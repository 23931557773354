/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import ContentContainer from '../content-container';
import FooterImageList from './footer-image-list';
import FooterLinkList from './footer-link-list';
import NewsletterForm from 'components/newsletter-form';
import FooterSocialMedia from './footer-social-media';
import Image from '../image';
import Link from '../link';
import RichText from '../rich-text';

const Row = ({ children }) => <div className="footer-row">{children}</div>;
Row.propTypes = { children: PropTypes.node };

const Column = ({ children }) => (
  <div className="footer-column">{children}</div>
);
Column.propTypes = { children: PropTypes.node };

const Footer = ({
  imageLists,
  linkLists,
  mainLinks,
  newsLetterForm,
  newsLetterTermsLink,
  richText,
  secondaryLinks,
  socialMedia
}) => (
  <footer className="footer">
    <div className="footer-section">
      <ContentContainer className="footer-content">
        <Row>
          {mainLinks.map(({ link, image }) => (
            <Column key={link.url}>
              <div className="footer-image-link">
                <Image {...image} />
                <Link
                  theme={[
                    Link.themes.huge,
                    Link.themes.bold,
                    Link.themes.skewUnderline,
                    Link.themes.white
                  ]}
                  {...link}
                />
              </div>
            </Column>
          ))}
        </Row>

        <Row>
          {linkLists.map(linkList => (
            <Column key={linkList.id}>
              <FooterLinkList {...linkList} />
            </Column>
          ))}
        </Row>

        <Row>
          {secondaryLinks.map(link => (
            <Column key={link.text + link.url}>
              <Button
                className="footer-button"
                theme={Button.themes.white}
                {...link}
              />
            </Column>
          ))}

          <Column>
            <NewsletterForm {...newsLetterForm} />
          </Column>
        </Row>
      </ContentContainer>
    </div>

    <div className="footer-section">
      <ContentContainer className="footer-content">
        <Row>
          {imageLists.map(list => (
            <Column key={list.title}>
              <FooterImageList {...list} />
            </Column>
          ))}
          <Column>
            <FooterSocialMedia {...socialMedia} />
          </Column>
        </Row>

        <Row>
          <RichText
            className="footer-rich-text"
            theme={RichText.themes.centered}
            {...richText}
          />
        </Row>
      </ContentContainer>
    </div>
  </footer>
);

Footer.propTypes = {
  imageLists: PropTypes.arrayOf(PropTypes.exact(FooterImageList.propTypes)),
  linkLists: PropTypes.arrayOf(PropTypes.exact(FooterLinkList.propTypes)),
  mainLinks: PropTypes.arrayOf(
    PropTypes.exact({
      image: PropTypes.exact(Image.propTypes),
      link: PropTypes.exact(Link.propTypes)
    })
  ),
  newsLetterForm: PropTypes.exact(NewsletterForm.propTypes),
  newsLetterTermsLink: PropTypes.exact(Link.propTypes),
  richText: PropTypes.exact(RichText.propTypes),
  secondaryLinks: PropTypes.arrayOf(PropTypes.exact(Button.propTypes)),
  socialMedia: PropTypes.exact(FooterSocialMedia.propTypes)
};

Footer.defaultProps = {
  imageLists: [],
  linkLists: [],
  mainLinks: [],
  secondaryLinks: []
};

export default Footer;
