import React from 'react';
import PropTypes from 'prop-types';

import ComponentList from 'components/component-list';
import ContentContainer from 'components/content-container';
import PageHeader from 'components/page-header';

const BrandPage = ({ blocks, header }) => {
  return (
    <React.Fragment>
      {header && <PageHeader {...header} />}

      <ContentContainer>
        {blocks && <ComponentList {...blocks} />}
      </ContentContainer>
    </React.Fragment>
  );
};

BrandPage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  header: PropTypes.exact(PageHeader.propTypes)
};

export default BrandPage;
