import React from 'react';
import PropTypes from 'prop-types';
import api from '../../js/api-helper';

import get from 'lodash/get';

import CheckoutContext from 'components/checkout-page/checkout-context';
import KlarnaIframe from '../klarna-iframe';
import DeliveryWidget from '../delivery-widget';
import Form from 'components/form';

import Radio from 'components/form-elements/radio';

const DeliveryForm = ({
  deliveryTitle,
  deliveryWidget,
  deliveryOptions,
  deliveryOptionsLabels, // NOTE: Is matched to `deliveryOptions` items by index
  endpoint
}) => {
  const { setIsLoading, setHasIframeHTML } = React.useContext(CheckoutContext);

  const [showPorterBuddy, setShowPorterBuddy] = React.useState(false);
  const [showOnlyKlarna, setShowOnlyKlarna] = React.useState(false);
  const [klarnaIframe, setKlarnaIframe] = React.useState();
  const [currentShipmentId, setCurrentShipmentId] = React.useState('');
  const currentShipmentRef = React.useRef();
  currentShipmentRef.current = currentShipmentId;

  const handleOnChange = async value => {
    setIsLoading(true);
    setShowPorterBuddy(false);
    setShowOnlyKlarna(false);
    setKlarnaIframe('');
    setCurrentShipmentId('');
    setHasIframeHTML(false);

    const { iframeHTML, enablePorterBuddy } = await api.execute(endpoint, {
      value
    });

    if (iframeHTML) {
      setHasIframeHTML(true);
      setKlarnaIframe(iframeHTML);
    }

    enablePorterBuddy ? setShowPorterBuddy(true) : setShowOnlyKlarna(true);
    setCurrentShipmentId(value);

    setIsLoading(false);
  };

  async function handleDeliveryFail() {
    //console.log('delivery failed: method id = ' + currentShipmentRef.current);
    const firstShipping = deliveryOptions
      ? deliveryOptions.options.find(
          option => option.value !== currentShipmentRef.current
        )
      : undefined;

    //console.log('setting another method id: ');
    //console.log(firstShipping);
    if (firstShipping !== undefined) {
      await api.execute(endpoint, {
        value: firstShipping.value
      });
    }
  }

  async function handleDeliverySucceed() {
    //console.log('delivery succeed: method id = ' + currentShipmentRef.current)
    if (currentShipmentRef.current !== '') {
      await api.execute(endpoint, {
        value: currentShipmentRef.current
      });
    }
  }

  return (
    <div className="delivery-form">
      <div className="delivery-form-delivery">
        {deliveryTitle && <h3>{deliveryTitle}</h3>}
        <Form endpoint={endpoint}>
          {deliveryOptions && (
            <Radio
              onChange={handleOnChange}
              className="checkout-radio"
              attributes={{ 'data-test-checkout-radio-button': '' }}
              validations="isRequired"
              idPrefix={`checkout-${deliveryOptions.name}-`}
              labels={get(deliveryOptions, 'options', []).map(
                (option, index) => {
                  const { price, description } =
                    deliveryOptionsLabels[index] || {};
                  return (
                    <span key={option.value}>
                      <span className="checkout-label">
                        {option.label} {price || ''}
                      </span>
                      {description && (
                        <span className="checkout-label-description">
                          {description}
                        </span>
                      )}
                    </span>
                  );
                }
              )}
              {...deliveryOptions}
            />
          )}
        </Form>
        <div className="delivery-form-payment">
          {showPorterBuddy && (
            <DeliveryWidget
              klarnaIframeHtml={klarnaIframe}
              handleDeliveryFail={handleDeliveryFail}
              handleDeliverySucceed={handleDeliverySucceed}
              {...deliveryWidget}
            />
          )}
          {showOnlyKlarna && (
            <KlarnaIframe htmlString={klarnaIframe} shouldEvalScript={true} />
          )}
        </div>
      </div>
    </div>
  );
};

DeliveryForm.propTypes = {
  deliveryOptions: PropTypes.exact(Radio.propTypes),
  deliveryTitle: PropTypes.string,
  deliveryWidget: PropTypes.shape(DeliveryWidget.propTypes),
  deliveryOptionsLabels: PropTypes.arrayOf(
    PropTypes.exact({
      description: PropTypes.string,
      price: PropTypes.string
    })
  ),
  endpoint: PropTypes.string.isRequired
};

DeliveryForm.defaultProps = {
  deliveryOptionsLabels: []
};

export default DeliveryForm;
