import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContentContainer from '../content-container';
import Icon from '../icon';
import Link from '../link';

const MyPageLayout = ({
  children,
  className,
  ingress,
  title,
  link,
  logoutLink
}) => (
  <div className={cn('my-page-layout', className)}>
    <ContentContainer>
      {link && (
        <Link prependChildren={true} theme={Link.themes.skew} {...link}>
          <Icon name="small-arrow-left" />
        </Link>
      )}

      {(title || ingress) && (
        <div className="my-page-layout-header">
          {title && <h1>{title}</h1>}
          {ingress && <p>{ingress}</p>}
          {logoutLink && (
            <Link className="my-page-layout-logout" {...logoutLink} />
          )}
        </div>
      )}

      <div className="my-page-layout-content">{children}</div>
    </ContentContainer>
  </div>
);

MyPageLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  ingress: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  logoutLink: PropTypes.exact(Link.propTypes)
};

MyPageLayout.propTypesMeta = {
  className: 'exclude'
};

export default MyPageLayout;
