import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import Icon from '../icon';

const FooterSocialMedia = ({ title, links }) => (
  <div className="footer-social-media-wrapper">
    <p>{title}</p>
    <ul>
      {links.map(link => (
        <li key={link.provider}>
          <Button className="footer-social-media-button" url={link.url}>
            <Icon name={link.provider} />
          </Button>
        </li>
      ))}
    </ul>
  </div>
);

FooterSocialMedia.defaultProps = {
  links: []
};

FooterSocialMedia.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.exact({
      url: PropTypes.string,
      provider: PropTypes.oneOf(['facebook', 'instagram', 'linkedin'])
        .isRequired
    })
  )
};

export default FooterSocialMedia;
