import React from 'react';
import PropTypes from 'prop-types';

import Image from '../image';
import RichText from 'components/rich-text';

const SolarisBanner = ({ image, imageText, title, text }) => {
  return (
    <div className="solaris-banner">
      <div className="solaris-logo-block">
        <Image {...image} />
        <span>{imageText}</span>
      </div>
      <div className="solaris-text-block">
        <div className="solaris-text-block-content">
          <h3>{title}</h3>
          <RichText text={text} />
        </div>
      </div>
    </div>
  );
};

SolarisBanner.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  imageText: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
};

export default SolarisBanner;
