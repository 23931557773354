import React from 'react';
import PropTypes from 'prop-types';

import api from '../../js/api-helper';
import checkApiPropTypes from 'js/check-api-prop-types';
import createFormModel from './create-form-model';

import Bomb from 'components/bomb';
import ContactLensForm from './contact-lens-form';
import Grid from '../grid';
import Icon from '../icon';
import ProductImages from '../product-images';
import ProductLogo from '../product-logo';
import ProductSellingPoints from '../product-selling-points';
import ReplacementProductBanner from './replacement-product-banner';
import SellingPoints from '../selling-points';
import UpdateFormResponse from './update-form-response';
import ProductVariantText from 'components/product-variant-text';

class ContactLensHeader extends React.Component {
  static propTypes = {
    bomb: PropTypes.string,
    getAvailabilityEndpoint: PropTypes.string,
    productImages: PropTypes.exact(ProductImages.propTypes),
    productLogo: PropTypes.exact(ProductLogo.propTypes),
    productName: PropTypes.string,
    productSellingPoints: PropTypes.exact(ProductSellingPoints.propTypes),
    productType: PropTypes.string,
    purchaseForm: PropTypes.exact(ContactLensForm.propTypes),
    replacementProductBanner: PropTypes.exact(
      ReplacementProductBanner.propTypes
    ),
    replacementProductTitle: PropTypes.string,
    sellingPoints: PropTypes.exact(SellingPoints.propTypes),
    updateFormEndpoint: PropTypes.string,
    productVariantText: PropTypes.string
  };

  static defaultProps = {
    productSellingPoints: []
  };

  state = {
    purchaseForm: this.props.purchaseForm,
    productVariantText: this.props.productVariantText
  };

  isLoading = false;

  onFormChange = formData => {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const transformedFormData = Object.entries(formData).reduce(
      (accum, [key, val]) => Object.assign({}, accum, { [key]: val || '' }),
      {}
    );

    api
      .execute(this.props.updateFormEndpoint, transformedFormData)
      .then(({ purchaseForm, productVariantText }) => {
        this.isLoading = false;

        checkApiPropTypes(
          { purchaseForm },
          UpdateFormResponse.propTypes,
          'UpdateFormResponse'
        );

        this.formsy && this.formsy.reset(createFormModel(purchaseForm));

        this.setState(state => ({
          purchaseForm: Object.assign({}, state.purchaseForm, purchaseForm),
          productVariantText: productVariantText
        }));
      });

    // NOTE: This endpoint is separate from updateFormEndpoint because the availability check is much slower on the server than the price calculation.
    if (this.props.getAvailabilityEndpoint) {
      api
        .execute(this.props.getAvailabilityEndpoint, transformedFormData)
        .then(({ purchaseForm }) => {
          checkApiPropTypes(
            { purchaseForm },
            UpdateFormResponse.propTypes,
            'UpdateFormResponse'
          );
          this.setState(state => ({
            purchaseForm: Object.assign({}, state.purchaseForm, purchaseForm)
          }));
        });
    }
  };

  render() {
    return (
      <Grid className="contact-lens-header" theme={Grid.themes.leftColumnWider}>
        <Grid.Column>
          <div className="contact-lens-product-wrapper">
            {this.props.replacementProductBanner && (
              <div className="contact-lens-replacement-product">
                <ReplacementProductBanner
                  {...this.props.replacementProductBanner}
                />
              </div>
            )}

            <div className="contact-lens-main-product">
              <div className="contact-lens-title">
                {this.props.replacementProductTitle && (
                  <h2>
                    <div className="contact-lens-checkmark">
                      <Icon name="checkmark" />
                    </div>
                    {this.props.replacementProductTitle}
                  </h2>
                )}
                {this.props.replacementProductBanner ? (
                  <h2 className="contact-lens-product-title">
                    {this.props.productName}
                  </h2>
                ) : (
                  <h1>{this.props.productName}</h1>
                )}
                <p>{this.props.productType}</p>
              </div>

              {this.props.productSellingPoints && (
                <div className="contact-lens-selling-points">
                  <ProductSellingPoints
                    theme={
                      this.props.replacementProductBanner
                        ? ProductSellingPoints.themes.smallText
                        : undefined
                    }
                    {...this.props.productSellingPoints}
                  />
                </div>
              )}

              <div className="contact-lens-image">
                {this.props.bomb && (
                  <div className="contact-lens-bomb">
                    <Bomb text={this.props.bomb} />
                  </div>
                )}
                <ProductImages {...this.props.productImages} />
              </div>

              <div className="contact-lens-logo">
                <ProductLogo {...this.props.productLogo} />
              </div>
            </div>
          </div>
        </Grid.Column>

        <Grid.Column>
          <ContactLensForm
            onChange={this.onFormChange}
            onRef={formsy => (this.formsy = formsy)}
            {...this.state.purchaseForm}
          />
          {this.state.productVariantText && (
            <ProductVariantText
              textHtml={this.state.productVariantText}
              className="product-variant-text--in-contact-lens"
            />
          )}
          <SellingPoints {...this.props.sellingPoints} />
        </Grid.Column>
      </Grid>
    );
  }
}

export default ContactLensHeader;
