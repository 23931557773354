import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import Icon from 'components/icon';

class CollapseList extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    collapseLabel: PropTypes.string,
    expandLabel: PropTypes.string,
    maxLength: PropTypes.number
  };

  state = {
    isExpanded: false
  };

  toggleExpanded = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  };

  render() {
    const { maxLength, children } = this.props;
    const sliceIndex = maxLength || React.Children.count(children);
    const visibleChildren = children.slice(0, sliceIndex);
    const hiddenChildren = children.slice(
      sliceIndex,
      React.Children.count(children)
    );

    return (
      <div className="collapse-list">
        <ul>
          {React.Children.map(visibleChildren, child => (
            <li>{child}</li>
          ))}
        </ul>
        {!!hiddenChildren.length && [
          <Collapse
            isOpen={this.state.isExpanded}
            key="list"
            unmountClosed={false}
          >
            <ul>
              {React.Children.map(hiddenChildren, child => (
                <li>{child}</li>
              ))}
            </ul>
          </Collapse>,
          <button
            type="button"
            onClick={this.toggleExpanded}
            key="toggle"
            className={cn({ 'is-active': this.state.isExpanded })}
          >
            <span>
              {this.state.isExpanded
                ? this.props.collapseLabel
                : this.props.expandLabel}
            </span>
            <Icon name="small-arrow-down" />
          </button>
        ]}
      </div>
    );
  }
}

export default CollapseList;
