/* eslint-disable react/no-unused-prop-types */
// Need to disable prop-types rule in order to keep 'name' proptype, used by formsy

import React from 'react';
import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';

class OptionGroup extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    getValue: PropTypes.func, // From formsy HOC
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        label: PropTypes.string,
        selected: PropTypes.bool,
        value: PropTypes.string
      })
    ).isRequired,
    setValue: PropTypes.func, // From formsy HOC
    onChange: PropTypes.func
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    options: [],
    onChange: () => {}
  };

  componentDidMount() {
    this.props.setValue(
      this.props.options.reduce((accum, option) => {
        if (option.selected) {
          accum.push(option.value);
        }
        return accum;
      }, [])
    );
  }

  onCheckboxChange = checkbox => {
    const value = this.props.getValue() || [];
    const indexOfCheckbox = value.indexOf(checkbox.value);

    if (checkbox.checked && indexOfCheckbox === -1) {
      value.push(checkbox.value);
    } else {
      value.splice(indexOfCheckbox, 1);
    }

    this.props.setValue(value);
    this.props.onChange(value);
  };

  render() {
    return this.props.children({
      value: this.props.getValue() || [],
      onChange: this.onCheckboxChange
    });
  }
}

export default withFormsy(OptionGroup);
