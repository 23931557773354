import React from 'react';
import PropTypes from 'prop-types';

const EmailTemplateContent = ({
  expectedDeliveryDate,
  orderConfirmationLabel,
  orderNumber,
  orderDateLabel,
  orderDate,
  products,
  quantityLabel,
  totalPriceLabel,
  unitPriceLabel
}) => (
  <div className="email-template-content">
    <p>
      {orderConfirmationLabel}: <strong>{orderNumber}</strong>
    </p>
    <p>
      {orderDateLabel}: <strong>{orderDate}</strong>
    </p>
    <p>{expectedDeliveryDate}</p>
    <div className="email-template-product-list">
      <hr />
      {products.map(product => (
        <div className="email-template-product" key={product.id}>
          <img src={product.image.src} />
          <h2>{product.title}</h2>
          <p>
            {product.details.map((detail, i) => (
              <React.Fragment key={detail.label}>
                {detail.label}: <strong>{detail.data}</strong>
                {product.details.length - 1 > i ? (
                  <React.Fragment>, </React.Fragment>
                ) : null}
              </React.Fragment>
            ))}
          </p>
          <div className="email-template-price">
            <p>
              {quantityLabel}: <strong>{product.quantity}</strong>,{' '}
              {unitPriceLabel}:{' '}
              <strong>{product.unitPrice.originalPrice}</strong>
            </p>
            <p className="email-template-unit-total">
              {totalPriceLabel}: <strong>{product.price.originalPrice}</strong>
            </p>
          </div>
          <hr />
        </div>
      ))}
    </div>
  </div>
);

EmailTemplateContent.defaultProps = {
  products: []
};

EmailTemplateContent.propTypes = {
  expectedDeliveryDate: PropTypes.string,
  orderConfirmationLabel: PropTypes.string,
  orderNumber: PropTypes.string,
  orderDateLabel: PropTypes.string,
  orderDate: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, data: PropTypes.string })
      ),
      image: PropTypes.object,
      price: PropTypes.shape({
        originalPrice: PropTypes.string
      }),
      id: PropTypes.string,
      productUrl: PropTypes.string,
      quantity: PropTypes.number,
      title: PropTypes.string,
      unitPrice: PropTypes.shape({
        originalPrice: PropTypes.string
      })
    })
  ),
  quantityLabel: PropTypes.string,
  totalPriceLabel: PropTypes.string,
  unitPriceLabel: PropTypes.string
};

EmailTemplateContent.propTypesMeta = 'exclude';

export default EmailTemplateContent;
