import { addValidationRule } from 'formsy-react';

const rules = {
  isRequired: (allFields, value) => {
    var hasValue = !!value && value.length !== 0;

    if (typeof value === 'object') {
      hasValue = hasValue && Object.keys(value).length !== 0;
    }

    return hasValue;
  },
  isBirthDate: (allFields, value) => {
    if (value) {
      if (!value.match(/\d{6}/)) {
        return false;
      }

      const day = parseInt(value.substr(0, 2));
      const month = parseInt(value.substr(2, 2));

      if (day < 1 || day > 31 || month < 1 || month > 12) {
        return false;
      }
    }

    return true;
  },
  isConditionallyRequired: (allFields, value, otherFieldsString) => {
    var otherFields = otherFieldsString.split('.');

    for (var i = 0; i < otherFields.length; i++) {
      if (!allFields[otherFields[i]] && !value) {
        return false;
      }
    }

    return true;
  },
  isEmailRepeat: (allFields, value, otherField) => {
    return !value || value === allFields[otherField];
  },
  isPhoneNumber: (allFields, value) => {
    return !value || value.match(/^\+?[\d-()\s]{7,}\d$/);
  },
  isPostalCode: (allFields, value) => {
    if (value && !value.match(/^\d{4}$/)) {
      return false;
    }

    return true;
  }
};

for (var key in rules) {
  addValidationRule(key, rules[key]);
}
