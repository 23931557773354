import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from '../button';
import ContentContainer from '../content-container';
import FilterGroup from './filter-group';
import HiddenInput from '../form-elements/hidden-input';
import Icon from '../icon';

class CategoryFilters extends React.Component {
  static propTypes = {
    categoryId: PropTypes.exact(HiddenInput.propTypes),
    filterGroups: PropTypes.arrayOf(PropTypes.exact(FilterGroup.propTypes)),
    hideFilters: PropTypes.func,
    hideFiltersLabel: PropTypes.string,
    isVisible: PropTypes.bool,
    resetLabel: PropTypes.string,
    submitLabel: PropTypes.string,
    title: PropTypes.string
  };

  static propTypesMeta = {
    isVisible: 'exclude'
  };

  static defaultProps = {
    filterGroups: [],
    submitLabel: 'Filtrér'
  };

  state = {
    isMounted: false
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  onReset = e => {
    e.preventDefault();
    this.formsy && this.formsy.reset();

    // Reset needs to be applied before submitting
    setTimeout(() => {
      this.submitButton.click();
    }, 200);
  };

  render() {
    return (
      <div
        className={cn('category-filters', {
          'is-visible': this.props.isVisible
        })}
      >
        <h2>{this.props.title}</h2>
        <div className="category-filters-nav">
          <ContentContainer>
            <div className="category-filters-hide-wrapper">
              <Button
                className="category-filters-hide"
                onClick={this.props.hideFilters}
                prependChildren={true}
                text={this.props.hideFiltersLabel}
                theme={Button.themes.plain}
              >
                <Icon name="small-arrow-left" />
              </Button>
            </div>
            <Button
              htmlFor="category-filters-submit"
              className="category-filters-apply"
              text={this.props.submitLabel}
              theme={Button.themes.mini}
            />
            <Button
              htmlFor="category-filters-reset"
              className="category-filters-reset"
              text={this.props.resetLabel}
              theme={[Button.themes.outline, Button.themes.mini]}
            />
          </ContentContainer>
        </div>
        {this.props.categoryId && <HiddenInput {...this.props.categoryId} />}
        <div className="category-filters-content">
          {this.props.filterGroups.map(group => (
            <FilterGroup key={group.title} {...group} />
          ))}
        </div>
        <input
          type="reset"
          id="category-filters-reset"
          className={cn('category-filters-reset', 'is-hidden')}
          value={this.props.resetLabel}
          onClick={this.onReset}
        />
        <input
          id="category-filters-submit"
          type="submit"
          className={cn('category-filters-submit', {
            'is-mounted': this.state.isMounted
          })}
          ref={input => (this.submitButton = input)}
          value={this.props.submitLabel}
        />
      </div>
    );
  }
}

export default CategoryFilters;
