import React from 'react';
import PropTypes from 'prop-types';

import Availability from '../availability';
import Bomb from 'components/bomb';
import Button from '../button';
import GlassesForm from './glasses-form';
import Grid from '../grid';
import Price from '../price';
import ProductColors from './product-colors';
import ProductImages from '../product-images';
import ProductLogo from '../product-logo';
import ProductSellingPoints from '../product-selling-points';
import SellingPoints from '../selling-points';
import ProductVariantText from 'components/product-variant-text';

const GlassesHeader = ({
  availability,
  bomb,
  onBeforeUpdateSize,
  price,
  productColors,
  productImages,
  productLogo,
  productModel,
  productName,
  productSellingPoints,
  purchaseForm,
  purchaseInformation,
  sellingPoints,
  updateSize,
  productVariantText
}) => {
  return (
    <Grid className="glasses-header" theme={Grid.themes.leftColumnWider}>
      <Grid.Column>
        <div className="glasses-title glasses-title-mobile">
          <h1>{productName}</h1>
          <p>{productModel}</p>
        </div>

        <div className="glasses-logo">
          <ProductLogo {...productLogo} />
        </div>

        <div className="glasses-image">
          {bomb && <Bomb text={bomb} />}
          <ProductImages {...productImages} />
        </div>
      </Grid.Column>

      <Grid.Column>
        <div className="glasses-title glasses-title-desktop">
          <h1>{productName}</h1>
          <p>{productModel}</p>
        </div>

        <div className="glasses-price">
          <Price
            theme={[Price.themes.alignLeft, Price.themes.bigFont]}
            {...price}
          />
          {availability && <Availability {...availability} />}
        </div>

        {purchaseForm && (
          <GlassesForm
            onBeforeUpdateSize={onBeforeUpdateSize}
            updateSize={updateSize}
            {...purchaseForm}
          />
        )}

        {purchaseInformation && (
          <div className="glasses-purchase-information">
            <p>{purchaseInformation.text}</p>
            <Button {...purchaseInformation.link} />
          </div>
        )}
        {productVariantText && (
          <ProductVariantText textHtml={productVariantText} />
        )}
        {productSellingPoints && (
          <div className="glasses-selling-points">
            <ProductSellingPoints {...productSellingPoints} />
          </div>
        )}
        <SellingPoints {...sellingPoints} />
        {productColors && <ProductColors {...productColors} />}
      </Grid.Column>
    </Grid>
  );
};

GlassesHeader.propTypes = {
  availability: PropTypes.exact(Availability.propTypes),
  bomb: PropTypes.string,
  onBeforeUpdateSize: PropTypes.func,
  price: PropTypes.exact(Price.propTypes),
  productColors: PropTypes.exact(ProductColors.propTypes),
  productImages: PropTypes.exact(ProductImages.propTypes),
  productLogo: PropTypes.exact(ProductLogo.propTypes),
  productModel: PropTypes.string,
  productName: PropTypes.string,
  productSellingPoints: PropTypes.exact(ProductSellingPoints.propTypes),
  purchaseForm: PropTypes.exact(GlassesForm.propTypes),
  purchaseInformation: PropTypes.exact({
    text: PropTypes.string,
    link: PropTypes.exact(Button.propTypes)
  }),
  sellingPoints: PropTypes.exact(SellingPoints.propTypes),
  updateFormEndpoint: PropTypes.string,
  updateSize: PropTypes.func,
  productVariantText: PropTypes.string
};

GlassesHeader.defaultProps = {
  productSellingPoints: []
};

GlassesHeader.propTypesMeta = {
  productVariantText: 'exclude'
};

export default GlassesHeader;
