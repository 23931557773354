import React from 'react';
import PropTypes from 'prop-types';

import Appointments from '../appointments';
import CustomerDataImportForm from '../customer-data-import-form';
import MyConsents from '../my-consents';
import MyPageSidebarSection from './my-page-sidebar-section';
import MyPageLayout from '../my-page-layout';
import Grid from '../grid';
import MyOrders from '../my-orders';
import MyPageSection from '../my-page-section';
import OrderList from '../order-list';
import PersonalDetails from '../personal-details';

const MyPage = ({
  appointments,
  appointmentsSection,
  customerDataImport,
  customerDataImportSection,
  layout,
  orders,
  ordersSection,
  previousOrder,
  previousOrderSection,
  previousStorePurchase,
  previousStorePurchaseSection,
  consentList,
  consentListSection,
  personalDetails,
  personalDetailsSection
}) => {
  return (
    <MyPageLayout {...layout}>
      <Grid className="my-page-content" theme={Grid.themes.leftColumnWider}>
        <Grid.Column>
          {customerDataImport && (
            <MyPageSection {...customerDataImportSection}>
              <CustomerDataImportForm {...customerDataImport} />
            </MyPageSection>
          )}
          {previousStorePurchase && (
            <MyPageSection {...previousStorePurchaseSection}>
              <OrderList {...previousStorePurchase} />
            </MyPageSection>
          )}
          {previousOrder && (
            <MyPageSection {...previousOrderSection}>
              <OrderList {...previousOrder} />
            </MyPageSection>
          )}
          {orders && (
            <MyPageSection {...ordersSection}>
              <MyOrders {...orders} />
            </MyPageSection>
          )}
        </Grid.Column>

        <Grid.Column>
          <div className="my-page-sidebar">
            {appointments && (
              <MyPageSidebarSection
                showLinkIcon={false}
                {...appointmentsSection}
              >
                <Appointments {...appointments} />
              </MyPageSidebarSection>
            )}
            {consentList && (
              <MyPageSidebarSection linkIcon="pen" {...consentListSection}>
                <MyConsents {...consentList} />
              </MyPageSidebarSection>
            )}
            {personalDetails && (
              <MyPageSidebarSection linkIcon="pen" {...personalDetailsSection}>
                <PersonalDetails {...personalDetails} />
              </MyPageSidebarSection>
            )}
          </div>
        </Grid.Column>
      </Grid>
    </MyPageLayout>
  );
};

MyPage.propTypes = {
  appointments: PropTypes.exact(Appointments.propTypes),
  appointmentsSection: PropTypes.exact(MyPageSidebarSection.propTypes),
  customerDataImportSection: PropTypes.exact(MyPageSection.propTypes),
  customerDataImport: PropTypes.exact(CustomerDataImportForm.propTypes),
  layout: PropTypes.exact(MyPageLayout.propTypes),
  orders: PropTypes.exact(MyOrders.propTypes),
  ordersSection: PropTypes.exact(MyPageSection.propTypes),
  previousStorePurchase: PropTypes.exact(OrderList.propTypes),
  previousStorePurchaseSection: PropTypes.exact(MyPageSection.propTypes),
  previousOrder: PropTypes.exact(OrderList.propTypes),
  previousOrderSection: PropTypes.exact(MyPageSection.propTypes),
  consentList: PropTypes.exact(MyConsents.propTypes),
  consentListSection: PropTypes.exact(MyPageSidebarSection.propTypes),
  personalDetails: PropTypes.exact(PersonalDetails.propTypes),
  personalDetailsSection: PropTypes.exact(MyPageSidebarSection.propTypes)
};

export default MyPage;
