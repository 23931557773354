import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import Link from '../link';
import TextInput from '../form-elements/text-input';

const PersonalInformationLoginForm = ({
  buttonText,
  forgotPasswordLink,
  formId,
  groupClassName,
  password,
  title,
  userId
}) => (
  <div className="personal-information-login">
    {userId && password && (
      <div className={groupClassName}>
        <h3>{title}</h3>
        <TextInput
          formId={formId}
          theme={TextInput.themes.grid}
          useFormsy={false}
          {...userId}
        />
        <TextInput
          formId={formId}
          theme={TextInput.themes.grid}
          type="password"
          useFormsy={false}
          {...password}
        />
      </div>
    )}
    <Button
      className="personal-information-login-submit"
      formId={formId}
      type="submit"
      text={buttonText}
    />
    {forgotPasswordLink && (
      <div className="personal-information-forgot-password">
        <Link {...forgotPasswordLink} />
      </div>
    )}
  </div>
);

PersonalInformationLoginForm.propTypes = {
  buttonText: PropTypes.string,
  forgotPasswordLink: PropTypes.exact(Link.propTypes),
  formId: PropTypes.string.isRequired,
  groupClassName: PropTypes.string,
  password: PropTypes.exact(TextInput.propTypes),
  title: PropTypes.string,
  userId: PropTypes.exact(TextInput.propTypes)
};

PersonalInformationLoginForm.propTypesMeta = {
  formId: 'exclude',
  groupClassName: 'exclude'
};

export default PersonalInformationLoginForm;
