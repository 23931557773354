import React from 'react';
import PropTypes from 'prop-types';

import ArrowButton from 'components/arrow-button';
import Icon from 'components/icon';

const RegistrationBanner = ({ description, link, title }) => (
  <div className="registration-banner">
    <h2>{title}</h2>
    <ArrowButton className="registration-banner-link" theme={null} {...link} />
    <ul className="registration-banner-text">
      {description.map(text => (
        <li key={text}>
          <Icon className="registration-banner-icon" name="checkmark" />
          {text}
        </li>
      ))}
    </ul>
  </div>
);

RegistrationBanner.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.exact(ArrowButton.propTypes),
  title: PropTypes.string
};

export default RegistrationBanner;
