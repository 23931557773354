import React from 'react';
import PropTypes from 'prop-types';

const OrderListItem = ({ amount, details, product, productDescription }) => (
  <tr>
    <td>
      <div className="order-list-item">
        <span>{product}</span>
        <span>{productDescription}</span>
      </div>

      {details && (
        <div className="order-list-details">
          {details.map(({ label, value }) => (
            <div className="order-list-details-item" key={label + value}>
              <div>{label}</div>
              <div>{value}</div>
            </div>
          ))}
        </div>
      )}
    </td>

    {amount && (
      <td>
        <span>{amount}</span>
      </td>
    )}
  </tr>
);

OrderListItem.propTypes = {
  amount: PropTypes.number,
  details: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  product: PropTypes.string,
  productDescription: PropTypes.string
};

export default OrderListItem;
