import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import propTypeTheme from 'utils/prop-type-theme';
import validationHelper from './validation-helper';

import Checkbox from '../form-elements/checkbox';
import Link from '../link';
import PersonalInformationLoginForm from './personal-information-login-form';
import TextInput from '../form-elements/text-input';
import ValidationErrorTexts from 'components/validation-error-texts';

const themes = {
  narrow: 'theme-narrow'
};

class PersonalInformationForm extends React.Component {
  static propTypes = {
    address: PropTypes.exact(TextInput.propTypes).isRequired,
    birthDate: PropTypes.exact(TextInput.propTypes).isRequired,
    birthDateLink: PropTypes.exact(Link.propTypes),
    changeEmailLink: PropTypes.exact(Link.propTypes),
    changePasswordLink: PropTypes.exact(Link.propTypes),
    city: PropTypes.exact(TextInput.propTypes),
    deliveryAddress: PropTypes.exact(TextInput.propTypes),
    deliveryAddressTitle: PropTypes.string,
    deliveryCity: PropTypes.exact(TextInput.propTypes),
    deliveryFirstName: PropTypes.exact(TextInput.propTypes),
    deliveryLastName: PropTypes.exact(TextInput.propTypes),
    deliveryPostalCode: PropTypes.exact(TextInput.propTypes),
    email: PropTypes.exact(TextInput.propTypes).isRequired,
    firstName: PropTypes.exact(TextInput.propTypes).isRequired,
    isLoggedIn: PropTypes.bool,
    lastName: PropTypes.exact(TextInput.propTypes).isRequired,
    loginForm: PropTypes.exact(PersonalInformationLoginForm.propTypes),
    loginFormId: PropTypes.string,
    onValidate: PropTypes.func,
    password: PropTypes.exact(TextInput.propTypes),
    phone: PropTypes.exact(TextInput.propTypes).isRequired,
    postalCode: PropTypes.exact(TextInput.propTypes).isRequired,
    registerAccount: PropTypes.exact(Checkbox.propTypes),
    separateDeliveryAddress: PropTypes.exact(Checkbox.propTypes),
    showDeliveryAddress: PropTypes.bool,
    showDeliveryAddressCheckbox: PropTypes.bool,
    theme: propTypeTheme(themes),
    title: PropTypes.string,
    validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes)
  };

  static propTypesMeta = {
    loginFormId: 'exclude',
    showDeliveryAddress: 'exclude',
    showDeliveryAddressCheckbox: 'exclude',
    theme: 'exclude'
  };

  static defaultProps = {
    onValidate: () => {},
    showDeliveryAddress: false,
    showDeliveryAddressCheckbox: true
  };

  state = {
    isMounted: false,
    showDeliveryAddress: this.props.showDeliveryAddress
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  // List keeping track of whether inputs are valid or not
  inputValidation = validationHelper.create(this.props);

  validateAll = () => {
    this.props.onValidate(validationHelper.validate(this.inputValidation));
  };

  onValidate = ({ name }, isValid) => {
    this.inputValidation = { ...this.inputValidation, [name]: isValid };
    this.validateAll();
  };

  toggleSeparateDeliveryAddress = showDeliveryAddress => {
    this.setState({ showDeliveryAddress });
    this.inputValidation = validationHelper.toggleDeliveryAddress(
      this.props,
      this.inputValidation,
      showDeliveryAddress
    );
    this.validateAll();
  };

  getTextInput = (textInputProps, validation, theme, formId, type) =>
    textInputProps ? (
      // Utility method to make the render method more compact. Just look at all those props that would have to be repeated 😱!
      <TextInput
        detectAutofill={true}
        formId={formId}
        idPrefix="personal-information"
        onValidate={this.onValidate.bind(this, textInputProps)}
        validations={validation}
        theme={[TextInput.themes.grid].concat(theme)}
        type={type}
        validationErrors={this.props.validationErrors}
        {...textInputProps}
      />
    ) : null;

  render() {
    const groupClassName = cn('personal-information-group', this.props.theme);

    const { aloneInRow, narrow, wide } = TextInput.themes;

    return (
      <React.Fragment>
        {this.props.loginForm && !this.props.isLoggedIn && (
          <PersonalInformationLoginForm
            formId={this.props.loginFormId}
            groupClassName={groupClassName}
            {...this.props.loginForm}
          />
        )}

        <div className={groupClassName}>
          {this.props.title && <h3>{this.props.title}</h3>}
          {this.getTextInput(this.props.email, 'isEmail', wide)}

          {(this.props.changeEmailLink || this.props.changePasswordLink) && (
            <div className="personal-information-form-links">
              {this.props.changeEmailLink && (
                <Link {...this.props.changeEmailLink} />
              )}
              {this.props.changePasswordLink && (
                <Link {...this.props.changePasswordLink} />
              )}
            </div>
          )}

          {this.getTextInput(
            this.props.password,
            '',
            aloneInRow,
            null,
            'password'
          )}
          {this.getTextInput(this.props.firstName)}
          {this.getTextInput(this.props.lastName)}
          {this.getTextInput(this.props.phone, 'isPhoneNumber', aloneInRow)}
          {this.getTextInput(this.props.address)}
          {this.getTextInput(this.props.postalCode, 'isPostalCode', narrow)}
          {this.getTextInput(this.props.city, '', narrow)}

          {this.props.separateDeliveryAddress &&
            this.props.showDeliveryAddressCheckbox && (
              <Checkbox
                idPrefix="personal-information"
                theme={Checkbox.themes.grid}
                onChange={this.toggleSeparateDeliveryAddress}
                {...this.props.separateDeliveryAddress}
              />
            )}
        </div>

        {this.props.deliveryFirstName &&
          this.props.deliveryLastName &&
          this.props.deliveryAddress &&
          this.props.deliveryPostalCode && (
            <div className="personal-information-collapse">
              <Collapse
                isOpen={!this.state.isMounted || this.state.showDeliveryAddress}
              >
                <div className={groupClassName}>
                  <h3>{this.props.deliveryAddressTitle}</h3>

                  {this.getTextInput(this.props.deliveryFirstName)}
                  {this.getTextInput(this.props.deliveryLastName)}
                  {this.getTextInput(this.props.deliveryAddress)}
                  {this.getTextInput(
                    this.props.deliveryPostalCode,
                    'isPostalCode',
                    narrow
                  )}
                  {this.getTextInput(this.props.deliveryCity, '', narrow)}
                </div>
              </Collapse>
            </div>
          )}

        <div className={groupClassName}>
          {this.getTextInput(this.props.birthDate, 'isBirthDate', narrow)}
          {this.props.birthDateLink && (
            <Link
              className="personal-information-link"
              theme={Link.themes.skewUnderline}
              {...this.props.birthDateLink}
            />
          )}
        </div>

        {this.props.registerAccount && (
          <div className={groupClassName}>
            <Checkbox
              idPrefix="personal-information"
              theme={Checkbox.themes.grid}
              {...this.props.registerAccount}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

PersonalInformationForm.themes = themes;

export default PersonalInformationForm;
