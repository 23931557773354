import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import cn from 'classnames';
import debounce from 'lodash/debounce';

import analytics from '../../js/analytics';
import breakpoints from '../../js/breakpoints';

import Collapse from 'react-tiny-collapse';

import ArrowButton from '../arrow-button';
import ContentContainer from '../content-container';
import Cart from '../cart';
import GlobalSearch from '../global-search';
import Icon from '../icon';
import Link from '../link';
import LoginModal from '../login-modal';
import MainNav from './main-nav';
import Modal from '../modal';

import logo from '../../assets/logo.svg';

class Header extends React.Component {
  static propTypes = {
    cart: PropTypes.exact(Cart.propTypes),
    cartLink: PropTypes.exact({
      numberOfProducts: PropTypes.number,
      text: PropTypes.string,
      url: PropTypes.string
    }),
    hideMenuText: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    linkToHome: PropTypes.exact(Link.propTypes),
    locateStoreLink: PropTypes.exact(Link.propTypes),
    loginModal: PropTypes.exact(LoginModal.propTypes),
    myPageLink: PropTypes.exact(Link.propTypes),
    mainNavigation: PropTypes.exact(MainNav.propTypes),
    search: PropTypes.exact(GlobalSearch.propTypes),
    searchPageLink: PropTypes.exact(Link.propTypes),
    showMenuText: PropTypes.string,
    supportMenu: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
    wishListLink: PropTypes.exact(Link.propTypes)
  };

  state = {
    activeSubMenu: '',
    cartLink: this.props.cartLink,
    headerBarHeight: undefined,
    isCartVisible: false,
    isDesktop: true,
    isLoginVisible: false,
    isSearchVisible: false,
    isTablet: true,
    mobileMenuIsOpen: false
  };

  componentDidMount() {
    this.screenWidth = window.innerWidth;
    this.onResize();
    window.addEventListener('resize', this.debouncedResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  }

  onResize = () => {
    const isDesktop = breakpoints.lg();
    const isTablet = breakpoints.md();
    document.body.style.position = '';

    this.setState(
      Object.assign(
        {},
        {
          headerBarHeight: this.headerBar.offsetHeight,
          isDesktop,
          isTablet
        },
        window.innerWidth !== this.screenWidth
          ? { mobileMenuIsOpen: false }
          : {}
      ),
      () => {
        this.screenWidth = window.innerWidth;
      }
    );
  };

  debouncedResize = debounce(this.onResize, 400);

  toggleMobileMenu = (e, isOpen) => {
    this.setState(state => {
      isOpen = isOpen || !state.mobileMenuIsOpen;

      if (isOpen && !state.isDesktop) {
        this.headerBar.scrollIntoView(true);

        document.body.style.position = 'fixed';
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.position = '';
        document.body.style.overflow = 'unset';
      }

      return {
        isCartVisible: false,
        isLoginVisible: false,
        isSearchVisible: false,
        mobileMenuIsOpen: isOpen
      };
    });
  };

  hideMobileMenu = () => {
    this.toggleMobileMenu(null, false);
  };

  toggleLogin = e => {
    e.preventDefault();

    this.setState(previousState => ({
      isLoginVisible: !previousState.isLoginVisible,
      mobileMenuIsOpen: false,
      isCartVisible: false
    }));
  };

  toggleCart = e => {
    e.preventDefault();
    this.setState(previousState => {
      if (!previousState.isCartVisible) {
        analytics.send({ event: 'cart-open' });
      }

      return {
        isCartVisible: !previousState.isCartVisible,
        isLoginVisible: false,
        isSearchVisible: false,
        mobileMenuIsOpen: false
      };
    });
  };

  toggleSearch = e => {
    e.preventDefault();
    this.setState(previousState => {
      if (!previousState.isSearchVisible) {
        analytics.send({ event: 'search-open' });
      }

      return {
        isCartVisible: false,
        isLoginVisible: false,
        isSearchVisible: !previousState.isSearchVisible,
        mobileMenuIsOpen: false
      };
    });
  };

  hideModals = () => {
    this.setState({
      isCartVisible: false,
      isLoginVisible: false,
      isSearchVisible: false,
      mobileMenuIsOpen: false
    });
  };

  onCartUpdate = ({ cartLink }) => {
    if (cartLink) {
      this.setState({ cartLink });
    }
  };

  onMeasure = height => {
    if (
      !this.state.isCartVisible &&
      !this.state.isLoginVisible &&
      !this.state.isSearchVisible
    ) {
      document.body.style.position = '';
      return;
    }

    const headerNode = ReactDOM.findDOMNode(this);

    if (!isNaN(height) && headerNode) {
      const bottomPosition = headerNode.offsetHeight + height;
      document.body.style.position =
        bottomPosition > window.innerHeight ? 'fixed' : '';
    } else {
      document.body.style.position = '';
    }
  };

  render() {
    const {
      locateStoreLink,
      myPageLink,
      searchPageLink,
      wishListLink
    } = this.props;
    const { mobileMenuIsOpen, cartLink } = this.state;
    const ModalContentWrapper = this.state.isTablet ? 'div' : ContentContainer;
    const loginId = 'header-login-modal';
    const searchId = 'header-search-modal';
    const cartId = 'header-cart-modal';

    return (
      <header className="header" ref={header => (this.headerBar = header)}>
        <div className="header-bar">
          <ContentContainer className="header-bar-content">
            <button
              className={cn('header-mobile-menu-toggle', {
                'is-active': mobileMenuIsOpen
              })}
              onClick={this.toggleMobileMenu}
            >
              <i className="header-hamburger">
                <i className="header-hamburger-line top" />
                <i className="header-hamburger-line mid" />
                <i className="header-hamburger-line bot" />
              </i>
              <span>
                {mobileMenuIsOpen
                  ? this.props.hideMenuText
                  : this.props.showMenuText}
              </span>
            </button>
            <Link
              className="header-logo"
              useSpanElement={true}
              {...this.props.linkToHome}
            >
              <img src={logo} alt="Brilleland logo" />
            </Link>

            <ul className="header-bar-nav">
              {this.props.supportMenu.map(link => (
                <li key={link.url} className="header-support-menu-link">
                  <Link
                    theme={[Link.themes.noStyle, Link.themes.skew]}
                    {...link}
                  />
                </li>
              ))}
              {this.state.isDesktop && myPageLink && (
                <li>
                  <Link
                    attributes={{
                      'aria-controls': loginId,
                      'aria-expanded': this.state.isLoginVisible
                    }}
                    className="header-login-link"
                    onClick={this.props.isLoggedIn ? null : this.toggleLogin}
                    theme={[Link.themes.noStyle, Link.themes.skew]}
                    {...myPageLink}
                  />
                </li>
              )}
              {locateStoreLink && (
                <li>
                  <Link
                    className="header-locate-store-link"
                    theme={[Link.themes.noStyle, Link.themes.skew]}
                    {...locateStoreLink}
                  >
                    <Icon name="pin" />
                  </Link>
                </li>
              )}
              {searchPageLink && (
                <li>
                  <Link
                    attributes={{
                      'aria-controls': searchId,
                      'aria-expanded': this.state.isSearchVisible
                    }}
                    className="header-search-button"
                    onClick={this.toggleSearch}
                    theme={[Link.themes.noStyle, Link.themes.skew]}
                    {...searchPageLink}
                  >
                    <Icon name="magnifier" />
                  </Link>
                </li>
              )}
            </ul>

            {(cartLink || wishListLink) && (
              <div className="header-cart-n-stuff">
                {wishListLink && (
                  <Link className="header-wish-list-button" {...wishListLink}>
                    <Icon name="heart" />
                  </Link>
                )}
                {cartLink && (
                  <Link
                    attributes={{
                      'aria-controls': cartId,
                      'aria-expanded': this.state.isCartVisible
                    }}
                    className={cn('header-cart-button', {
                      'has-products': cartLink.numberOfProducts
                    })}
                    onClick={this.toggleCart}
                    text={cartLink.text}
                    url={cartLink.url}
                  >
                    <div className="header-cart-icon">
                      <Icon name="basket" />
                      <span className="header-cart-count">
                        {cartLink.numberOfProducts}
                      </span>
                    </div>
                  </Link>
                )}
              </div>
            )}
          </ContentContainer>
        </div>

        <MainNav
          hideMobileMenu={this.hideMobileMenu}
          isDesktop={this.state.isDesktop}
          isExpanded={mobileMenuIsOpen}
          myPageLink={myPageLink}
          supportMenu={this.props.supportMenu}
          toggleLogin={this.toggleLogin}
          topPosition={this.state.headerBarHeight}
          {...this.props.mainNavigation}
        />

        <Modal
          animate={false}
          className="header-login"
          containerClassName="header-login-inner"
          contentClassName="header-login-content"
          id={searchId}
          isVisible={this.state.isLoginVisible}
          hide={this.hideModals}
        >
          <Collapse
            isOpen={this.state.isLoginVisible}
            onMeasure={this.onMeasure}
          >
            <ModalContentWrapper>
              <LoginModal {...this.props.loginModal} />
            </ModalContentWrapper>
          </Collapse>
        </Modal>

        <Modal
          animate={false}
          className="header-search"
          containerClassName="header-search-inner"
          contentClassName="header-search-content"
          hide={this.hideModals}
          id={searchId}
          isVisible={this.state.isSearchVisible}
        >
          <ContentContainer>
            <Collapse
              animateChildren={false}
              isOpen={this.state.isSearchVisible}
              onMeasure={this.onMeasure}
            >
              <GlobalSearch onMeasure={this.onMeasure} {...this.props.search} />
            </Collapse>
          </ContentContainer>
        </Modal>

        <Modal
          animate={false}
          className="header-cart"
          containerClassName="header-cart-inner"
          contentClassName="header-cart-content"
          hide={this.hideModals}
          id={cartId}
          isVisible={this.state.isCartVisible}
        >
          <Collapse
            animateChildren={false}
            isOpen={this.state.isCartVisible}
            onMeasure={this.onMeasure}
            unmountClosed={false}
          >
            <ModalContentWrapper>
              <Cart
                onUpdate={this.onCartUpdate}
                theme={Cart.themes.mini}
                {...this.props.cart}
              >
                <ArrowButton text={cartLink.text} url={cartLink.url} />
              </Cart>
            </ModalContentWrapper>
          </Collapse>
        </Modal>
      </header>
    );
  }
}

export default Header;
