import React from 'react';
import PropTypes from 'prop-types';

import Form from '../form';
import PageSpinner from '../page-spinner';

const MyPageForm = ({
  children,
  endpoint,
  onResponse,
  submitLabel,
  showSubmitButton,
  validationEndpoint
}) => (
  <Form
    endpoint={endpoint}
    onResponse={onResponse}
    submitLabel={submitLabel}
    validationEndpoint={validationEndpoint}
    showSubmitButton={showSubmitButton}
  >
    {({ isLoading }) => (
      <React.Fragment>
        <PageSpinner shouldContainInParent={true} isLoading={isLoading} />
        {children}
      </React.Fragment>
    )}
  </Form>
);

MyPageForm.propTypes = {
  children: PropTypes.node,
  endpoint: PropTypes.string,
  onResponse: PropTypes.func,
  showSubmitButton: PropTypes.bool,
  submitLabel: PropTypes.string,
  validationEndpoint: PropTypes.string
};

MyPageForm.propTypesMeta = {
  showSubmitButton: 'exclude'
};

MyPageForm.defaultProps = {
  onResponse: () => {}
};

export default MyPageForm;
