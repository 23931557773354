import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import toQueryString from '@creuna/utils/to-query-string';

import breakpoints from 'js/breakpoints';
import createFilterModel from './create-filter-model';
import propTypeTheme from 'utils/prop-type-theme';

import Button from '../button';
import CategoryFilters from '../category-filters';
import ContentContainer from '../content-container';
import Checkbox from '../form-elements/checkbox';
import EmptyListMessage from '../empty-list-message';
import Form from '../form';
import ImageLinkList from '../image-link-list';
import PageSpinner from '../page-spinner';
import ProductList from '../product-list';
import Select from '../form-elements/select';
import Search from 'components/form-elements/search';
import SolarisBanner from './solaris-banner';
import Paginator from '../paginator';
import ProductsFooter from './products-footer';

const themes = {
  search: 'theme-search'
};

class CategoryPage extends React.Component {
  static propTypes = {
    articles: PropTypes.exact(ImageLinkList.propTypes),
    articlesButtonText: PropTypes.string,
    pagination: PropTypes.exact(Paginator.propTypes),
    endpoint: PropTypes.string.isRequired,
    filters: PropTypes.exact(CategoryFilters.propTypes),
    hasMoreProducts: PropTypes.bool,
    moreProductsEndpoint: PropTypes.string,
    moreProductsLabel: PropTypes.string,
    noResultsMessage: PropTypes.string,
    numberOfProducts: PropTypes.string,
    productList: PropTypes.exact(ProductList.propTypes),
    productsButtonText: PropTypes.string,
    search: PropTypes.exact(Search.propTypes),
    showArticles: PropTypes.bool,
    showFiltersLabel: PropTypes.string,
    showNewProductsOnly: PropTypes.exact(Checkbox.propTypes),
    solarisBanner: PropTypes.exact(SolarisBanner.propTypes),
    sorting: PropTypes.exact(Select.propTypes),
    theme: propTypeTheme(themes),
    title: PropTypes.string,
    productsFooter: PropTypes.exact(ProductsFooter.propTypes)
  };

  static propTypesMeta = {
    theme: 'exclude'
  };

  static defaultProps = {
    products: []
  };

  state = {
    articles: this.props.articles,
    articlesButtonText: this.props.articlesButtonText,
    filters: this.props.filters,
    pagination: this.props.pagination,
    filtersVisible: false,
    hasMoreProducts: this.props.hasMoreProducts,
    isArticlesVisible: this.props.showArticles,
    isDesktop: true,
    isLoading: false,
    moreProductsEndpoint: this.props.moreProductsEndpoint,
    numberOfProducts: this.props.numberOfProducts,
    productList: this.props.productList,
    productsButtonText: this.props.productsButtonText,
    title: this.props.title,
    productsFooter: this.props.productsFooter
  };

  searchTerm = get(this.props.search, 'value', '');
  previousFormData = {
    [get(this.props.showNewProductsOnly, 'name', '')]: get(
      this.props.showNewProductsOnly,
      'selected'
    ),
    [get(this.props.sorting, 'name', '')]:
      get(this.props.sorting, 'options', []).find(option => option.selected) ||
      get(this.props.sorting, 'options[0].value')
  };

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.debouncedResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  }

  onFiltersChange = data => {
    if (this.state.isLoading) {
      return;
    }

    if (this.props.search) {
      const newSearchTerm = get(data, get(this.props.search, 'name', ''), '');

      if (this.searchTerm !== newSearchTerm) {
        // NOTE: We don't want to search while the user is typing
        this.searchTerm = newSearchTerm;
        return;
      }
    }

    // We need a separate check for 'show only new products' and result sorting, because these should refresh page on change
    let shouldRefresh = false;

    if (this.props.showNewProductsOnly) {
      const key = get(this.props.showNewProductsOnly, 'name', '');
      if (this.previousFormData[key] !== data[key]) {
        shouldRefresh = true;
      }
    }

    if (this.props.sorting) {
      const key = get(this.props.sorting, 'name', '');
      if (this.previousFormData[key] !== data[key]) {
        shouldRefresh = true;
      }
    }

    this.previousFormData = data;

    if ((this.formsy && this.state.isDesktop) || shouldRefresh) {
      window.history.replaceState(
        {},
        null,
        window.location.pathname + toQueryString(data)
      );
      this.formsy.submit();
    }
  };

  onResize = () => {
    this.setState({ isDesktop: breakpoints.md() });
  };

  debouncedResize = debounce(this.onResize, 400);

  hideFilters = () => {
    document.body.style.position = '';
    document.body.style.overflow = '';
    this.setState({ filtersVisible: false });
  };

  showFilters = () => {
    if (!this.state.isDesktop) {
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      this.mobileFilterContainer.scrollTop = 0;
    }
    this.setState({ filtersVisible: true });
  };

  showArticles = () => {
    this.setState({ isArticlesVisible: true });
  };

  showProducts = () => {
    this.setState({ isArticlesVisible: false });
  };

  onBeforeSubmit = () => {
    this.setState({ isLoading: true });
  };

  onResponse = (response, shouldReplaceProducts) => {
    const newFilterModel = Object.assign(
      {},
      this.formsy.getModel(),
      createFilterModel(response)
    );

    this.formsy.reset(newFilterModel);
    this.previousFormData = this.formsy.getModel();

    this.setState(
      previousState => {
        const products = get(response, 'productList.products', []);
        const oldProducts = get(previousState, 'productList.products', []);
        const newProducts = shouldReplaceProducts
          ? products
          : oldProducts.concat(products);

        return Object.assign({}, response, {
          isLoading: false,
          productList: {
            ...previousState.productList,
            products: newProducts
          },
          pagination: response.pagination
        });
      },
      () => {
        this.hideFilters();
      }
    );
  };

  render() {
    const loadMoreFormId = 'load-more-products';
    const hasProducts = get(this.state.productList, 'products', []).length > 0;

    return (
      <div className={cn('category-page', this.props.theme)}>
        <Form
          endpoint={this.props.endpoint}
          onChange={this.onFiltersChange}
          onBeforeSubmit={this.onBeforeSubmit}
          onResponse={response => {
            this.onResponse(response, true);
          }}
          onRef={formsy => {
            this.formsy = formsy;
          }}
          showSubmitButton={false}
        >
          <PageSpinner isLoading={this.state.isLoading} />
          <ContentContainer className="category-page-header">
            <h1>{this.state.title}</h1>
            {this.props.search && (
              <React.Fragment>
                <div className="category-page-search-tabs">
                  <button
                    className={cn('category-page-search-tab', {
                      'is-active': !this.state.isArticlesVisible
                    })}
                    type="button"
                    onClick={this.showProducts}
                  >
                    {this.state.productsButtonText}
                  </button>
                  <button
                    className={cn('category-page-search-tab', {
                      'is-active': this.state.isArticlesVisible
                    })}
                    type="button"
                    onClick={this.showArticles}
                  >
                    {this.state.articlesButtonText}
                  </button>
                </div>

                <div className="category-page-search">
                  <Search useFormsy={true} {...this.props.search} />
                </div>
              </React.Fragment>
            )}
          </ContentContainer>

          <div style={{ display: this.state.isArticlesVisible ? 'none' : '' }}>
            <ContentContainer className="category-page-content">
              <div
                className={cn('category-page-filters', {
                  'is-visible': this.state.filtersVisible
                })}
                ref={div => (this.mobileFilterContainer = div)}
              >
                <div className="category-page-filters-content">
                  <CategoryFilters
                    hideFilters={this.hideFilters}
                    isVisible={this.state.filtersVisible}
                    onRef={formsy => (this.formsy = formsy)}
                    {...this.state.filters}
                  />
                </div>
              </div>

              <div className="category-page-products">
                {this.props.solarisBanner && (
                  <SolarisBanner {...this.props.solarisBanner} />
                )}

                <div className="category-page-filter-sort-container">
                  <div className="category-page-number-of-products">
                    {this.state.numberOfProducts}
                  </div>

                  <div className="category-page-news">
                    {this.props.showNewProductsOnly && (
                      <Checkbox
                        idPrefix="category-show"
                        {...this.props.showNewProductsOnly}
                      />
                    )}
                  </div>

                  <div className="category-page-filter-sort">
                    <Button
                      className="category-page-show-filters"
                      onClick={this.showFilters}
                      text={this.props.showFiltersLabel}
                      theme={Button.themes.gray}
                    />

                    {this.props.sorting && (
                      <div className="category-page-sort">
                        <Select
                          idPrefix="category-page-sort"
                          shouldShowCheckmark={false}
                          theme={[
                            Select.themes.gray,
                            Select.themes.smallPadding
                          ]}
                          {...this.props.sorting}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {hasProducts ? (
                  <div className="category-page-product-list">
                    <ProductList
                      shouldAnimate={true}
                      theme={[
                        ProductList.themes.narrow,
                        ProductList.themes.noMargin
                      ]}
                      {...this.state.productList}
                    />
                  </div>
                ) : (
                  <EmptyListMessage text={this.props.noResultsMessage} />
                )}

                <div className="category-page-load-more">
                  {this.state.hasMoreProducts &&
                    !this.state.isArticlesVisible && (
                      <Button
                        formId={loadMoreFormId}
                        theme={[Button.themes.light, Button.themes.big]}
                        text={this.props.moreProductsLabel}
                        type="submit"
                      />
                    )}
                </div>
                {this.state.pagination && (
                  <div className="category-page-load-more">
                    <Paginator {...this.state.pagination} />
                  </div>
                )}

                {this.state.productsFooter && (
                  <div className="category-page-products-footer">
                    <ProductsFooter {...this.state.productsFooter} />
                  </div>
                )}
              </div>
            </ContentContainer>
          </div>
        </Form>

        <Form
          endpoint={this.state.moreProductsEndpoint}
          id={loadMoreFormId}
          onBeforeSubmit={this.onBeforeSubmit}
          onResponse={this.onResponse}
          showSubmitButton={false}
        />

        {this.state.isArticlesVisible && (
          <ContentContainer className="category-page-content">
            <ImageLinkList
              theme={ImageLinkList.themes.grid}
              {...this.state.articles}
            />
          </ContentContainer>
        )}
      </div>
    );
  }
}

CategoryPage.themes = themes;

export default CategoryPage;
