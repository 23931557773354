import React from 'react';
import PropTypes from 'prop-types';

import Grid from '../grid';
import TextInput from '../form-elements/text-input';
import MyPageForm from '../my-page-form';
import MyPageLayout from 'components/my-page-layout';
import MyPageSection from 'components/my-page-section';
import ValidationErrorTexts from 'components/validation-error-texts';

const ChangePasswordPage = ({
  form,
  layout,
  newPassword,
  oldPassword,
  section,
  validationErrors
}) => {
  return (
    <MyPageLayout {...layout}>
      <Grid theme={Grid.themes.leftColumnWider}>
        <Grid.Column>
          <MyPageSection headingLevel={1} {...section}>
            <MyPageForm {...form}>
              {oldPassword && (
                <TextInput
                  validations="isRequired"
                  validationErrors={validationErrors}
                  type="password"
                  {...oldPassword}
                />
              )}
              {newPassword && (
                <TextInput
                  validations="isRequired"
                  validationErrors={validationErrors}
                  type="password"
                  {...newPassword}
                />
              )}
            </MyPageForm>
          </MyPageSection>
        </Grid.Column>
      </Grid>
    </MyPageLayout>
  );
};

ChangePasswordPage.propTypes = {
  form: PropTypes.exact(MyPageForm.propTypes),
  layout: PropTypes.exact(MyPageLayout.propTypes),
  newPassword: PropTypes.shape(TextInput.propTypes),
  oldPassword: PropTypes.shape(TextInput.propTypes),
  section: PropTypes.exact(MyPageSection.propTypes),
  validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes)
};

export default ChangePasswordPage;
