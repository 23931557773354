import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Icon from '../icon';

const themes = {
  smallText: 'theme-small-text'
};

const ProductSellingPoints = ({ className, items, theme }) => (
  <ul className={cn('product-selling-points', className, theme)}>
    {items.map(item => (
      <li key={item}>
        <Icon name="checkmark" />
        <span>{item}</span>
      </li>
    ))}
  </ul>
);

ProductSellingPoints.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  theme: PropTypes.oneOf(Object.values(themes))
};

ProductSellingPoints.propTypesMeta = {
  className: 'exclude',
  theme: 'exclude'
};

ProductSellingPoints.defaultProps = {
  items: []
};

ProductSellingPoints.themes = themes;

export default ProductSellingPoints;
