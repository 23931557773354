// NOTE: Do not edit this file. It is built by running `/codegen/components.js`
// NOTE: The imported/exported components are exposed to the global scope

import Accordion from './components/accordion/accordion.jsx';
import AccordionList from './components/accordion-list/accordion-list.jsx';
import AddToCartButton from './components/add-to-cart-button/add-to-cart-button.jsx';
import AddToCartConfirmation from './components/add-to-cart-confirmation/add-to-cart-confirmation.jsx';
import Appointments from './components/appointments/appointments.jsx';
import ArrowButton from './components/arrow-button/arrow-button.jsx';
import ArticlePage from './components/article-page/article-page.jsx';
import AssistiveDrawer from './components/assistive-drawer/assistive-drawer.jsx';
import Availability from './components/availability/availability.jsx';
import Bomb from './components/bomb/bomb.jsx';
import BookingPage from './components/booking-page/booking-page.jsx';
import BrandPage from './components/brand-page/brand-page.jsx';
import Breadcrumbs from './components/breadcrumbs/breadcrumbs.jsx';
import Button from './components/button/button.jsx';
import CampaignBanner from './components/campaign-banner/campaign-banner.jsx';
import CampaignBannerCarousel from './components/campaign-banner-carousel/campaign-banner-carousel.jsx';
import Carousel from './components/carousel/carousel.jsx';
import Cart from './components/cart/cart.jsx';
import CategoryBlock from './components/category-block/category-block.jsx';
import CategoryFilters from './components/category-filters/category-filters.jsx';
import CategoryPage from './components/category-page/category-page.jsx';
import ChangeEmailPage from './components/change-email-page/change-email-page.jsx';
import ChangePasswordPage from './components/change-password-page/change-password-page.jsx';
import Chat from './components/chat/chat.jsx';
import CheckoutButton from './components/checkout-button/checkout-button.jsx';
import CheckoutLogin from './components/checkout-login/checkout-login.jsx';
import CheckoutLoginForm from './components/checkout-login-form/checkout-login-form.jsx';
import CheckoutLogout from './components/checkout-logout/checkout-logout.jsx';
import CheckoutPage from './components/checkout-page/checkout-page.jsx';
import CheckoutPageLegacy from './components/checkout-page-legacy/checkout-page-legacy.jsx';
import CheckoutRegisterForm from './components/checkout-register-form/checkout-register-form.jsx';
import CollapsableRichText from './components/collapsable-rich-text/collapsable-rich-text.jsx';
import CollapseList from './components/collapse-list/collapse-list.jsx';
import ComponentList from './components/component-list/component-list.jsx';
import ComponentListItem from './components/component-list-item/component-list-item.jsx';
import ConsentPage from './components/consent-page/consent-page.jsx';
import ContactLensPage from './components/contact-lens-page/contact-lens-page.jsx';
import ContainedImage from './components/contained-image/contained-image.jsx';
import ContentContainer from './components/content-container/content-container.jsx';
import CtaBlock from './components/cta-block/cta-block.jsx';
import CtaBlockSmall from './components/cta-block-small/cta-block-small.jsx';
import CtaCarousel from './components/cta-carousel/cta-carousel.jsx';
import CustomerDataImportForm from './components/customer-data-import-form/customer-data-import-form.jsx';
import Debounce from './components/debounce/debounce.jsx';
import DeliveryForm from './components/delivery-form/delivery-form.jsx';
import DeliveryWidget from './components/delivery-widget/delivery-widget.jsx';
import EditProfilePage from './components/edit-profile-page/edit-profile-page.jsx';
import EmailTemplate from './components/email-template/email-template.jsx';
import EmbedVideo from './components/embed-video/embed-video.jsx';
import EmptyListMessage from './components/empty-list-message/empty-list-message.jsx';
import ErrorPage from './components/error-page/error-page.jsx';
import FindStore from './components/find-store/find-store.jsx';
import FindStoreBlock from './components/find-store-block/find-store-block.jsx';
import FindStorePage from './components/find-store-page/find-store-page.jsx';
import FluidImage from './components/fluid-image/fluid-image.jsx';
import Footer from './components/footer/footer.jsx';
import ForgotPasswordPage from './components/forgot-password-page/forgot-password-page.jsx';
import Form from './components/form/form.jsx';
import Checkbox from './components/form-elements/checkbox/checkbox.jsx';
import CheckboxGroup from './components/form-elements/checkbox-group/checkbox-group.jsx';
import ColorSelect from './components/form-elements/color-select/color-select.jsx';
import GenderSelect from './components/form-elements/gender-select/gender-select.jsx';
import HiddenInput from './components/form-elements/hidden-input/hidden-input.jsx';
import InputTable from './components/form-elements/input-table/input-table.jsx';
import OptionGroup from './components/form-elements/option-group/option-group.jsx';
import Radio from './components/form-elements/radio/radio.jsx';
import Search from './components/form-elements/search/search.jsx';
import Select from './components/form-elements/select/select.jsx';
import ShapeSelect from './components/form-elements/shape-select/shape-select.jsx';
import TextInput from './components/form-elements/text-input/text-input.jsx';
import FormsyWrapper from './components/formsy-wrapper/formsy-wrapper.jsx';
import Frontpage from './components/frontpage/frontpage.jsx';
import GlassesPage from './components/glasses-page/glasses-page.jsx';
import GlobalSearch from './components/global-search/global-search.jsx';
import Grid from './components/grid/grid.jsx';
import Header from './components/header/header.jsx';
import Icon from './components/icon/icon.jsx';
import IframePage from './components/iframe-page/iframe-page.jsx';
import Image from './components/image/image.jsx';
import ImageLinkList from './components/image-link-list/image-link-list.jsx';
import KlarnaAndPorterbuddy from './components/klarna-and-porterbuddy/klarna-and-porterbuddy.jsx';
import KlarnaIframe from './components/klarna-iframe/klarna-iframe.jsx';
import LensVisualisation from './components/lens-visualisation/lens-visualisation.jsx';
import Link from './components/link/link.jsx';
import LinkList from './components/link-list/link-list.jsx';
import LoginForm from './components/login-form/login-form.jsx';
import LoginModal from './components/login-modal/login-modal.jsx';
import LoginPage from './components/login-page/login-page.jsx';
import Message from './components/message/message.jsx';
import Metadata from './components/metadata/metadata.jsx';
import Modal from './components/modal/modal.jsx';
import MouseoverLink from './components/mouseover-link/mouseover-link.jsx';
import MyConsents from './components/my-consents/my-consents.jsx';
import MyOrders from './components/my-orders/my-orders.jsx';
import MyPage from './components/my-page/my-page.jsx';
import MyPageForm from './components/my-page-form/my-page-form.jsx';
import MyPageLayout from './components/my-page-layout/my-page-layout.jsx';
import MyPageSection from './components/my-page-section/my-page-section.jsx';
import NewsletterForm from './components/newsletter-form/newsletter-form.jsx';
import NewsletterFormBlock from './components/newsletter-form-block/newsletter-form-block.jsx';
import OnPageEditing from './components/on-page-editing/on-page-editing.jsx';
import Openinghoursmeta from './components/openingHoursMeta/openingHoursMeta.jsx';
import OrderAgainButton from './components/order-again-button/order-again-button.jsx';
import OrderList from './components/order-list/order-list.jsx';
import OrderSummary from './components/order-summary/order-summary.jsx';
import PageHeader from './components/page-header/page-header.jsx';
import PageSpinner from './components/page-spinner/page-spinner.jsx';
import Paginator from './components/paginator/paginator.jsx';
import PersonalDetails from './components/personal-details/personal-details.jsx';
import PersonalInformationForm from './components/personal-information-form/personal-information-form.jsx';
import PersonalInformationPage from './components/personal-information-page/personal-information-page.jsx';
import Price from './components/price/price.jsx';
import ProductCard from './components/product-card/product-card.jsx';
import ProductImages from './components/product-images/product-images.jsx';
import ProductList from './components/product-list/product-list.jsx';
import ProductLogo from './components/product-logo/product-logo.jsx';
import ProductSellingPoints from './components/product-selling-points/product-selling-points.jsx';
import ProductText from './components/product-text/product-text.jsx';
import ProductVariantText from './components/product-variant-text/product-variant-text.jsx';
import Quote from './components/quote/quote.jsx';
import ReactComponent from './components/react-component/react-component.jsx';
import RegisterPage from './components/register-page/register-page.jsx';
import RegistrationBanner from './components/registration-banner/registration-banner.jsx';
import RichText from './components/rich-text/rich-text.jsx';
import SearchPage from './components/search-page/search-page.jsx';
import SellingPoints from './components/selling-points/selling-points.jsx';
import SiteInfoBanner from './components/site-info-banner/site-info-banner.jsx';
import Spinner from './components/spinner/spinner.jsx';
import StaticContainer from './components/static-container/static-container.jsx';
import StoreMap from './components/store-map/store-map.jsx';
import StorePage from './components/store-page/store-page.jsx';
import TermsForm from './components/terms-form/terms-form.jsx';
import ValidationErrorTexts from './components/validation-error-texts/validation-error-texts.jsx';
import Video from './components/video/video.jsx';

export {
  Accordion,
  AccordionList,
  AddToCartButton,
  AddToCartConfirmation,
  Appointments,
  ArrowButton,
  ArticlePage,
  AssistiveDrawer,
  Availability,
  Bomb,
  BookingPage,
  BrandPage,
  Breadcrumbs,
  Button,
  CampaignBanner,
  CampaignBannerCarousel,
  Carousel,
  Cart,
  CategoryBlock,
  CategoryFilters,
  CategoryPage,
  ChangeEmailPage,
  ChangePasswordPage,
  Chat,
  CheckoutButton,
  CheckoutLogin,
  CheckoutLoginForm,
  CheckoutLogout,
  CheckoutPage,
  CheckoutPageLegacy,
  CheckoutRegisterForm,
  CollapsableRichText,
  CollapseList,
  ComponentList,
  ComponentListItem,
  ConsentPage,
  ContactLensPage,
  ContainedImage,
  ContentContainer,
  CtaBlock,
  CtaBlockSmall,
  CtaCarousel,
  CustomerDataImportForm,
  Debounce,
  DeliveryForm,
  DeliveryWidget,
  EditProfilePage,
  EmailTemplate,
  EmbedVideo,
  EmptyListMessage,
  ErrorPage,
  FindStore,
  FindStoreBlock,
  FindStorePage,
  FluidImage,
  Footer,
  ForgotPasswordPage,
  Form,
  Checkbox,
  CheckboxGroup,
  ColorSelect,
  GenderSelect,
  HiddenInput,
  InputTable,
  OptionGroup,
  Radio,
  Search,
  Select,
  ShapeSelect,
  TextInput,
  FormsyWrapper,
  Frontpage,
  GlassesPage,
  GlobalSearch,
  Grid,
  Header,
  Icon,
  IframePage,
  Image,
  ImageLinkList,
  KlarnaAndPorterbuddy,
  KlarnaIframe,
  LensVisualisation,
  Link,
  LinkList,
  LoginForm,
  LoginModal,
  LoginPage,
  Message,
  Metadata,
  Modal,
  MouseoverLink,
  MyConsents,
  MyOrders,
  MyPage,
  MyPageForm,
  MyPageLayout,
  MyPageSection,
  NewsletterForm,
  NewsletterFormBlock,
  OnPageEditing,
  Openinghoursmeta,
  OrderAgainButton,
  OrderList,
  OrderSummary,
  PageHeader,
  PageSpinner,
  Paginator,
  PersonalDetails,
  PersonalInformationForm,
  PersonalInformationPage,
  Price,
  ProductCard,
  ProductImages,
  ProductList,
  ProductLogo,
  ProductSellingPoints,
  ProductText,
  ProductVariantText,
  Quote,
  ReactComponent,
  RegisterPage,
  RegistrationBanner,
  RichText,
  SearchPage,
  SellingPoints,
  SiteInfoBanner,
  Spinner,
  StaticContainer,
  StoreMap,
  StorePage,
  TermsForm,
  ValidationErrorTexts,
  Video
};
