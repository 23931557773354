import React from 'react';
import PropTypes from 'prop-types';

import Grid from '../grid';
import ContentContainer from '../content-container';
import GlassesHeader from './glasses-header';
import GlassesMeasurement from './glasses-measurement';
import LensVisualisation from '../lens-visualisation';
import Metadata from '../metadata';
import PageHeader from '../page-header';
import PageSpinner from '../page-spinner';
import ProductList from '../product-list';
import ProductText from 'components/product-text';

class GlassesPage extends React.Component {
  static propTypes = {
    editorialContent: PropTypes.exact(PageHeader.propTypes),
    frameMetadata: PropTypes.exact(Metadata.propTypes),
    glassesMeasurement: PropTypes.exact(GlassesMeasurement.propTypes),
    header: PropTypes.exact(GlassesHeader.propTypes),
    productTextHtml: PropTypes.string,
    lenseMetadata: PropTypes.exact(Metadata.propTypes),
    lensVisualisation: PropTypes.exact(LensVisualisation.propTypes),
    relatedProducts: PropTypes.exact(ProductList.propTypes),
    productVariantText: PropTypes.string
  };

  state = {
    ...this.props,
    isLoading: false
  };

  beforeUpdateSize = () => {
    this.setState({ isLoading: true });
  };

  updateSize = newState => {
    this.setState({ ...newState, isLoading: false });
  };

  render() {
    return (
      <div className="glasses-page">
        <PageSpinner isLoading={this.state.isLoading} />
        <ContentContainer>
          <GlassesHeader
            isLoading={this.state.isLoading}
            onBeforeUpdateSize={this.beforeUpdateSize}
            updateSize={this.updateSize}
            {...this.state.header}
            productVariantText={this.state.productVariantText}
          />
          {this.props.productTextHtml && (
            <ProductText textHtml={this.props.productTextHtml} />
          )}
          <Grid
            className="glasses-page-content"
            theme={Grid.themes.leftColumnWider}
          >
            <Grid.Column>
              <GlassesMeasurement {...this.state.glassesMeasurement} />
            </Grid.Column>
            <Grid.Column>
              <Metadata {...this.state.frameMetadata} />
            </Grid.Column>
          </Grid>
          <Grid
            className="glasses-page-content"
            theme={Grid.themes.leftColumnWider}
          >
            <Grid.Column>
              {this.state.lensVisualisation && (
                <LensVisualisation {...this.state.lensVisualisation} />
              )}
            </Grid.Column>
            <Grid.Column>
              {this.state.lenseMetadata && (
                <Metadata {...this.state.lenseMetadata} />
              )}
            </Grid.Column>
          </Grid>
        </ContentContainer>

        {this.state.editorialContent && (
          <PageHeader
            headingLevel={2}
            shouldUseBlockStyling={true}
            {...this.state.editorialContent}
          />
        )}

        <ContentContainer className="glasses-page-bottom-content">
          {this.state.relatedProducts && (
            <ProductList {...this.state.relatedProducts} />
          )}
        </ContentContainer>
      </div>
    );
  }
}

export default GlassesPage;
