import React from 'react';

import Link from 'components/link';
import RichText from 'components/rich-text';
import Store from 'components/find-store/store';

// NOTE: This component inherits propTypes from 'components/find-store/store.jsx'.
/* eslint-disable react/prop-types */
const MapPopup = ({
  name,
  address,
  phone,
  email,
  url,
  openingHours,
  postPlace,
  postCode
}) => (
  <div className="store-map-popup">
    <Link className="store-map-popup-name" text={name} url={url} />
    <p>{address}</p>
    <p>{`${postCode} ${postPlace}`}</p>

    <RichText
      className="store-map-popup-hours"
      theme={RichText.themes.centered}
      {...openingHours}
    />

    <div className="store-map-popup-contact">
      <Link text={phone} url={`tel:${phone}`} />
      <Link text={email} url={`mailto:${email}`} />
    </div>
  </div>
);

MapPopup.propTypes = Store.propTypes;

export default MapPopup;
