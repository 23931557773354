import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';

const MyConsents = ({ consentList }) => (
  <ul className="my-consents">
    {consentList.map(consent => {
      return (
        <li key={consent.id}>
          <Icon name="checkmark-micro" />
          {consent.text}
        </li>
      );
    })}
  </ul>
);

MyConsents.propTypes = {
  consentList: PropTypes.arrayOf(
    PropTypes.exact({ id: PropTypes.string, text: PropTypes.string })
  )
};

MyConsents.defaultProps = {
  consentList: []
};

export default MyConsents;
