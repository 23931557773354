import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Icon from 'components/icon';
import OptionGroup from '../option-group';

const colorNames = {
  Black: 'color-filter-black',
  Brown: 'color-filter-brown',
  Blue: 'color-filter-blue',
  Gold: 'color-filter-gold',
  Green: 'color-filter-green',
  Grey: 'color-filter-gray',
  'Grey/Black': 'color-filter-gray-black',
  Mirror: 'color-filter-mirror',
  Multi: 'color-filter-multi',
  Silver: 'color-filter-silver'
};

const ColorSelect = ({ idPrefix, name, options }) => {
  return (
    <div className="color-filter">
      <OptionGroup name={name} options={options}>
        {({ value, onChange }) => (
          <ul>
            {options.map(option =>
              colorNames[option.value] ? (
                <li key={option.value}>
                  <input
                    type="checkbox"
                    checked={value.indexOf(option.value) !== -1}
                    disabled={option.disabled}
                    id={idPrefix + name + option.value}
                    name={name}
                    onChange={e => {
                      onChange({
                        value: option.value,
                        checked: e.target.checked
                      });
                    }}
                    value={option.value}
                  />
                  <label
                    className={cn(
                      'color-filter-item',
                      colorNames[option.value]
                    )}
                    htmlFor={idPrefix + name + option.value}
                    title={option.label}
                  >
                    <span>{option.label}</span>
                    <Icon name="checkmark" />
                  </label>
                </li>
              ) : null
            )}
          </ul>
        )}
      </OptionGroup>
    </div>
  );
};

ColorSelect.propTypes = {
  idPrefix: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string,
      selected: PropTypes.bool,
      value: PropTypes.oneOf(Object.keys(colorNames)).isRequired
    })
  )
};

ColorSelect.propTypesMeta = {
  idPrefix: 'exclude'
};

ColorSelect.defaultProps = {
  options: []
};

export default ColorSelect;
