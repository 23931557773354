import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Link from '../link';

const SearchResultHeader = ({ isCentered, label, link }) => (
  <div
    className={cn('global-search-results-header', {
      'is-centered': isCentered
    })}
  >
    <span>{label}</span>
    <Link {...link} />
  </div>
);

SearchResultHeader.propTypes = {
  isCentered: PropTypes.bool,
  label: PropTypes.string,
  link: PropTypes.exact(Link.propTypes)
};

SearchResultHeader.propTypesMeta = {
  isCentered: 'exclude'
};

export default SearchResultHeader;
