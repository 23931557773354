import React from 'react';

const Context = React.createContext({
  isLoading: false,
  setIsLoading: () => {},
  iframeHTML: '',
  setIframeHTML: () => {}
});

export default Context;
