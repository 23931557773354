import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContentContainer from '../content-container';
import Image from '../image';
import OnPageEditing from '../on-page-editing';
import PageHeaderLinkList from './page-header-link-list';
import RichText from '../rich-text';
import Video from '../video';

const PageHeader = ({
  headingLevel,
  image,
  ingress,
  linkList,
  OPE,
  shouldUseBlockStyling,
  text,
  title,
  video
}) => {
  const Title = 'h' + headingLevel;

  return (
    <div
      className={cn('page-header', {
        'has-image': !!image || !!video,
        'is-block': shouldUseBlockStyling
      })}
    >
      <ContentContainer className="page-header-content">
        <OnPageEditing name={OPE.title}>
          <Title>{title}</Title>
        </OnPageEditing>

        <OnPageEditing name={OPE.ingress}>
          {ingress && (
            <div className="page-header-text">
              {ingress && (
                <RichText
                  className="page-header-ingress"
                  theme={RichText.themes.centered}
                  {...ingress}
                />
              )}
              <OnPageEditing name={OPE.linkList}>
                {linkList && <PageHeaderLinkList {...linkList} />}
              </OnPageEditing>
            </div>
          )}
        </OnPageEditing>

        <OnPageEditing name={OPE.text}>
          {text && (
            <div className="page-header-text">
              {text && <RichText text={text} />}
            </div>
          )}
        </OnPageEditing>

        {video ? (
          <div className="page-header-image">
            <Video {...video} />
          </div>
        ) : (
          <OnPageEditing name={OPE.image}>
            {image && (
              <div className="page-header-image">
                <Image {...image} />
              </div>
            )}
          </OnPageEditing>
        )}
      </ContentContainer>
    </div>
  );
};

PageHeader.propTypes = {
  headingLevel: PropTypes.number,
  image: PropTypes.exact(Image.propTypes),
  ingress: PropTypes.exact(RichText.propTypes),
  linkList: PropTypes.exact(PageHeaderLinkList.propTypes),
  OPE: PropTypes.exact({
    image: PropTypes.string,
    ingress: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string
  }),
  title: PropTypes.string,
  text: PropTypes.string,
  shouldUseBlockStyling: PropTypes.bool,
  video: PropTypes.exact(Video.propTypes)
};

PageHeader.defaultProps = {
  headingLevel: 1,
  OPE: {}
};

PageHeader.propTypesMeta = {
  headingLevel: 'exclude'
};

export default PageHeader;
