import React from 'react';
import PropTypes from 'prop-types';

import Image from '../image';

const ReplacementProductBanner = ({
  image,
  productName,
  productType,
  text,
  title
}) => (
  <div className="replacement-product-banner">
    <h2>{title}</h2>
    <h1>{productName}</h1>
    <p className="replacement-product-banner-type">{productType}</p>
    <p className="replacement-product-banner-text">{text}</p>
    {image && (
      <div className="replacement-product-banner-image">
        <Image {...image} />
      </div>
    )}
  </div>
);

ReplacementProductBanner.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  productName: PropTypes.string,
  productType: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};

export default ReplacementProductBanner;
