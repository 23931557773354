/* eslint-disable react/no-multi-comp */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import CampaignBanner from 'components/campaign-banner';
import Carousel from 'components/carousel';

import CarouselContext from '../../contexts/carousel-context';

const BannerChild = ({ item, index }) => {
  const { currentSlideIndex } = useContext(CarouselContext);

  const isCurrent = currentSlideIndex === index;

  return (
    <CampaignBanner
      theme={CampaignBanner.themes.contained}
      isFocusable={isCurrent}
      key={item.id}
      {...item}
      ctaType="campaign-banner-carousel"
    />
  );
};

BannerChild.propTypes = {
  index: PropTypes.number,
  item: PropTypes.shape(CampaignBanner.propTypes)
};

const CampaignBannerCarousel = ({
  items,
  title,
  isAutoPlayEnabled,
  autoPlayDelayInSeconds,
  pauseItemText,
  playItemText,
  stopItemText,
  previousItemText,
  nextItemText,
  carouselLabelText,
  carouselAnnouncementText
}) => {
  return (
    <div className="campaign-banner-carousel">
      <Carousel
        theme={Carousel.themes.block}
        numberOfSlidesToShow={1}
        shouldShowDots={true}
        carouselAnnouncementText={carouselAnnouncementText}
        shouldLoop={true}
        pauseItemText={pauseItemText}
        playItemText={playItemText}
        stopItemText={stopItemText}
        isAutoPlayEnabled={isAutoPlayEnabled}
        autoPlayDelayInSeconds={autoPlayDelayInSeconds}
        title={title}
        previousItemText={previousItemText}
        nextItemText={nextItemText}
        carouselLabelText={carouselLabelText}
      >
        {items.map((item, index) => (
          <BannerChild item={item} key={index} index={index} />
        ))}
      </Carousel>
    </div>
  );
};

CampaignBannerCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(CampaignBanner.propTypes)),
  title: PropTypes.string,
  carouselLabelText: PropTypes.string.isRequired,
  previousItemText: PropTypes.string,
  nextItemText: PropTypes.string,
  pauseItemText: PropTypes.string,
  playItemText: PropTypes.string,
  stopItemText: PropTypes.string,
  carouselAnnouncementText: PropTypes.string,
  isAutoPlayEnabled: PropTypes.bool,
  autoPlayDelayInSeconds: PropTypes.number
};

CampaignBannerCarousel.defaultProps = {
  items: [],
  autoPlayDelayInSeconds: 8
};

export default CampaignBannerCarousel;
