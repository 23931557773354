import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Link from '../link';

const MyPageSidebarSection = ({ children, ingress, link, linkIcon, title }) => (
  <div className="my-page-sidebar-section">
    <h2>{title}</h2>
    <p>{ingress}</p>
    {children}
    {link && (
      <Link
        className="my-page-sidebar-section-button"
        prependChildren={true}
        theme={Link.themes.skewUnderline}
        {...link}
      >
        {linkIcon && <Icon name={linkIcon} />}
      </Link>
    )}
  </div>
);

MyPageSidebarSection.propTypes = {
  children: PropTypes.node,
  ingress: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  linkIcon: PropTypes.string,
  title: PropTypes.string
};

MyPageSidebarSection.propTypesMeta = {
  linkIcon: 'exclude'
};

export default MyPageSidebarSection;
