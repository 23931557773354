import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Cookies from 'universal-cookie';
import CollapsableRichText from '../collapsable-rich-text';
import Button from '../button';
import Banner from './banner';

const COOKIE_ID = 'user_closed_info_banner';

const cookies = new Cookies();

const SiteInfoBanner = props => {
  const { cookieExpirationTimeInSeconds, ...rest } = props;

  const [showBanner, setShowBanner] = useState(false);

  const setCookie = () => {
    cookies.set(COOKIE_ID, true, {
      path: '/',
      maxAge: cookieExpirationTimeInSeconds
    });
  };

  const handleOnClick = () => {
    setShowBanner(false);
    setCookie();
  };

  useEffect(() => {
    setShowBanner(!cookies.get(COOKIE_ID));
  }, []);

  return showBanner ? <Banner onClick={handleOnClick} {...rest} /> : null;
};

SiteInfoBanner.defaultProps = {
  cookieExpirationTimeInSeconds: 86400 // 24 hours
};

SiteInfoBanner.propTypes = {
  title: PropTypes.string,
  cookieExpirationTimeInSeconds: PropTypes.number,
  onClick: PropTypes.func,
  richText: PropTypes.exact(CollapsableRichText.propTypes),
  buttonText: PropTypes.string,
  link: PropTypes.exact(Button.propTypes)
};

export default SiteInfoBanner;
