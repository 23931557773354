import React from 'react';
import PropTypes from 'prop-types';

import Grid from '../grid';
import TextInput from '../form-elements/text-input';
import MyPageForm from '../my-page-form';
import MyPageLayout from 'components/my-page-layout';
import MyPageSection from 'components/my-page-section';
import ValidationErrorTexts from 'components/validation-error-texts';

const ForgotPasswordPage = ({
  form,
  email,
  layout,
  section,
  validationErrors
}) => {
  return (
    <MyPageLayout {...layout}>
      <Grid theme={Grid.themes.leftColumnWider}>
        <Grid.Column>
          <MyPageSection headingLevel={1} {...section}>
            <MyPageForm {...form}>
              <TextInput
                validations="isRequired,isEmail"
                isRequired={true}
                validationErrors={validationErrors}
                {...email}
              />
            </MyPageForm>
          </MyPageSection>
        </Grid.Column>
      </Grid>
    </MyPageLayout>
  );
};

ForgotPasswordPage.propTypes = {
  form: PropTypes.exact(MyPageForm.propTypes),
  email: PropTypes.exact(TextInput.propTypes),
  layout: PropTypes.exact(MyPageLayout.propTypes),
  section: PropTypes.exact(MyPageSection.propTypes),
  validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes)
};

export default ForgotPasswordPage;
