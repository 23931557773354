/* eslint-disable react/no-multi-comp */
import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import Carousel from 'components/carousel';
import CtaBlock from 'components/cta-block';

import CarouselContext from '../../contexts/carousel-context';

const BlockChild = ({ block, index }) => {
  const { currentSlideIndex } = useContext(CarouselContext);

  const isCurrent = currentSlideIndex === index;

  return (
    <div>
      <CtaBlock {...block} isFocusable={isCurrent} />
    </div>
  );
};

BlockChild.propTypes = {
  index: PropTypes.number,
  block: PropTypes.exact(CtaBlock.propTypes)
};

BlockChild.propTypesMeta = 'exclude';

const CtaCarousel = ({
  title,
  blocks,
  isAutoPlayEnabled,
  autoPlayDelayInSeconds,
  pauseItemText,
  playItemText,
  stopItemText,
  previousItemText,
  nextItemText,
  carouselLabelText,
  carouselAnnouncementText
}) => {
  return (
    <div className="cta-carousel">
      <Carousel
        theme={Carousel.themes.cta}
        numberOfSlidesToShow={1}
        shouldShowDots={true}
        carouselAnnouncementText={carouselAnnouncementText}
        shouldLoop={true}
        pauseItemText={pauseItemText}
        playItemText={playItemText}
        stopItemText={stopItemText}
        isAutoPlayEnabled={isAutoPlayEnabled}
        autoPlayDelayInSeconds={autoPlayDelayInSeconds}
        title={title}
        previousItemText={previousItemText}
        nextItemText={nextItemText}
        carouselLabelText={carouselLabelText}
      >
        {blocks.map((block, index) => {
          return (
            <Fragment key={index}>
              <BlockChild block={block} index={index} />
            </Fragment>
          );
        })}
      </Carousel>
    </div>
  );
};

CtaCarousel.propTypes = {
  title: PropTypes.string,
  carouselLabelText: PropTypes.string.isRequired,
  previousItemText: PropTypes.string,
  nextItemText: PropTypes.string,
  pauseItemText: PropTypes.string,
  playItemText: PropTypes.string,
  stopItemText: PropTypes.string,
  carouselAnnouncementText: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.exact(CtaBlock.propTypes)),
  isAutoPlayEnabled: PropTypes.bool,
  autoPlayDelayInSeconds: PropTypes.number
};

CtaCarousel.propTypesMeta = {};

CtaCarousel.defaultProps = {
  blocks: [],
  autoPlayDelayInSeconds: 0
};

export default CtaCarousel;
