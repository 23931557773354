import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '../accordion';

const AccordionList = ({ items }) =>
  !items.length ? null : (
    <ul className="accordion-list">
      {items.map(item => (
        <li key={item.id}>
          <Accordion showBorder={false} {...item} />
        </li>
      ))}
    </ul>
  );

AccordionList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(Accordion.propTypes))
};

AccordionList.defaultProps = {
  items: []
};

export default AccordionList;
