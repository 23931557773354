import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

const Price = ({
  children,
  className,
  discountedPrice,
  element,
  originalPrice,
  priceLabel,
  theme
}) => {
  const Element = element;
  const smallPrice = discountedPrice && (
    <div className={cn('price-small', theme)} key="price-small">
      {originalPrice}
    </div>
  );
  const bigPrice = (
    <div
      className={cn('price-big', theme, {
        'has-discount': discountedPrice
      })}
      key="price-big"
    >
      {priceLabel && <span className="price-label">{priceLabel}</span>}
      {discountedPrice || originalPrice}
    </div>
  );

  return children ? (
    children(smallPrice, bigPrice)
  ) : (
    <Element className={cn('price', className, theme)}>
      {smallPrice}
      {bigPrice}
    </Element>
  );
};

const themes = {
  alignLeft: 'theme-align-left',
  bigFont: 'theme-big-font',
  centered: 'theme-centered',
  lineThrough: 'theme-line-through',
  mediumFontWeight: 'theme-medium-font-weight',
  orange: 'theme-orange',
  smallFont: 'theme-small-font',
  vertical: 'theme-vertical',
  white: 'theme-white'
};

Price.propTypes = {
  children: PropTypes.func,
  className: PropTypes.string,
  discountedPrice: PropTypes.string,
  element: PropTypes.string,
  originalPrice: PropTypes.string,
  priceLabel: PropTypes.string,
  theme: propTypeTheme(themes)
};

Price.propTypesMeta = {
  className: 'exclude',
  element: 'exclude',
  theme: 'exclude'
};

Price.defaultProps = {
  element: 'div'
};

Price.themes = themes;

export default Price;
