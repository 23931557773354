import React from 'react';

import CtaBlock from '../cta-block';

const CtaBlockSmall = props => (
  <CtaBlock theme={CtaBlock.themes.mini} {...props} />
);

CtaBlockSmall.propTypes = CtaBlock.propTypes;

export default CtaBlockSmall;
