import React from 'react';
import PropTypes from 'prop-types';

import embed from 'embed-video';

import OnPageEditing from '../on-page-editing';

const EmbedVideo = ({ OPE, url }) => {
  const iframe = embed(url) || '';

  return (
    <div className="embed-video">
      <OnPageEditing name={OPE.url} editType={OnPageEditing.editTypes.flyout}>
        <div dangerouslySetInnerHTML={{ __html: iframe }} />
      </OnPageEditing>
    </div>
  );
};

EmbedVideo.propTypes = {
  OPE: PropTypes.exact({
    url: PropTypes.string
  }),
  url: PropTypes.string.isRequired
};

EmbedVideo.defaultProps = {
  OPE: {}
};

export default EmbedVideo;
