import React from 'react';
import PropTypes from 'prop-types';

import { Motion, spring } from 'react-motion';
import rangeMap from '@creuna/utils/range-map';
import transform from 'css-transform-string';

import ImageLoader from 'js/responsive-images';

import Button from 'components/button';
import Icon from 'components/icon';
import Spinner from 'components/spinner';

class ZoomModal extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    imagePadding: PropTypes.number,
    imageWidth: PropTypes.number,
    onCloseButtonClick: PropTypes.func.isRequired
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    imagePadding: 100,
    imageWidth: 1400
  };

  state = {
    imageHeight: 0,
    posX: 0,
    posY: 0
  };

  onMouseMove = e => {
    const padding = this.props.imagePadding;
    const { imageWidth } = this.props;
    const { imageHeight } = this.state;
    const winW = window.innerWidth;
    const winH = window.innerHeight;
    const xDiff = (imageWidth - winW) / 2 + padding;
    const yDiff = (imageHeight - winH) / 2 + padding;

    this.setState({
      posX:
        winW < imageWidth + 2 * padding
          ? rangeMap(e.clientX, 0, winW, xDiff, -xDiff)
          : 0,
      posY:
        winH < imageHeight + 2 * padding
          ? rangeMap(e.clientY, 0, winH, yDiff, -yDiff)
          : 0
    });
  };

  componentDidMount() {
    if (!this.image.complete || !this.image.naturalHeight) {
      this.image.onload = () => {
        this.setState({ imageHeight: this.image.offsetHeight });
      };
    } else {
      this.setState({ imageHeight: this.image.offsetHeight });
    }
  }

  render() {
    return (
      <div className="product-image-zoom" onMouseMove={this.onMouseMove}>
        <div className="product-image-zoom-spinner">
          <Spinner theme={Spinner.themes.big} />
        </div>

        <Motion
          defaultStyle={{ x: 0, y: 0 }}
          style={{ x: spring(this.state.posX), y: spring(this.state.posY) }}
        >
          {interpolatedStyle => (
            <img
              src={ImageLoader.getNewSrc(this.props.src, this.props.imageWidth)}
              style={{
                width: this.props.imageWidth,
                transform: transform(interpolatedStyle)
              }}
              ref={img => (this.image = img)}
            />
          )}
        </Motion>
        <Button
          className="product-image-zoom-close"
          onClick={this.props.onCloseButtonClick}
        >
          <Icon name="big-x" />
        </Button>
      </div>
    );
  }
}

export default ZoomModal;
