import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import FluidImage from '../fluid-image';
import Link from 'components/link';

const themes = {
  grid: 'theme-grid'
};

const columnClassNames = {
  2: 'two-columns',
  3: 'three-columns'
};

const ImageLinkList = ({ columns, items, title, theme }) => (
  <div className={cn('image-link-list', columnClassNames[columns], theme)}>
    {title && <h2>{title}</h2>}

    <ul>
      {items.map(item => (
        <li key={item.text + item.url}>
          <Link
            url={item.url}
            className={cn({ 'has-image': !!item.image })}
            theme={[Link.themes.noStyle, Link.themes.skew]}
          >
            {item.image ? (
              <React.Fragment>
                <div className="image-link-list-image">
                  <div className="image-link-list-image-inner">
                    <FluidImage
                      {...item.image}
                      imageShouldCoverContainer={false}
                    />
                  </div>
                </div>
                <span className="image-link-list-text">{item.text}</span>
                {item.ingress && (
                  <span className="image-link-list-ingress">
                    {item.ingress}
                  </span>
                )}
              </React.Fragment>
            ) : (
              <span className="image-link-list-big-text">{item.text}</span>
            )}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

ImageLinkList.propTypes = {
  columns: PropTypes.oneOf([2, 3]),
  title: PropTypes.string,
  theme: propTypeTheme(themes),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ingress: PropTypes.string,
      image: PropTypes.exact(FluidImage.propTypes),
      text: PropTypes.string,
      url: PropTypes.string
    })
  )
};

ImageLinkList.propTypesMeta = {
  theme: 'exclude'
};

ImageLinkList.defaultProps = {
  columns: 3,
  items: []
};

ImageLinkList.themes = themes;

export default ImageLinkList;
