import React from 'react';
import PropTypes from 'prop-types';

import ContentContainer from '../content-container';

const BreadCrumbs = ({ items }) =>
  !items.length ? null : (
    <div className="breadcrumbs">
      <ContentContainer>
        <ul itemScope itemType="http://schema.org/BreadcrumbList">
          {items.map(({ text, url }, index) => (
            <li
              key={text + url}
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              {url ? (
                <a href={url} itemProp="item">
                  <span itemProp="name">{text}</span>
                </a>
              ) : (
                <span itemProp="name">{text}</span>
              )}
              <meta itemProp="position" content={index + 1} />
            </li>
          ))}
        </ul>
      </ContentContainer>
    </div>
  );

BreadCrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string.isRequired
    })
  )
};

BreadCrumbs.defaultProps = {
  items: []
};

export default BreadCrumbs;
