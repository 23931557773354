import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import CheckoutButton from 'components/checkout-button';

const CheckoutLogout = ({
  buttonText,
  logoutText,
  logoutUrl,
  onComplete,
  text
}) => {
  return (
    <div className="checkout-logout">
      <div className="checkout-logout-content">
        <p>{text}</p>
        <Button
          className="checkout-logout-button"
          url={logoutUrl}
          text={logoutText}
          theme={Button.themes.outline}
        />
      </div>

      <CheckoutButton onClick={onComplete} text={buttonText} />
    </div>
  );
};

CheckoutLogout.propTypes = {
  buttonText: PropTypes.string.isRequired,
  logoutText: PropTypes.string,
  logoutUrl: PropTypes.string,
  onComplete: PropTypes.func,
  text: PropTypes.string.isRequired
};

export default CheckoutLogout;
