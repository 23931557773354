import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import api from '../../js/api-helper';
import deepClone from '@creuna/utils/deep-clone';
import isEqual from '@creuna/utils/is-equal';

import Button from '../button';
import Formsy from 'formsy-react';
import './formsy-with-validation';

class Form extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    className: PropTypes.string,
    endpoint: PropTypes.string.isRequired,
    id: PropTypes.string,
    onBeforeSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onInvalidSubmit: PropTypes.func,
    onResponse: PropTypes.func,
    onRef: PropTypes.func,
    onValidate: PropTypes.func, // Local Formsy validation
    onValidationResponse: PropTypes.func, // Server side validation
    showSubmitButton: PropTypes.bool,
    submitLabel: PropTypes.string,
    validationEndpoint: PropTypes.string
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    onBeforeSubmit: () => {},
    onChange: () => {},
    onInvalidSubmit: () => {},
    onResponse: () => {},
    onRef: () => {},
    onValidate: () => {},
    onValidationResponse: () => {},
    showSubmitButton: true
  };

  state = {
    isLoading: false,
    isValidating: false
  };

  formData = {};

  isValidationModel = responseObject => {
    if (!Object.keys(responseObject).length) {
      return false;
    }

    const model = this.formsy.getModel();
    return Object.keys(responseObject).every(
      key => Object.keys(model).indexOf(key) !== -1
    );
  };

  onChange = formData => {
    if (!isEqual(formData, this.formData)) {
      this.props.onChange(formData);
      this.formData = deepClone(formData);
    }
  };

  onValidSubmit = (formData, resetForm, invalidateForm) => {
    if (this.state.isLoading) {
      return;
    }

    // Use formdata from onBeforeSubmit only if it returned data
    formData = this.props.onBeforeSubmit(formData)
      ? this.props.onBeforeSubmit(formData)
      : formData;

    if (this.props.validationEndpoint) {
      this.setState({ isLoading: true }, () => {
        api
          .execute(this.props.validationEndpoint, formData)
          .then(response => {
            if (this.isValidationModel(response)) {
              invalidateForm(response);
              this.props.onValidationResponse(false);
              this.setState({ isLoading: false });
            } else {
              this.props.onValidationResponse(true);
              this.doApiSubmit(formData);
            }
          })
          .catch(() => {
            this.setState({ isLoading: false });
            this.props.onResponse({});
          });
      });
    } else {
      this.doApiSubmit(formData);
    }
  };

  doApiSubmit = formData => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        api
          .execute(this.props.endpoint, formData)
          .then(response => {
            this.setState({ isLoading: false });
            this.props.onResponse(response);
          })
          .catch(() => {
            this.setState({ isLoading: false });
            this.props.onResponse({});
          });
      }
    );
  };

  render() {
    return (
      <Formsy
        action={this.props.endpoint}
        className={cn('form', this.props.className)}
        id={this.props.id}
        method="post"
        onChange={this.onChange}
        onValid={() => this.props.onValidate(true)}
        onValidSubmit={this.onValidSubmit}
        onInvalid={() => this.props.onValidate(false)}
        onInvalidSubmit={this.props.onInvalidSubmit}
        ref={formsy => {
          this.formsy = formsy;
          this.props.onRef(formsy);
        }}
        tabIndex={-1}
        noValidate
      >
        {typeof this.props.children === 'function'
          ? this.props.children({ isLoading: this.state.isLoading })
          : this.props.children}
        {this.props.showSubmitButton && (
          <div className="form-nav">
            <Button
              className="form-submit"
              disabled={this.state.isLoading}
              text={this.props.submitLabel}
              type="submit"
            />
          </div>
        )}
      </Formsy>
    );
  }
}

export default Form;
