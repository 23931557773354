import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Image from 'components/image';

// NOTE: The 'selected' prop is used by 'create-filter-model.js' to reset the input state on API response
const ShapeSelectItem = ({
  checked,
  disabled,
  idPrefix,
  image,
  label,
  name,
  onChange,
  selected, // eslint-disable-line no-unused-vars
  value
}) => {
  const id = idPrefix + '-' + name + '-' + value;

  return (
    <div className="shape-select-item">
      <input
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        type="checkbox"
        onChange={e => {
          onChange({ value: value, checked: e.target.checked });
        }}
        value={value}
      />
      <label htmlFor={id}>
        <div className="shape-select-image">
          <Image responsive={false} {...image} />
          <div className="shape-select-checkmark">
            <Icon name="checkmark" />
          </div>
        </div>

        <div className="shape-select-label">{label}</div>
      </label>
    </div>
  );
};

ShapeSelectItem.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  idPrefix: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  value: PropTypes.string
};

ShapeSelectItem.propTypesMeta = {
  checked: 'exclude',
  idPrefix: 'exclude',
  name: 'exclude' // NOTE: name is provided by ShapeSelect
};

export default ShapeSelectItem;
