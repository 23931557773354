import React from 'react';
import PropTypes from 'prop-types';

import Grid from '../grid';
import MyPageForm from '../my-page-form';
import MyPageLayout from 'components/my-page-layout';
import MyPageSection from 'components/my-page-section';
import PersonalInformationForm from '../personal-information-form';

const PersonalInformationPage = ({
  form,
  formContent,
  layout,
  section,
  showDeliveryAddress
}) => {
  return (
    <MyPageLayout {...layout}>
      <Grid theme={Grid.themes.leftColumnWider}>
        <Grid.Column>
          <MyPageSection headingLevel={1} {...section}>
            <MyPageForm {...form}>
              <PersonalInformationForm
                showDeliveryAddress={showDeliveryAddress}
                showDeliveryAddressCheckbox={false}
                {...formContent}
              />
            </MyPageForm>
          </MyPageSection>
        </Grid.Column>
      </Grid>
    </MyPageLayout>
  );
};

PersonalInformationPage.propTypes = {
  form: PropTypes.exact(MyPageForm.propTypes),
  formContent: PropTypes.exact(PersonalInformationForm.propTypes),
  layout: PropTypes.exact(MyPageLayout.propTypes),
  section: PropTypes.exact(MyPageSection.propTypes),
  showDeliveryAddress: PropTypes.bool
};

PersonalInformationPage.propTypesMeta = {
  showDeliveryAddress: 'exclude'
};

export default PersonalInformationPage;
