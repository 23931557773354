import PropTypes from 'prop-types';

const PaginatorLink = () => {};

PaginatorLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default PaginatorLink;
