import React from 'react';
import PropTypes from 'prop-types';

import isRunningOnClient from '@creuna/utils/is-running-on-client';

import api from 'js/api-helper';

import Button from 'components/button';
import Cart from 'components/cart';
import KlarnaIFrame from 'components/klarna-iframe';
import MyPageSection from 'components/my-page-section';
import MyPageLayout from 'components/my-page-layout';
import OrderAgainButton from 'components/order-again-button';
import RegistrationBanner from 'components/registration-banner';

const print = () => {
  window.print();
};

class OrderSummary extends React.Component {
  static propTypes = {
    layout: PropTypes.exact(MyPageLayout.propTypes),
    getIframeEndpoint: PropTypes.string,
    orderAgain: PropTypes.exact(OrderAgainButton.propTypes),
    orderDetails: PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string,
        detail: PropTypes.string
      })
    ),
    cart: PropTypes.exact(Cart.propTypes),
    printLabel: PropTypes.string,
    section: PropTypes.exact(MyPageSection.propTypes),
    registrationBanner: PropTypes.exact(RegistrationBanner.propTypes),
    orderConfirmationHeading: PropTypes.string,
    orderConfirmationDescription: PropTypes.string
  };

  static defaultProps = {
    orderDetails: []
  };

  state = {
    iframeHTML: null
  };

  componentDidMount() {
    if (this.props.getIframeEndpoint) {
      api.execute(this.props.getIframeEndpoint).then(({ iframeHTML }) => {
        this.setState({ iframeHTML });
      });
    }
  }

  render() {
    const {
      orderConfirmationHeading,
      orderConfirmationDescription
    } = this.props;

    return (
      <MyPageLayout {...this.props.layout}>
        <MyPageSection headingLevel={1} {...this.props.section}>
          {this.props.registrationBanner && (
            <RegistrationBanner {...this.props.registrationBanner} />
          )}
          {orderConfirmationHeading && (
            <h2 className="order-confirmation-heading">
              {orderConfirmationHeading}
            </h2>
          )}
          {orderConfirmationDescription && (
            <p className="order-confirmation-description">
              {orderConfirmationDescription}
            </p>
          )}
          {this.state.iframeHTML && (
            <KlarnaIFrame
              htmlString={this.state.iframeHTML}
              shouldEvalScript={true}
            />
          )}
          <div className="order-summary-info">
            <table>
              <thead>
                <tr>
                  {this.props.orderDetails.map(order => (
                    <th key={order.label}>{order.label}</th>
                  ))}
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  {this.props.orderDetails.map(order => (
                    <td key={order.detail}>
                      <span>{order.label}</span>
                      <span>{order.detail}</span>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <Cart shouldBeEditable={false} {...this.props.cart}>
            <div className="order-summary-button-wrapper">
              {this.props.orderAgain && (
                <OrderAgainButton {...this.props.orderAgain} />
              )}
              {isRunningOnClient && (
                <Button
                  className="order-summary-print-button"
                  onClick={print}
                  text={this.props.printLabel}
                  theme={Button.themes.outline}
                />
              )}
            </div>
          </Cart>
        </MyPageSection>
      </MyPageLayout>
    );
  }
}

export default OrderSummary;
