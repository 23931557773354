import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

// NOTE: This component exists so that the server can have a feature toggle for signup/login on the checkout page. CheckoutPageLegacy is very similar to CheckoutPage except that it doesn't render any signup/login forms.
import CheckoutContext from '../checkout-page/checkout-context';
import Cart from 'components/cart';
import CheckoutStep from '../checkout-page/checkout-step';

import CheckoutButton from 'components/checkout-button';
import ContentContainer from '../content-container';
import TermsForm from 'components/terms-form';
import KlarnaIframe from '../klarna-iframe';
import PageSpinner from '../page-spinner';
import api from 'js/api-helper';

const stepIds = {
  cart: 'cart',
  terms: 'terms',
  payment: 'payment'
};

const CheckoutPageLegacy = ({
  analyticsEndpoint,
  cart,
  cartButtonText,
  cartTitle,
  collapseLabel,
  deliveryAndTerms,
  deliveryAndTermsTitle,
  expandLabel,
  klarnaTitle,
  title
}) => {
  const [iframeHTML, setIframeHTML] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [stepCompletenessMap, setStepCompletnessMap] = React.useState({
    [stepIds.cart]: false,
    [stepIds.terms]: false,
    [stepIds.payment]: false
  });

  const stepIsComplete = stepId => stepCompletenessMap[stepId];
  const setStepComplete = React.useCallback((stepId, stepNumber) => {
    // NOTE: `stepNumber` is the number of the completed step (the previously active step). The analytics endpoint is interested in what the currently active step is, which is the step after `stepNumber`.
    analyticsEndpoint &&
      api.execute(analyticsEndpoint, { currentActiveStep: stepNumber + 1 });
    setStepCompletnessMap(validities => ({ ...validities, [stepId]: true }));
  }, []);

  const onCartUpdate = React.useCallback(() => {
    // If the Klarna iframe has been loaded, the page needs to be refreshed when the cart changes in order to update the cart in the iframe.
    iframeHTML && window.location.reload();
  }, [iframeHTML]);

  return (
    <CheckoutContext.Provider value={{ isLoading, setIsLoading }}>
      <PageSpinner isLoading={isLoading} />

      <div className="checkout-page">
        <ContentContainer>
          <h1>{title}</h1>

          <CheckoutStep
            collapseLabel={collapseLabel}
            expandLabel={expandLabel}
            isComplete={stepIsComplete(stepIds.cart)}
            isEnabled={get(cart, 'products', []).length > 0}
            number={1}
            theme={CheckoutStep.themes.padding}
            title={cartTitle}
          >
            <React.Fragment>
              <Cart onUpdate={onCartUpdate} {...cart} />
              <CheckoutButton
                attributes={{ 'data-test-checkout-step-1-button': '' }}
                onClick={() => setStepComplete(stepIds.cart, 1)}
                text={cartButtonText}
              />
            </React.Fragment>
          </CheckoutStep>

          {deliveryAndTerms && (
            <CheckoutStep
              collapseLabel={collapseLabel}
              expandLabel={expandLabel}
              forceInitialAnimation={true}
              isEnabled={stepIsComplete(stepIds.cart)}
              isComplete={stepIsComplete(stepIds.terms)}
              number={2}
              theme={CheckoutStep.themes.padding}
              title={deliveryAndTermsTitle}
            >
              <TermsForm
                onResponse={({ iframeHTML }) => {
                  if (iframeHTML) {
                    setStepComplete(stepIds.terms, 2);
                    setIframeHTML(iframeHTML);
                  }
                }}
                {...deliveryAndTerms}
              />
            </CheckoutStep>
          )}

          <CheckoutStep
            collapseLabel={collapseLabel}
            expandLabel={expandLabel}
            forceInitialAnimation={true}
            isEnabled={stepIsComplete(stepIds.terms) && Boolean(iframeHTML)}
            isComplete={false}
            number={3}
            title={klarnaTitle}
          >
            {iframeHTML && (
              <KlarnaIframe htmlString={iframeHTML} shouldEvalScript={true} />
            )}
          </CheckoutStep>
        </ContentContainer>
      </div>
    </CheckoutContext.Provider>
  );
};

CheckoutPageLegacy.propTypes = {
  analyticsEndpoint: PropTypes.string,
  cart: PropTypes.exact(Cart.propTypes),
  cartButtonText: PropTypes.string,
  cartTitle: PropTypes.string,
  collapseLabel: PropTypes.string,
  deliveryAndTerms: PropTypes.exact(TermsForm.propTypes),
  deliveryAndTermsTitle: PropTypes.string,
  expandLabel: PropTypes.string,
  klarnaTitle: PropTypes.string,
  title: PropTypes.string
};

export default CheckoutPageLegacy;
