import React from 'react';
import PropTypes from 'prop-types';

const EmailTemplateSummary = ({
  addressLabel,
  address,
  customerName,
  discount,
  discountLabel,
  email,
  paymentMethodLabel,
  paymentMethod,
  phone,
  reorderLink,
  shippingLabel,
  shippingCost,
  totalPriceLabel,
  totalPrice
}) => (
  <div className="email-template-summary">
    <div className="email-template-summary-price">
      <p>
        {discountLabel}: <strong>{discount}</strong>
      </p>
      <p>
        {shippingLabel}: <strong>{shippingCost}</strong>
      </p>
      <p className="email-template-summary-total-price">
        <span>
          {totalPriceLabel}: <strong>{totalPrice}</strong>
        </span>
      </p>
      <a href={reorderLink.url}>{reorderLink.text}</a>
    </div>
    <div className="email-template-payment-informastion">
      <p>
        <strong>{paymentMethodLabel}</strong>
      </p>
      <p>{paymentMethod}</p>
    </div>
    <div className="email-template-delivery-information">
      <p>
        <strong>{addressLabel}</strong>
      </p>
      <p>{customerName}</p>
      <p>{phone}</p>
      <p>
        <a href={`mailto: ${email}`}>{email}</a>
      </p>
      <p>{address}</p>
    </div>
  </div>
);

EmailTemplateSummary.propTypes = {
  addressLabel: PropTypes.string,
  address: PropTypes.string,
  customerName: PropTypes.string,
  discount: PropTypes.string,
  discountLabel: PropTypes.string,
  email: PropTypes.string,
  paymentMethodLabel: PropTypes.string,
  paymentMethod: PropTypes.string,
  phone: PropTypes.string,
  reorderLink: PropTypes.object,
  shippingLabel: PropTypes.string,
  shippingCost: PropTypes.string,
  totalPriceLabel: PropTypes.string,
  totalPrice: PropTypes.string
};

EmailTemplateSummary.propTypesMeta = 'exclude';

export default EmailTemplateSummary;
