import PubSub from 'pubsub-js';

import topics from 'js/topics.json';

function onTopic(topic, func) {
  if (typeof func !== 'function') {
    return;
  }

  return PubSub.subscribe(topic, (topic, { message, title }) => {
    func({ message, title });
  });
}

function send(topic, { message, title }) {
  PubSub.publish(topic, { message, title });
}

const onErrorMessage = func => onTopic(topics.errorMessage, func);

function off(token) {
  if (!token) {
    return;
  }

  PubSub.unsubscribe(token);
}

const onMessage = func => onTopic(topics.message, func);

const sendErrorMessage = ({ message, title }) =>
  send(topics.errorMessage, { message, title });

const sendMessage = ({ message, title }) =>
  send(topics.message, { message, title });

export default {
  onErrorMessage,
  off,
  onMessage,
  sendErrorMessage,
  sendMessage
};
