import React from 'react';
import PropTypes from 'prop-types';

import PubSub from 'pubsub-js';

import topics from 'js/topics.json';

import Collapse from 'react-tiny-collapse';

import Button from '../button';
import ArrowButton from '../arrow-button';
import CartProduct from '../cart/cart-product';
import Icon from '../icon';
import Modal from '../modal';
import PageSpinner from '../page-spinner';

class AddToCartConfirmation extends React.Component {
  static propTypes = {
    cartLink: PropTypes.exact(ArrowButton.propTypes),
    closeButtonText: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.exact(CartProduct.propTypes)),
    showAddToCartConfirmation: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    showAddToCartConfirmation: true
  };

  state = {
    isLoading: false,
    isVisible: false,
    products: []
  };

  componentDidMount() {
    this.subscriber = PubSub.subscribe(
      topics.cartUpdate,
      (msg, { confirmation }) => {
        if (!confirmation || !confirmation.products) return;

        this.setState({
          ...confirmation,
          isVisible: this.props.showAddToCartConfirmation
        });
      }
    );
  }

  hide = () => {
    this.setState({ isVisible: false });
  };

  onProductEdit = () => {
    this.setState({ isLoading: true });
  };

  onProductEditResponse = response => {
    this.setState({ isLoading: false });
    PubSub.publish(topics.cartUpdate, response);
  };

  render() {
    return (
      <Modal
        containerClassName="add-to-cart-confirmation"
        hide={this.hide}
        isVisible={this.state.isVisible}
        showCloseButton={false}
      >
        <PageSpinner
          shouldContainInParent={true}
          isLoading={this.state.isLoading}
        />

        <div
          data-test-add-to-cart-confirmation
          className="add-to-cart-confirmation-content"
        >
          <h2>
            <Icon className="add-to-cart-confirmation-icon" name="checkmark" />
            {this.props.title}
          </h2>
          {this.state.products.length > 0 && (
            <React.Fragment>
              <Collapse isOpen={true} unmountClosed={false}>
                <table>
                  {this.state.products.map(product => (
                    <CartProduct
                      onSubmit={this.onProductEdit}
                      onUpdate={this.onProductEditResponse}
                      key={product.id}
                      shouldBeEditable={false}
                      shouldShowDetails={false}
                      {...product}
                    />
                  ))}
                </table>
              </Collapse>
              <div className="add-to-cart-confirmation-buttons">
                <div className="add-to-cart-confirmation-button">
                  <Button
                    className="add-to-cart-confirmation-close"
                    onClick={this.hide}
                    text={this.props.closeButtonText}
                  />
                </div>
                <div className="add-to-cart-confirmation-button">
                  <ArrowButton
                    attributes={{
                      'data-test-confirmation-go-to-cart-button': ''
                    }}
                    {...this.props.cartLink}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </Modal>
    );
  }
}

export default AddToCartConfirmation;
