import React from 'react';
import PropTypes from 'prop-types';

import OnPageEditing from '../on-page-editing';

const Quote = ({ OPE, text }) => (
  <blockquote className="quote">
    <OnPageEditing element="span" name={OPE.text}>
      {text}
    </OnPageEditing>
  </blockquote>
);

Quote.propTypes = {
  OPE: PropTypes.exact({
    text: PropTypes.string
  }),
  text: PropTypes.string
};

Quote.defaultProps = {
  OPE: {}
};

export default Quote;
