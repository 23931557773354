import React from 'react';
import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';

class CheckboxFormsy extends React.Component {
  static propTypes = {
    children: PropTypes.func,
    getValue: PropTypes.func, // From Formsy HOC
    setValue: PropTypes.func, // From Formsy HOC
    initiallyChecked: PropTypes.bool
  };

  static defaultProps = {
    onChange: () => {}
  };

  onChange = ({ checked }) => {
    this.props.setValue(checked);
  };

  componentDidMount() {
    if (typeof this.props.initiallyChecked !== 'undefined') {
      this.props.setValue(this.props.initiallyChecked);
    }
  }

  render() {
    return this.props.children({
      checked: !!this.props.getValue(),
      onChange: this.onChange
    });
  }
}

export default withFormsy(CheckboxFormsy);
