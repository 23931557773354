import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from '../link';
import PaginatorLink from './paginator-link';

const Paginator = ({ links, previousLink, nextLink }) => (
  <div className="paginator">
    <div className="paginator__content">
      {previousLink && (
        <div
          className={cn('paginator__navigation', {
            'paginator__navigation--disabled': previousLink.isDisabled,
          })}
        >
          {previousLink.isDisabled ? (
            <span className="paginator__navigation-placeholder">
              {previousLink.text}
            </span>
          ) : (
            <Link
              text={previousLink.text}
              url={previousLink.url}
              theme={Link.themes.light}
            />
          )}
        </div>
      )}
      <ul className="paginator__list">
        {links.map((link, index) => (
          <li key={index} className={cn('paginator__item')}>
            <a
              className={cn('paginator__link', {
                'paginator__link--disabled': link.isDisabled,
              })}
              href={link.isDisabled ? undefined : link.url}
            >
              {link.text}
            </a>
          </li>
        ))}
      </ul>
      {nextLink && (
        <div
          className={cn('paginator__navigation', {
            'paginator__navigation--disabled': nextLink.isDisabled,
          })}
        >
          {nextLink.isDisabled ? (
            <span className="paginator__navigation-placeholder">
              {nextLink.text}
            </span>
          ) : (
            <Link
              text={nextLink.text}
              url={nextLink.url}
              theme={Link.themes.light}
            />
          )}
        </div>
      )}
    </div>
  </div>
);

Paginator.propTypes = {
  nextLink: PropTypes.exact(PaginatorLink.propTypes),
  previousLink: PropTypes.exact(PaginatorLink.propTypes),
  links: PropTypes.arrayOf(PropTypes.exact(PaginatorLink.propTypes)),
};

Paginator.defaultProps = {
  links: [],
};

export default Paginator;
