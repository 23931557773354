import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import Form from 'components/form';
import TextInput from '../form-elements/text-input';
import Link from 'components/link';
import Icon from '../icon';
import cn from 'classnames';

const NewsletterForm = ({
  endpoint,
  submitLabel,
  input,
  termsLink,
  className
}) => (
  <div className={cn('newsletter-form', className)}>
    <Form endpoint={endpoint} showSubmitButton={false}>
      <div className="newsletter-form__body">
        <TextInput
          className="newsletter-form__input"
          type="email"
          autocomplete="email"
          theme={[TextInput.themes.hiddenLabel, TextInput.themes.noValidation]}
          {...input}
        />
        <div className="newsletter-form__button-container">
          <Button
            type="submit"
            className="newsletter-form__button"
            theme={[Button.themes.orange, Button.themes.uppercase]}
          >
            <Icon name="mail" />
            <span>{submitLabel}</span>
          </Button>
        </div>
      </div>
    </Form>

    {termsLink && (
      <div className="newsletter-form__terms-link-container">
        <Link
          className="newsletter-form__terms-link"
          theme={[Link.themes.mini, Link.themes.white]}
          {...termsLink}
        />
      </div>
    )}
  </div>
);

NewsletterForm.propTypes = {
  input: PropTypes.exact(TextInput.propTypes),
  endpoint: PropTypes.string,
  submitLabel: PropTypes.string,
  termsLink: PropTypes.exact(Link.propTypes),
  className: PropTypes.string
};

NewsletterForm.propTypesMeta = {
  className: 'exclude'
};

export default NewsletterForm;
