import get from 'lodash/get';

export default function createFormModel(purchaseForm) {
  const { productSelect, propertySelect } = purchaseForm;

  if (!productSelect || !propertySelect) {
    return;
  }

  const model = {};

  model[productSelect.name] = get(
    productSelect.options.find(option => option.selected),
    'value',
    get(productSelect.options, '[0].value')
  );

  propertySelect.rows.forEach(row => {
    row.inputs.forEach(input => {
      model[input.name] = get(
        input.options.find(option => option.selected),
        'value'
      );
    });
  });

  return model;
}
