import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

const themes = {
  big: 'theme-big',
  white: 'theme-white'
};

const Spinner = ({ className, theme }) => (
  <div className={cn('spinner', theme, className)} />
);

Spinner.propTypes = {
  className: PropTypes.string,
  theme: propTypeTheme(themes)
};

Spinner.propTypesMeta = 'exclude';

Spinner.themes = themes;

export default Spinner;
