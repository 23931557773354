/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import NewsletterForm from 'components/newsletter-form';
import cn from 'classnames';

const themes = {
  small: 'theme-mini',
  compact: 'theme-compact'
};

const NewsletterFormBlock = ({ ...props }) => {
  return (
    <div className={cn('newsletter-form-block', props.theme)}>
      <NewsletterForm {...props} className="newsletter-form--in-block" />
    </div>
  );
};

NewsletterFormBlock.propTypes = Object.assign({}, NewsletterForm.propTypes, {
  theme: PropTypes.oneOf(Object.values(themes))
});

NewsletterFormBlock.themes = themes;

export default NewsletterFormBlock;
