import React from 'react';
import PropTypes from 'prop-types';

import ComponentList from 'components/component-list';
import ContentContainer from 'components/content-container';
import Grid from 'components/grid';
import PageHeader from 'components/page-header';
import ProductList from 'components/product-list';
import RichText from 'components/rich-text';

const ArticlePage = ({ blocks, firstText, header, secondText, sidebar }) => {
  return (
    <React.Fragment>
      {header && <PageHeader {...header} />}

      <ContentContainer>
        <Grid theme={[Grid.themes.leftColumnWider, Grid.themes.editorialPage]}>
          <Grid.Column>
            {firstText && (
              <RichText theme={RichText.themes.centered} {...firstText} />
            )}
            {blocks && (
              <ComponentList
                className="article-page-blocks"
                additionalComponentProps={{
                  ProductList: { theme: ProductList.themes.twoItemsPerRow }
                }}
                {...blocks}
              />
            )}
            {secondText && <RichText {...secondText} />}
          </Grid.Column>

          {sidebar && (
            <Grid.Column>
              <ComponentList {...sidebar} />
            </Grid.Column>
          )}
        </Grid>
      </ContentContainer>
    </React.Fragment>
  );
};

ArticlePage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  firstText: PropTypes.exact(RichText.propTypes),
  header: PropTypes.exact(PageHeader.propTypes),
  secondText: PropTypes.exact(RichText.propTypes),
  sidebar: PropTypes.exact(ComponentList.propTypes)
};

export default ArticlePage;
