import googleMapsLoader from 'js/google-maps-loader';
import getPlaceMatches from './get-place-matches';

const removeDuplicates = arr =>
  arr.reduce(
    (accum, value) => (accum.includes(value) ? accum : accum.concat(value)),
    []
  );

// Returns a filter function that takes a search term, a lat and a long
export default function(APIKey, stores) {
  return new Promise(resolve => {
    googleMapsLoader(APIKey).then(google => {
      const geocoder = new google.maps.Geocoder();

      // Return filter function
      resolve((searchTerm, lat, long) => {
        return new Promise(resolve => {
          if (!searchTerm && !lat && !long) {
            return resolve(stores);
          }

          const nameMatches = stores.filter(
            ({ name }) =>
              searchTerm &&
              name.toLowerCase().includes(searchTerm.toLowerCase())
          );

          const geocoderProps = searchTerm
            ? { address: searchTerm, componentRestrictions: { country: 'NO' } }
            : { location: new google.maps.LatLng(lat, long) };

          geocoder.geocode(geocoderProps, data => {
            const googleMatches = getPlaceMatches(google, stores, data);

            const allMatches = removeDuplicates(
              [].concat(nameMatches, googleMatches)
            );

            resolve(allMatches);
          });
        });
      });
    });
  });
}
