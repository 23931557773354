import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/form-elements/checkbox';
import CheckboxGroup from 'components/form-elements/checkbox-group';
import ColorSelect from 'components/form-elements/color-select';
import GenderSelect from 'components/form-elements/gender-select';
import Link from 'components/link';
import ShapeSelect from 'components/form-elements/shape-select';

const FilterGroup = ({
  colorSelect,
  genderSelect,
  genericFilter,
  link,
  shapeSelect,
  title
}) => {
  let FilterComponent;

  const filter = colorSelect ? (
    <ColorSelect idPrefix="color-filter-" {...colorSelect} />
  ) : genderSelect ? (
    <GenderSelect idPrefix="gender-filter-" {...genderSelect} />
  ) : shapeSelect ? (
    <ShapeSelect idPrefix="category-filter" {...shapeSelect} />
  ) : genericFilter ? (
    <CheckboxGroup
      checkboxTheme={Checkbox.themes.link}
      idPrefix="category-filter"
      {...genericFilter}
    />
  ) : null;

  return !FilterComponent && !filter ? null : (
    <div className="filter-group">
      <h3>{title}</h3>
      <div className="filter-group-content">
        {filter}
        {FilterComponent}
      </div>
      {link && <Link useSpanElement={true} {...link} />}
    </div>
  );
};

FilterGroup.propTypes = {
  colorSelect: PropTypes.exact(ColorSelect.propTypes),
  genderSelect: PropTypes.exact(GenderSelect.propTypes),
  genericFilter: PropTypes.exact(CheckboxGroup.propTypes),
  link: PropTypes.exact(Link.propTypes),
  shapeSelect: PropTypes.exact(ShapeSelect.propTypes),
  title: PropTypes.string
};

FilterGroup.defaultProps = {
  options: []
};

export default FilterGroup;
