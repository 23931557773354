import React from 'react';
import PropTypes from 'prop-types';

import CollapseList from '../../collapse-list';
import OptionGroup from '../option-group';
import ShapeSelectItem from './shape-select-item';

const ShapeSelect = ({
  collapseLabel,
  expandLabel,
  idPrefix,
  maxLength,
  name,
  options
}) => {
  return (
    <div className="shape-select">
      <OptionGroup name={name} options={options}>
        {({ value, onChange }) => (
          <CollapseList {...{ collapseLabel, expandLabel, maxLength }}>
            {options.map(option => (
              <ShapeSelectItem
                checked={value.includes(option.value)}
                idPrefix={idPrefix}
                key={option.value}
                name={name}
                onChange={onChange}
                {...option}
              />
            ))}
          </CollapseList>
        )}
      </OptionGroup>
    </div>
  );
};

ShapeSelect.propTypes = {
  collapseLabel: PropTypes.string,
  expandLabel: PropTypes.string,
  idPrefix: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(ShapeSelectItem.propTypes))
};

ShapeSelect.propTypesMeta = {
  idPrefix: 'exclude'
};

ShapeSelect.defaultProps = {
  options: []
};

export default ShapeSelect;
