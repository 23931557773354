import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import CheckoutContext from 'components/checkout-page/checkout-context';

import CheckoutButton from 'components/checkout-button';
import Checkbox from 'components/form-elements/checkbox';
import CheckboxGroup from 'components/form-elements/checkbox-group';
import Form from 'components/form';
import Link from 'components/link';
import RichText from 'components/rich-text';

const TermsForm = ({
  buttonText,
  marketingConsentsTitle,
  marketingConsents,
  serviceConsents,
  hideServiceConsents,
  endpoint,
  isDesktopKey,
  onResponse,
  terms,
  termsDescriptionTitle,
  termsDescriptionText,
  termsLink,
  validationMessage
}) => {
  const { setIsLoading } = React.useContext(CheckoutContext);
  const [isValid, setValid] = React.useState(false);
  const [isPristine, setPristine] = React.useState(true);

  const onBeforeSubmit = React.useCallback(
    formData => {
      setPristine(false);
      setIsLoading(true);
      return { ...formData, [isDesktopKey]: window.innerWidth > 780 };
    },
    [isDesktopKey]
  );

  return (
    <div className="terms-form">
      <Form
        endpoint={endpoint}
        onBeforeSubmit={onBeforeSubmit}
        onInvalidSubmit={() => setPristine(false)}
        onValidate={setValid}
        onResponse={data => {
          onResponse(data);
          setIsLoading(false);
          setPristine(false);
        }}
        showSubmitButton={false}
      >
        <div className="terms-form-terms">
          {serviceConsents && (
            <div className="terms-form-panel">
              {termsDescriptionTitle && (
                <h3 className="terms-form-description-title">
                  {termsDescriptionTitle}
                </h3>
              )}
              <div
                className={cn('terms-form-service-consents', {
                  'terms-form-service-consents--hidden': hideServiceConsents
                })}
              >
                <CheckboxGroup {...serviceConsents} />
              </div>
              {termsDescriptionText && (
                <RichText
                  theme={RichText.themes.fancyBullets}
                  text={termsDescriptionText}
                />
              )}
            </div>
          )}
          <div className="terms-form-checkboxes">
            {terms && (
              <Checkbox
                attributes={{ 'data-test-checkout-checkbox': '' }}
                className="terms-form-checkbox is-required"
                validations="isRequired"
                {...terms}
              />
            )}
            {termsLink && (
              <div className="terms-form-link-wrapper">
                <Link {...termsLink} />
              </div>
            )}
            <div className="terms-form-marketing">
              {marketingConsentsTitle && (
                <h3 className="terms-form-marketing-title">
                  {marketingConsentsTitle}
                </h3>
              )}
              {marketingConsents && (
                <div className="terms-form-marketing-checkbox">
                  <CheckboxGroup {...marketingConsents} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="terms-form-errors">
          <Collapse isOpen={!isPristine && !isValid}>
            <div className="terms-form-error">
              <p>{validationMessage}</p>
            </div>
          </Collapse>
        </div>

        <CheckoutButton
          attributes={{ 'data-test-checkout-step-3-button': '' }}
          text={buttonText}
          type="submit"
        />
      </Form>
    </div>
  );
};

TermsForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  marketingConsentsTitle: PropTypes.string.isRequired,
  marketingConsents: PropTypes.exact(CheckboxGroup.propTypes),
  serviceConsents: PropTypes.exact(CheckboxGroup.propTypes),
  hideServiceConsents: PropTypes.bool,
  endpoint: PropTypes.string.isRequired,
  isDesktopKey: PropTypes.string.isRequired,
  onResponse: PropTypes.func,
  terms: PropTypes.exact(Checkbox.propTypes),
  termsDescriptionText: PropTypes.string,
  termsDescriptionTitle: PropTypes.string,
  termsLink: PropTypes.exact(Link.propTypes),
  validationMessage: PropTypes.string
};

export default TermsForm;
