import React from 'react';
import PropTypes from 'prop-types';

import PubSub from 'pubsub-js';

import topics from '../../js/topics.json';
import checkApiPropTypes from 'js/check-api-prop-types.js';

import AddToCartButton from '../add-to-cart-button';
import AddToCartResponse from './add-to-cart-response';
import Availability from '../availability';
import Form from '../form';
import Icon from 'components/icon';
import InputTable from '../form-elements/input-table';
import PageSpinner from '../page-spinner';
import Price from '../price';
import Radio from '../form-elements/radio';

class ContactLensForm extends React.Component {
  static propTypes = {
    addToCartEndpoint: PropTypes.string,
    availability: PropTypes.exact(Availability.propTypes),
    availabilityBanner: PropTypes.string,
    onChange: PropTypes.func,
    onRef: PropTypes.func,
    priceSummary: PropTypes.exact({
      price: PropTypes.string,
      priceLabel: PropTypes.string,
      summary: PropTypes.string
    }),
    productSelect: PropTypes.exact(Radio.propTypes),
    productPrices: PropTypes.arrayOf(PropTypes.exact(Price.propTypes)), // NOTE: Is matched to productSelect options by index
    propertySelect: PropTypes.exact(InputTable.propTypes),
    shippingBanner: PropTypes.string,
    submitLabel: PropTypes.string,
    validationEndpoint: PropTypes.string
  };

  static defaultProps = {
    productPrices: [],
    onRef: () => {}
  };

  state = {
    enableValidation: false,
    isLoading: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ isLoading: false });
    }
  }

  onChange = formData => {
    clearTimeout(this.changeTimeout);
    this.changeTimeout = setTimeout(() => {
      this.setState({ isLoading: true }, () => {
        this.props.onChange(formData);
      });
    }, 100);
  };

  onAddToCartResponse = response => {
    checkApiPropTypes(
      response,
      AddToCartResponse.propTypes,
      'AddToCartResponse'
    );
    PubSub.publish(topics.cartUpdate, response);
  };

  render() {
    const {
      priceSummary,
      productSelect,
      propertySelect,
      shippingBanner
    } = this.props;

    return !productSelect || !propertySelect ? null : (
      <Form
        className="contact-lens-form"
        endpoint={this.props.addToCartEndpoint}
        onChange={this.onChange}
        onInvalidSubmit={this.onInvalidSubmit}
        onRef={this.props.onRef}
        onResponse={this.onAddToCartResponse}
        showSubmitButton={false}
        validationEndpoint={this.props.validationEndpoint}
      >
        {({ isLoading }) => (
          <React.Fragment>
            <PageSpinner
              shouldContainInParent={true}
              isLoading={isLoading || this.state.isLoading}
            />

            {productSelect && (
              <div className="contact-lens-product-select">
                <Radio
                  idPrefix="lens-select-"
                  {...productSelect}
                  labels={productSelect.options.map((option, index) => (
                    <span
                      className="contact-lens-form-price"
                      key={option.value}
                    >
                      <span>{option.label}</span>
                      {this.props.productPrices[index] && (
                        <Price {...this.props.productPrices[index]} />
                      )}
                    </span>
                  ))}
                />
              </div>
            )}

            {shippingBanner && (
              <div className="contact-lens-shipping-banner">
                <p>{shippingBanner}</p>
              </div>
            )}

            {propertySelect && (
              <div className="contact-lens-property-select">
                <InputTable {...propertySelect} />
              </div>
            )}
            {this.props.availability && (
              <div className="contact-lens-availability">
                <Availability {...this.props.availability} />
              </div>
            )}
            {this.props.availabilityBanner && (
              <div className="contact-lens-availability-banner">
                <Icon
                  className="contact-lens-availability-banner-icon"
                  name="checkmark"
                />
                {this.props.availabilityBanner}
              </div>
            )}
            {priceSummary && (
              <div className="contact-lens-form-summary">
                <p>{priceSummary.summary}</p>
                <div className="contact-lens-form-summary-price">
                  <p>{priceSummary.priceLabel}</p>
                  <Price originalPrice={priceSummary.price} />
                </div>
              </div>
            )}
            <AddToCartButton text={this.props.submitLabel} />
          </React.Fragment>
        )}
      </Form>
    );
  }
}

export default ContactLensForm;
