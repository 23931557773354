import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';

const icons = {
  kids: 'category-kids',
  man: 'category-man',
  woman: 'category-woman'
};

const CategoryLink = ({ categoryName, text, url, tabIndex = null }) => {
  return (
    <a className="category-link" href={url} tabIndex={tabIndex}>
      <div className="category-link-icon">
        <Icon name={icons[categoryName]} />
        <Icon name={icons[categoryName] + '-glasses'} />
      </div>
      <div className="category-link-text">{text}</div>
    </a>
  );
};

CategoryLink.propTypes = {
  categoryName: PropTypes.oneOf(Object.keys(icons)).isRequired,
  text: PropTypes.string,
  url: PropTypes.string,
  tabIndex: PropTypes.number
};

CategoryLink.propTypesMeta = {
  tabIndex: 'exclude'
};

export default CategoryLink;
