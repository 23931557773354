import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import Icon from '../icon';

const AddToCartButton = ({ text }) => (
  <Button
    attributes={{ 'data-test-add-to-cart-button': '' }}
    className="add-to-cart-button"
    theme={[Button.themes.orange, Button.themes.uppercase]}
    type="submit"
    text={text}
  >
    <Icon className="add-to-cart-button-icon" name="basket" />
  </Button>
);

AddToCartButton.propTypes = {
  text: PropTypes.string
};

AddToCartButton.propTypesMeta = 'exclude';

AddToCartButton.defaultProps = {
  text: 'Legg i handlevogn'
};

export default AddToCartButton;
