import React from 'react';

import Button from 'components/button';
import Icon from 'components/icon';

const OrderAgainButton = props => {
  return (
    <Button
      className="order-again-button"
      theme={[Button.themes.orange, Button.themes.uppercase]}
      type="submit"
      {...props}
    >
      <Icon className="order-again-button-icon" name="basket" />
    </Button>
  );
};

OrderAgainButton.propTypes = Button.propTypes;

export default OrderAgainButton;
