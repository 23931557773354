import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const KlarnaIframe = ({ htmlString, shouldEvalScript }) => {
  const container = useRef();

  useEffect(() => {
    if (shouldEvalScript) {
      const scriptsTags = container.current.getElementsByTagName('script');

      for (var i = 0; i < scriptsTags.length; i++) {
        const parentNode = scriptsTags[i].parentNode;
        const newScriptTag = document.createElement('script');
        newScriptTag.type = 'text/javascript';
        newScriptTag.text = scriptsTags[i].text;
        parentNode.removeChild(scriptsTags[i]);
        parentNode.appendChild(newScriptTag);
      }
    }
  }, []);

  return (
    <div ref={container} className="klarna-iframe">
      <div
        data-test-klarna-iframe
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
    </div>
  );
};

KlarnaIframe.propTypes = {
  htmlString: PropTypes.string,
  shouldEvalScript: PropTypes.bool
};

KlarnaIframe.propTypesMeta = 'exclude';

export default KlarnaIframe;
