import React from 'react';
import PropTypes from 'prop-types';

import CheckoutContext from 'components/checkout-page/checkout-context';

import Button from 'components/button';
import Form from 'components/form';
import TextInput from 'components/form-elements/text-input';

const CheckoutRegisterForm = ({
  bullets,
  endpoint,
  onRegistered,
  submitLabel,
  text,
  title,
  userId
}) => {
  const { setIsLoading } = React.useContext(CheckoutContext);

  return (
    <Form
      className="checkout-register-form"
      endpoint={endpoint}
      onBeforeSubmit={() => setIsLoading(true)}
      onResponse={({ isRegistered }) => {
        isRegistered && onRegistered();
        setIsLoading(false);
      }}
      showSubmitButton={false}
    >
      {title && <h3>{title}</h3>}
      {bullets.length > 0 && (
        <ul className="checkout-register-form-bullets">
          {bullets.map(bullet => (
            <li key={bullet}>{bullet}</li>
          ))}
        </ul>
      )}
      {text && <p>{text}</p>}
      <TextInput detectAutofill={true} validations="isRequired" {...userId} />

      <div className="checkout-register-form-footer">
        <Button
          className="checkout-register-form-submit"
          type="submit"
          text={submitLabel}
        />
      </div>
    </Form>
  );
};

CheckoutRegisterForm.propTypes = {
  bullets: PropTypes.arrayOf(PropTypes.string),
  endpoint: PropTypes.string,
  onRegistered: PropTypes.func,
  submitLabel: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  userId: PropTypes.exact(TextInput.propTypes)
};

CheckoutRegisterForm.defaultProps = {
  bullets: []
};

export default CheckoutRegisterForm;
