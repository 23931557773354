import React from 'react';
import PropTypes from 'prop-types';

import ContentContainer from '../content-container';
import ContactLensHeader from './contact-lens-header';
import Grid from '../grid';
import ProductList from '../product-list';
import ProductText from 'components/product-text';

const ContactLensPage = ({
  header,
  productInformation,
  productMetadata,
  relatedProducts,
  productTextHtml
}) => (
  <div className="contact-lens-page">
    <ContentContainer>
      <ContactLensHeader {...header} />
      {productTextHtml && <ProductText textHtml={productTextHtml} />}
      <Grid
        className="contact-lens-content"
        theme={Grid.themes.leftColumnWider}
      >
        <Grid.Column>
          {productInformation && (
            <div className="contact-lens-information">
              <h2>{productInformation.title}</h2>
              {productInformation.content.map(item => [
                <h3 key={item.title}>{item.title}</h3>,
                <p key={item.text}>{item.text}</p>
              ])}
            </div>
          )}
          {productMetadata && (
            <div className="contact-lens-metadata">
              <h2>{productMetadata.title}</h2>
              <table>
                <tbody>
                  {productMetadata.content.map(item => (
                    <tr key={item.title}>
                      <td>{`${item.title}:`}</td>
                      <td>{item.text}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Grid.Column>
        <Grid.Column />
      </Grid>

      {relatedProducts && <ProductList {...relatedProducts} />}
    </ContentContainer>
  </div>
);

ContactLensPage.propTypes = {
  header: PropTypes.exact(ContactLensHeader.propTypes),
  productTextHtml: PropTypes.string,
  productType: PropTypes.string,
  productInformation: PropTypes.exact({
    title: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.exact({
        title: PropTypes.string,
        text: PropTypes.string
      })
    )
  }),
  productMetadata: PropTypes.exact({
    title: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.exact({
        title: PropTypes.string,
        text: PropTypes.string
      })
    )
  }),
  relatedProducts: PropTypes.exact(ProductList.propTypes)
};

export default ContactLensPage;
