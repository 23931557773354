import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import get from 'lodash/get';

import Collapse from 'react-tiny-collapse';

import propTypeTheme from 'utils/prop-type-theme';

import FormsyWrapper from 'components/formsy-wrapper';
import HiddenInput from 'components/form-elements/hidden-input';
import Icon from 'components/icon';

const themes = {
  gray: 'theme-gray'
};

const getLabel = (options, value, placeholder) => {
  const fallback =
    get(options.find(o => o.selected), 'label', placeholder) ||
    get(options, '[0].label');

  return get(options.find(o => o.value === value), 'label', fallback);
};

class Select extends React.Component {
  static propTypes = {
    ariaLabelledBy: PropTypes.string,
    defaultScrollToValue: PropTypes.string,
    enableValidation: PropTypes.bool,
    idPrefix: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        selected: PropTypes.bool
      })
    ),
    placeholder: PropTypes.string,
    shouldShowCheckmark: PropTypes.bool,
    theme: propTypeTheme(themes),
    validations: PropTypes.string,
    validationErrors: PropTypes.object
  };

  static propTypesMeta = {
    ariaLabelledBy: 'exclude',
    enableValidation: 'exclude',
    idPrefix: 'exclude',
    shouldShowCheckmark: 'exclude',
    theme: 'exclude',
    validations: 'exclude',
    validationErrors: 'exclude'
  };

  selectedOption = null;
  scrollContainer = null;

  static defaultProps = {
    enableValidation: true,
    options: [],
    shouldShowCheckmark: true
  };

  state = {
    dropdownIsVisible: false,
    isMounted: false
  };

  getInitialValue = () => {
    const selectedOption = this.props.options.find(option => option.selected);
    const firstValue = get(this.props.options[0], 'value', {});
    const initialValue =
      get(selectedOption, 'value') ||
      (this.props.placeholder ? '' : firstValue);
    return initialValue;
  };

  componentDidMount() {
    this.setState({ isMounted: true });

    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (
      this.fakeSelect &&
      e.target !== this.fakeSelect &&
      !this.fakeSelect.contains(e.target)
    ) {
      this.setState({ dropdownIsVisible: false });
    }
  };

  scrollToSelectedOption = () => {
    if (!this.selectedOption || !this.scrollContainer) return;

    const elementPosition = this.selectedOption.offsetTop;
    const elementHeight = this.selectedOption.offsetHeight;
    const containerHeight = this.scrollContainer.offsetHeight;
    this.scrollContainer.scrollTop =
      elementPosition + elementHeight - containerHeight / 2;
  };

  hideDropdown = () => {
    this.setState({ dropdownIsVisible: false });
  };

  toggleDropdown = () => {
    this.setState(
      state => ({ dropdownIsVisible: !state.dropdownIsVisible }),
      () => {
        if (this.state.dropdownIsVisible) {
          this.scrollToSelectedOption();
        }
      }
    );
  };

  render() {
    return (
      <FormsyWrapper
        initialValue={this.getInitialValue()}
        name={this.props.name}
        validationErrors={this.props.validationErrors}
        validations={this.props.validations}
      >
        {({ getValue, getErrorMessage, isPristine, isValid, setValue }) => {
          const ariaAttributes = this.props.ariaLabelledBy
            ? { 'aria-labelledby': this.props.ariaLabelledBy }
            : {};

          const label = getLabel(
            this.props.options,
            getValue(),
            this.props.placeholder
          );

          const showError =
            this.props.enableValidation && !isPristine() && !isValid();

          const id = this.props.label
            ? (this.props.idPrefix || '') + '-' + this.props.name
            : null;

          if (this.props.options.length === 1) {
            const [firstOption] = this.props.options;
            return (
              <React.Fragment>
                <div className="select-no-options">{firstOption.label}</div>
                {this.props.name && (
                  <HiddenInput name={this.props.name} {...firstOption} />
                )}
              </React.Fragment>
            );
          }

          return (
            <div
              className={cn('select', this.props.theme, {
                'has-error': showError,
                'is-active': this.state.dropdownIsVisible,
                'is-mounted': this.state.isMounted
              })}
            >
              <select
                name={this.props.name}
                id={id}
                onChange={e => {
                  setValue(e.target.value);
                  this.hideDropdown();
                }}
                value={getValue() || ''}
                {...ariaAttributes}
              >
                {this.props.placeholder && (
                  <option value="">{this.props.placeholder}</option>
                )}
                {this.props.options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              <div className="select-fake">
                <div
                  data-test-select-button
                  className="select-element"
                  onClick={this.toggleDropdown}
                  ref={div => (this.fakeSelect = div)}
                >
                  {this.props.label && (
                    <label htmlFor={id}>{this.props.label}</label>
                  )}

                  {label}
                  {(!getValue() || !this.props.shouldShowCheckmark) && (
                    <Icon className="arrow" name="small-arrow-down" />
                  )}
                  {this.props.shouldShowCheckmark && !!getValue() && (
                    <div className="select-checkmark">
                      <Icon name="checkmark" />
                    </div>
                  )}
                </div>
                <Collapse
                  className="select-dropdown"
                  isOpen={this.state.dropdownIsVisible}
                >
                  <ul ref={el => (this.scrollContainer = el)}>
                    {this.props.options.map(option => {
                      const isSelected = option.value === getValue();
                      const isScrollToElement =
                        isSelected ||
                        option.value === this.props.defaultScrollToValue;

                      return (
                        <li
                          className={cn({ 'is-active': isSelected })}
                          key={option.value}
                          onClick={e => {
                            e.stopPropagation();
                            setValue(option.value);
                            this.hideDropdown();
                          }}
                          ref={el => {
                            if (isScrollToElement) this.selectedOption = el;
                          }}
                          {...(option.value
                            ? { 'data-test-select-option': '' }
                            : {})}
                        >
                          <span>{option.label}</span>
                        </li>
                      );
                    })}
                  </ul>
                </Collapse>
              </div>

              <Collapse isOpen={showError}>
                <div className="select-error">{getErrorMessage()}</div>
              </Collapse>
            </div>
          );
        }}
      </FormsyWrapper>
    );
  }
}

Select.themes = themes;

export default Select;
