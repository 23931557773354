import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import clamp from '@creuna/utils/clamp';
import { useSpringStyle } from 'use-spring-effect';

import propTypeTheme from 'utils/prop-type-theme';
import useNonPassiveEvent from 'hooks/use-non-passive-event';

import useInterval from '../../hooks/use-interval';
import CarouselContextProvider from '../../contexts/carousel-context-provider';
import AssistiveDrawer from 'components/assistive-drawer';

import Icon from '../icon';

const themes = {
  block: 'theme-block',
  green: 'theme-green',
  small: 'theme-small',
  cta: 'theme-cta'
};

const getNewState = (newIndex, children, numberOfSlidesToShow) => {
  const numberOfItems = React.Children.count(children);
  const lastIndex = numberOfItems - numberOfSlidesToShow;
  const newIndexClamped = clamp(newIndex, 0, lastIndex);

  return {
    currentIndex: newIndexClamped,
    currentIndexRounded: Math.round(newIndexClamped),
    hasNextItem: newIndexClamped < lastIndex,
    hasPreviousItem: newIndexClamped > 0
  };
};

const Carousel = ({
  children,
  nextItemText,
  pauseItemText,
  playItemText,
  stopItemText,
  autoPlayDelayInSeconds,
  numberOfSlidesToShow,
  previousItemText,
  dotButtonText,
  shouldShowDots,
  carouselLabelText,
  isAutoPlayEnabled,
  numberOfSlidesToJump,
  startIndex,
  theme,
  thumbnails,
  shouldShowNavText,
  shouldLoop,
  title,
  carouselAnnouncementText,
  carouselItemLabelText,
  hasQuickAnimations
}) => {
  const slidesCount = React.useMemo(() => React.Children.count(children), [
    children
  ]);

  const hasThumbnails = React.Children.count(thumbnails) > 0;

  const announcementText = carouselAnnouncementText.replace(
    /\$\{1\}/g,
    slidesCount
  );

  const slideRefs = useRef([]);

  // const [shouldAutoPlay, setShouldAutoPlay] = React.useState(isAutoPlayEnabled);

  const [state, setState] = React.useState({ currentIndex: startIndex });
  const [wrapper, setWrapper] = React.useState();
  const [navVisible, setNavVisible] = React.useState(slidesCount > 0);

  const animationParams = hasQuickAnimations
    ? { stiffness: 2000, damping: 50, precision: 1000 }
    : { stiffness: 130, damping: 20, precision: 1000 };

  const [animationRef, transitionTo] = useSpringStyle(
    startIndex,
    x => ({
      transform: `translateX(${-((100 / slidesCount) * x) /
        numberOfSlidesToShow}%)`
    }),
    animationParams,
    [slidesCount, numberOfSlidesToShow]
  );

  const update = index => {
    const newState = getNewState(index, children, numberOfSlidesToShow);
    setState(newState);
    transitionTo(newState.currentIndex);
  };

  const goToItem = newIndex => update(Math.round(newIndex));

  const goToNextItem = () => {
    if (shouldLoop && state.currentIndex === slidesCount - 1) {
      goToItem(0);
      return;
    }

    goToItem(state.currentIndex + numberOfSlidesToJump);
  };

  const goToPreviousItem = () => {
    if (shouldLoop && state.currentIndex === startIndex) {
      goToItem(slidesCount);
      return;
    }

    goToItem(state.currentIndex - numberOfSlidesToJump);
  };

  //TODO: conditional if isAutoPlayEnabled, else new component?
  const {
    reset: resetTimer,
    isPaused: isTimerPaused,
    isStopped: isTimerStopped,
    stop: stopTimer,
    start: startTimer,
    pause: pauseTimer,
    unpause: unpauseTimer
  } = useInterval(
    () => {
      goToNextItem();
    },
    isAutoPlayEnabled ? autoPlayDelayInSeconds : undefined
  );

  // On children update
  React.useEffect(() => {
    update(startIndex);
    setNavVisible(slidesCount);
  }, [slidesCount]);

  // Non-render state
  const hasMultiTouch = React.useRef();
  const isZoomed = React.useRef();
  const initialScreenWidth = React.useRef();
  const indexOnTouchStart = React.useRef();
  const previousX = React.useRef();
  const touchStartX = React.useRef();

  React.useEffect(() => {
    initialScreenWidth.current = window.innerWidth;
  }, []);

  React.useEffect(() => {
    goToItem(startIndex);
  }, [startIndex]);

  const onTouchEnd = () => {
    goToItem(state.currentIndex);
    touchStartX.current = undefined;
    previousX.current = 0;
  };

  const onTouchStart = touch => {
    if (!touch) return;

    touchStartX.current = touch.clientX;
    indexOnTouchStart.current = state.currentIndex;
    previousX.current = touch.clientX;
  };

  const onTouchMove = (e, touch) => {
    isZoomed.current =
      Math.abs(window.innerWidth - initialScreenWidth.current) > 10;

    if (isZoomed.current || hasMultiTouch.current || !touch) {
      return;
    }

    const travel = touchStartX.current - touch.clientX;

    if (Math.abs(travel) > 5) {
      e.preventDefault(); // Disable scrolling while interacting with carousel
    }

    // NOTE: Go to next slide on fast swipe
    if (Math.abs(touch.clientX - previousX.current) > 20) {
      update(indexOnTouchStart.current + (travel > 0 ? 1 : -1));
      return;
    }

    const travelRelative = travel / wrapper.offsetWidth;

    previousX.current = touch.clientX;

    update(indexOnTouchStart.current + travelRelative);
  };

  const navigateByDot = index => {
    resetTimer();
    goToItem(index);
    // slideRefs.current[index].focus();
  };

  const onWrapperTouchMove = e => {
    hasMultiTouch.current = e.touches.length > 1;
    onTouchMove(e, e.touches[0]);
  };

  const toggleStopAutoPlay = () => {
    isTimerStopped ? startTimer() : stopTimer();
  };

  // NOTE: Using non-passive event because passive event listeners can't call event.preventDefault
  useNonPassiveEvent('touchmove', wrapper, onWrapperTouchMove);

  const itemWidth = 100 / slidesCount;
  const currentSlide = state.currentIndexRounded;
  const maxSteps = Math.ceil(slidesCount / numberOfSlidesToShow);
  const steps = new Array(maxSteps).fill(0).map((_, i) => i);

  const memoizedAnnoucementText = React.useMemo(() => {
    return announcementText.replace(/\$\{0\}/g, currentSlide + 1);
  }, [currentSlide]);

  return slidesCount === 0 ? null : (
    <CarouselContextProvider currentSlideIndex={currentSlide}>
      <section
        role="region"
        aria-label={carouselLabelText}
        aria-roledescription="karusell"
        className={cn('carousel', theme, {
          'has-thumbnails': hasThumbnails,
          'shows-multiple-slides': numberOfSlidesToShow > 1,
          'default-state': !state.hasPreviousItem
        })}
      >
        {isAutoPlayEnabled && (
          <AssistiveDrawer description="karusellfunksjoner">
            <button
              type="button"
              className="carousel-assistive-button"
              onClick={isTimerStopped ? startTimer : stopTimer}
            >
              {isTimerStopped ? playItemText : stopItemText}
            </button>
          </AssistiveDrawer>
        )}
        {carouselAnnouncementText && (
          <div
            className="carousel-live-region"
            aria-live="polite"
            aria-atomic={true}
          >
            {memoizedAnnoucementText}
          </div>
        )}
        <div className="carousel-content">
          <div
            className="carousel-content-wrapper"
            onMouseEnter={pauseTimer}
            onMouseLeave={unpauseTimer}
          >
            <div
              className="carousel-items-wrapper"
              ref={setWrapper}
              onTouchStart={e => onTouchStart(e.touches[0])}
              onTouchEnd={onTouchEnd}
            >
              <ul
                aria-live={isAutoPlayEnabled ? 'off' : 'polite'}
                ref={animationRef}
                className="carousel-items"
                style={{
                  width: `${slidesCount * 100}%`
                }}
              >
                {React.Children.map(children, (child, index) => {
                  const isCurrent = currentSlide === index;

                  return (
                    <li
                      role="group"
                      aria-label={`${index} av ${slidesCount}`}
                      aria-hidden={!isCurrent}
                      aria-roledescription={carouselItemLabelText}
                      ref={ref => (slideRefs.current[index] = ref)}
                      tabIndex="-1"
                      onFocus={e => {
                        if (!e.currentTarget.contains(e.relatedTarget))
                          pauseTimer();
                      }}
                      onBlur={e => {
                        if (!e.currentTarget.contains(e.relatedTarget))
                          unpauseTimer();
                      }}
                      className={cn({ 'is-current': isCurrent })}
                      style={{ width: `${itemWidth / numberOfSlidesToShow}%` }}
                    >
                      {child}
                    </li>
                  );
                })}
              </ul>
            </div>
            {navVisible && (
              <React.Fragment>
                {title && <span className="carousel-title">{title}</span>}
                <div className="carousel-nav">
                  <button
                    aria-label={previousItemText}
                    className="carousel-prev"
                    type="button"
                    disabled={!shouldLoop && !state.hasPreviousItem}
                    onClick={() => {
                      resetTimer();
                      goToPreviousItem();
                    }}
                  >
                    {shouldShowNavText && (
                      <span className="carousel-prev-text">
                        {previousItemText}
                      </span>
                    )}
                    <Icon name="big-arrow-right" />
                  </button>
                  <button
                    aria-label={nextItemText}
                    className="carousel-next"
                    disabled={!shouldLoop && !state.hasNextItem}
                    onClick={() => {
                      resetTimer();
                      goToNextItem();
                    }}
                    type="button"
                  >
                    {shouldShowNavText && (
                      <span className="carousel-next-text">{nextItemText}</span>
                    )}
                    <Icon name="big-arrow-right" />
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>

          {shouldShowDots && (
            <div className="carousel-dots">
              {isAutoPlayEnabled && (
                <>
                  <button
                    aria-label={pauseItemText}
                    onClick={toggleStopAutoPlay}
                    type="button"
                    className={cn('carousel-status', {
                      'carousel-status--paused': isTimerPaused,
                      'carousel-status--stopped': isTimerStopped
                    })}
                  >
                    {shouldShowNavText && (
                      <span className="carousel-status-text">
                        {pauseItemText}
                      </span>
                    )}
                    <span aria-hidden={true} className="carousel-status-icons">
                      <span className="carousel-status-icon carousel-play-icon">
                        <Icon name="pause" className="carousel-icon" />
                      </span>
                      <span className="carousel-status-icon carousel-pause-icon">
                        <div className="makeshift-pause-icon" />
                      </span>
                      <span className="carousel-status-icon carousel-stop-icon">
                        <div className="makeshift-pause-icon" />
                      </span>
                    </span>
                  </button>
                </>
              )}
              {steps.map(index => {
                const isActive = index === currentSlide;
                const slideIndex = index + 1;

                return (
                  <div key={index}>
                    <button
                      data-carousel-element={slideIndex}
                      onClick={() => navigateByDot(index)}
                      disabled={isActive}
                      className={cn('carousel-dot', {
                        'is-active': isActive
                      })}
                    >
                      <span className="carousel-dot-text">
                        {`${dotButtonText} ${slideIndex} ${
                          isActive ? '(aktiv)' : ''
                        }`}
                      </span>
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {hasThumbnails && (
            <ul aria-hidden={true} className="carousel-thumbnails">
              {React.Children.map(thumbnails, (child, index) => (
                <li>
                  <button
                    className={cn('carousel-thumbnail', {
                      'is-active': index === currentSlide
                    })}
                    type="button"
                    onClick={() => goToItem(index)}
                  >
                    {child}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </CarouselContextProvider>
  );
};

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  nextItemText: PropTypes.string,
  pauseItemText: PropTypes.string,
  playItemText: PropTypes.string,
  stopItemText: PropTypes.string,
  numberOfSlidesToShow: PropTypes.number,
  previousItemText: PropTypes.string,
  dotButtonText: PropTypes.string,
  shouldShowDots: PropTypes.bool,
  shouldLoop: PropTypes.bool,
  isAutoPlayEnabled: PropTypes.bool,
  autoPlayDelayInSeconds: PropTypes.number,
  startIndex: PropTypes.number,
  numberOfSlidesToJump: PropTypes.number,
  theme: propTypeTheme(themes),
  thumbnails: PropTypes.arrayOf(PropTypes.node),
  shouldShowNavText: PropTypes.bool,
  hasQuickAnimations: PropTypes.bool,
  title: PropTypes.string,
  carouselAnnouncementText: PropTypes.string,
  carouselLabelText: PropTypes.string,
  carouselItemLabelText: PropTypes.string
};

Carousel.propTypesMeta = 'exclude';

Carousel.defaultProps = {
  nextItemText: 'Neste bilde',
  carouselLabelText: 'karusell',
  numberOfSlidesToShow: 1,
  carouselItemLabelText: 'Karusellelement',
  dotButtonText: 'Karusellelement',
  pauseItemText: 'Pause karusell',
  stopItemText: 'Stopp karusell',
  playItemText: 'Spill av karusell',
  carouselAnnouncementText: 'Side ${0} av ${1}',
  previousItemText: 'Forrige bilde',
  shouldShowDots: true,
  shouldLoop: true,
  shouldAutoRotate: false,
  hasQuickAnimations: false,
  autoPlayDelayInSeconds: 0,
  startIndex: 0,
  shouldShowNavText: false,
  title: '',
  numberOfSlidesToJump: 1
};

Carousel.themes = themes;

export default Carousel;
