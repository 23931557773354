import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Icon from 'components/icon';

const CheckoutButton = ({ attributes, disabled, onClick, text, type }) => {
  return (
    <Button
      attributes={attributes}
      className="checkout-button"
      disabled={disabled}
      onClick={onClick}
      text={text}
      theme={[Button.themes.orange, Button.themes.uppercase]}
      type={type}
    >
      <Icon className="checkout-button-icon" name="small-arrow-right" />
    </Button>
  );
};

CheckoutButton.propTypes = {
  attributes: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.string
};

CheckoutButton.propTypesMeta = 'exclude';

export default CheckoutButton;
