import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';

class MouseoverLink extends React.Component {
  static propTypes = {
    ...Link.propTypes,
    delay: PropTypes.number,
    onMousehold: PropTypes.func.isRequired
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    delay: 500
  };

  link = React.createRef();
  timeout = null;

  onMouseIn = () => {
    this.timeout = setTimeout(() => {
      if (!this) return;

      this.props.onMousehold();
    }, this.props.delay);
  };

  onMouseOut = () => {
    clearTimeout(this.timeout);
  };

  componentDidMount() {
    if (!this.link.current) return;

    this.link.current.addEventListener('mouseenter', this.onMouseIn);
    this.link.current.addEventListener('mouseleave', this.onMouseOut);
  }

  componentWillUnmount() {
    if (!this.link.current) return;

    this.link.current.removeEventListener('mouseenter', this.onMouseIn);
    this.link.current.removeEventListener('mouseleave', this.onMouseOut);
  }

  render() {
    // Eslint rule is ignored so that 'delay' and 'onMouseover' can be omitted from Link props
    // eslint-disable-next-line no-unused-vars
    const { delay, onMousehold, ...props } = this.props;

    return <Link onRef={this.link} {...props} />;
  }
}

export default MouseoverLink;
