import React from 'react';
import PropTypes from 'prop-types';

import ContentContainer from '../content-container';
import FindStore from '../find-store';
import StoreMap from '../store-map';
import PageSpinner from '../page-spinner';
import StoresList from './stores-list';

const FindStorePage = ({
  findStore,
  title,
  ingress,
  list,
  mapMarker,
  noResultsText
}) => {
  return (
    <ContentContainer className="find-store-page">
      <h1>{title}</h1>
      <p className="find-store-page-ingress">{ingress}</p>

      <FindStore
        className="find-store-page-search"
        shouldUseLocation={true}
        {...findStore}
      >
        {({ apiKey, isLoading, stores }) => (
          <React.Fragment>
            <PageSpinner isLoading={isLoading} />

            {stores.length > 0 ? (
              <React.Fragment>
                <div className="find-store-page-stores">
                  <StoresList stores={stores} {...list} />
                </div>

                <div className="find-store-page-map">
                  <StoreMap
                    googleMapsAPIKey={apiKey}
                    mapMarker={mapMarker}
                    stores={stores}
                  />
                </div>
              </React.Fragment>
            ) : (
              <div className="find-store-page-no-results">{noResultsText}</div>
            )}
          </React.Fragment>
        )}
      </FindStore>
    </ContentContainer>
  );
};

FindStorePage.propTypes = {
  findStore: PropTypes.exact(FindStore.propTypes),
  title: PropTypes.string,
  ingress: PropTypes.string,
  list: PropTypes.exact(StoresList.propTypes),
  mapMarker: PropTypes.string,
  noResultsText: PropTypes.string
};

FindStorePage.defaultProps = {
  noResultsText: 'Vi fant dessverre ingen butikker.'
};

export default FindStorePage;
