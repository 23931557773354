import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import Button from '../button';
import Icon from '../icon';
import OnPageEditing from '../on-page-editing';
import RichText from '../rich-text';

class Accordion extends React.Component {
  static propTypes = {
    id: PropTypes.string, // NOTE: Used as key in AccordionList
    OPE: PropTypes.exact({
      text: PropTypes.string,
      title: PropTypes.string
    }),
    showBorder: PropTypes.bool,
    text: PropTypes.exact(RichText.propTypes),
    title: PropTypes.string
  };

  static propTypesMeta = {
    showBorder: 'exclude'
  };

  static defaultProps = {
    OPE: {},
    showBorder: true
  };

  state = {
    id: null,
    isExpanded: false
  };

  toggle = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  };

  componentDidMount() {
    // Setting the id inside of a requestAnimationFrame callback seems to ensure that no two Accordions get the same id.
    requestAnimationFrame(() => {
      this.setState({ id: new Date().getTime() });
    });
  }

  render() {
    return (
      <div
        className={cn('accordion', { 'has-border': this.props.showBorder })}
        key={this.props.id}
      >
        <Button
          attributes={{
            'aria-controls': this.state.id,
            'aria-expanded': this.state.isExpanded
          }}
          className={cn({ 'is-active': this.state.isExpanded })}
          onClick={this.toggle}
          theme={Button.themes.plain}
          type="button"
        >
          <div className="accordion-header">
            <OnPageEditing name={this.props.OPE.title}>
              <span>{this.props.title}</span>
            </OnPageEditing>
            <div className="accordion-icon">
              <Icon name="small-arrow-down" />
            </div>
          </div>
        </Button>

        <Collapse
          componentProps={{ id: this.state.id }}
          isOpen={this.state.isExpanded}
        >
          <OnPageEditing name={this.props.OPE.text}>
            <RichText
              className="accordion-content"
              theme={RichText.themes.centered}
              {...this.props.text}
            />
          </OnPageEditing>
        </Collapse>
      </div>
    );
  }
}

export default Accordion;
