import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import RichText from '../rich-text';

const Metadata = ({ title, descriptionList, text, button }) => (
  <div className="metadata">
    <h2>{title}</h2>
    <dl className="metadata-description-list">
      {descriptionList.map(description => (
        <React.Fragment key={description.tag}>
          <dt>{description.tag}</dt>
          <dd>{description.description}</dd>
        </React.Fragment>
      ))}
    </dl>
    <RichText theme={RichText.themes.centered} text={text} />
    {button && (
      <div className="metadata-button-wrapper">
        <Button className="metadata-button" {...button} />
      </div>
    )}
  </div>
);

Metadata.defaultProps = {
  descriptionList: []
};

Metadata.propTypes = {
  title: PropTypes.string,
  descriptionList: PropTypes.arrayOf(
    PropTypes.exact({
      tag: PropTypes.string,
      description: PropTypes.string
    })
  ),
  text: PropTypes.string,
  button: PropTypes.exact(Button.propTypes)
};

export default Metadata;
