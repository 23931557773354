import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import FlipMotion from 'react-flip-motion';

import ProductCard from '../product-card';

const themes = {
  narrow: 'theme-narrow',
  noMargin: 'theme-no-margin',
  productBackgrounds: 'theme-product-backgrounds',
  vertical: 'theme-vertical',
  twoItemsPerRow: 'theme-two-items-per-row'
};

const ProductList = ({
  products,
  productTheme,
  shouldAnimate,
  theme,
  title,
  ctaType = 'product-list'
}) => {
  const ListWrapper = shouldAnimate ? FlipMotion : 'ul';
  const listProps = shouldAnimate
    ? { component: 'ul', childComponent: 'li' }
    : {};
  const ChildWrapper = shouldAnimate ? React.Fragment : 'li';

  return (
    <div className={cn('product-list', theme)}>
      {title && <h2>{title}</h2>}
      <ListWrapper {...listProps}>
        {products.map(product => (
          <ChildWrapper key={product.url}>
            <ProductCard theme={productTheme} {...product} ctaType={ctaType} />
          </ChildWrapper>
        ))}
      </ListWrapper>
    </div>
  );
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.exact(ProductCard.propTypes)),
  productTheme: PropTypes.any,
  shouldAnimate: PropTypes.bool,
  theme: propTypeTheme(themes),
  title: PropTypes.string,
  ctaType: PropTypes.string
};

ProductList.propTypesMeta = {
  productTheme: 'exclude',
  shouldAnimate: 'exclude',
  theme: 'exclude',
  ctaType: 'exclude'
};

ProductList.defaultProps = {
  products: []
};

ProductList.themes = themes;

export default ProductList;
