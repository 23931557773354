import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import CtaBlock from '../cta-block';
import FluidImage from '../fluid-image';
import OnPageEditing from '../on-page-editing';
import ProductCard from '../product-card';
import ProductList from '../product-list';
import Video from '../video';

const ctaColors = {
  green: 'green',
  white: 'white'
};

function getCtaTheme(ctaColor) {
  if (ctaColor === ctaColors.green) {
    return CtaBlock.themes.green;
  } else if (ctaColor === ctaColors.white) {
    return CtaBlock.themes.white;
  }
}

const themes = {
  contained: 'theme-contained'
};

const CampaignBanner = ({
  ctaBlock,
  ctaColor,
  image,
  OPE,
  isFocusable,
  products,
  video,
  theme,
  ctaType = 'campaign-banner'
}) => (
  <div className={cn('campaign-banner', theme)}>
    <div className="campaign-banner-header">
      <div className="campaign-banner-image">
        {video ? (
          <Video theme={Video.themes.fill} {...video} />
        ) : (
          <OnPageEditing name={OPE.image}>
            <FluidImage initialSize={800} {...image} />
          </OnPageEditing>
        )}
      </div>

      <div className="campaign-banner-cta">
        <OnPageEditing name={OPE.ctaBlock}>
          {ctaBlock && (
            <CtaBlock
              showImage={false}
              isFocusable={isFocusable}
              theme={[getCtaTheme(ctaColor), CtaBlock.themes.compact]}
              {...ctaBlock}
              ctaType={ctaType}
            />
          )}
        </OnPageEditing>
      </div>
    </div>

    <OnPageEditing name={OPE.products}>
      {products && (
        <div className="campaign-banner-products">
          <ProductList
            productTheme={[ProductCard.themes.backgroundColor]}
            theme={[
              ProductList.themes.noMargin,
              ProductList.themes.productBackgrounds
            ]}
            {...products}
            ctaType="campaign-banner-products"
          />
        </div>
      )}
    </OnPageEditing>
  </div>
);

CampaignBanner.propTypes = {
  ctaBlock: PropTypes.exact(CtaBlock.propTypes),
  ctaColor: PropTypes.oneOf(Object.values(ctaColors)),
  image: PropTypes.exact(FluidImage.propTypes),
  OPE: PropTypes.exact({
    ctaBlock: PropTypes.string,
    image: PropTypes.string,
    products: PropTypes.string
  }),
  products: PropTypes.exact(ProductList.propTypes),
  video: PropTypes.exact(Video.propTypes),
  isFocusable: PropTypes.bool,
  theme: propTypeTheme(themes),
  ctaType: PropTypes.string
};

CampaignBanner.propTypesMeta = {
  theme: 'exclude',
  isFocusable: 'exclude',
  ctaType: 'exclude'
};

CampaignBanner.defaultProps = {
  ctaColor: ctaColors.white,
  OPE: {}
};

CampaignBanner.themes = themes;

export default CampaignBanner;
