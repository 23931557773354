import React from 'react';
import PropTypes from 'prop-types';

const ErrorSvg = ({ errorCode }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="352px"
    height="352px"
    viewBox="0 0 352 352"
    style={{
      enableBackground: 'new 0 0 352 352',
      maxWidth: '100%'
    }}
    xmlSpace="preserve"
  >
    <style type="text/css">{`
	.st0{fill:#0A6431;}
	.st1{fill:none;stroke:#0A6431;stroke-width:10;stroke-miterlimit:10;}
	.st2{fill:#FFFFFF;}
	.st3{font-family:Verdana,sans-serif;font-weight:bold;}
	.st4{font-size:32;}
	.st5{fill:none;stroke:#0A6431;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;}
`}</style>
    <path
      className="st0"
      d="M238.8,158.2c-21.5,0-39.7,13.7-46.5,32.9c-4.6-3.1-9.8-4.8-14.7-4.8c-5,0-10.1,1.6-14.6,4.7
	c-6.8-19.1-25-32.8-46.5-32.8c-27.3,0-49.4,22.1-49.4,49.4c0,27.3,22.1,49.4,49.4,49.4c21.9,0,40.5-14.3,47-34.1l0,0.8
	c5-11.9,3.4-25.1,14.1-25.1c10.5,0,10.1,12.3,13.8,22.8c5.9,20.6,24.9,35.7,47.4,35.7c27.3,0,49.4-22.1,49.4-49.4
	C288.2,180.4,266.1,158.2,238.8,158.2z"
    />
    <path
      className="st1"
      d="M328.1,167.4c11.3,6,18.9,18.1,18.9,31.9c0,19.9-15.9,36-35.5,36c-1.6,0-3.1-0.1-4.6-0.3
	C291.4,293.7,247,347,176,347c-70.3,0-115.4-53.3-130.9-112c-1.5,0.2-3.1,0.3-4.6,0.3c-19.6,0-35.5-16.1-35.5-36
	c0-19.9,15.9-36,35.5-36c19.2,4.3,105.9,10.8,168-32c17.9-12.4,12.5-36.1,12.5-36.1c37.8-3,35.7,74.8,108.9,72.2L329,158
	c0-73.3-44.3-122.7-98.5-142.8C212.4,8.5,193.2,5,174.2,5C85.4,5,23,64.2,23,156.9V167"
    />
    <text transform="matrix(1 0 0 1 81.5575 221.1479)" className="st2 st3 st4">
      {errorCode}
    </text>
    <text transform="matrix(1 0 0 1 203.6404 221.2861)" className="st2 st3 st4">
      {errorCode}
    </text>
    <line className="st5" x1="136" y1="294" x2="222" y2="294" />
  </svg>
);

ErrorSvg.propTypes = {
  errorCode: PropTypes.string
};

ErrorSvg.propTypesMeta = 'exclude';

export default ErrorSvg;
