import React from 'react';
import PropTypes from 'prop-types';

const Appointments = ({ items, text }) => (
  <div className="appointments">
    {items && (
      <dl>
        {items.map(({ label, value }) => (
          <React.Fragment key={label + value}>
            <dt>{label}</dt>
            <dd>{value}</dd>
          </React.Fragment>
        ))}
      </dl>
    )}

    {text && <p>{text}</p>}
  </div>
);

Appointments.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  text: PropTypes.string
};

Appointments.defaultProps = {
  items: []
};

export default Appointments;
