import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';

const ProductsFooter = ({ title, text }) => {
  return (
    <div className="products-footer">
      {title && <h3 className="products-footer__title">{title}</h3>}
      {text && (
        <RichText
          text={text}
          hasBottomMargin={false}
          className="products-footer__lead"
        />
      )}
    </div>
  );
};

ProductsFooter.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

ProductsFooter.propTypesMeta = {};

export default ProductsFooter;
