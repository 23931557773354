import React from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import { iframeResizer } from 'iframe-resizer';

// solution uses iframeResizer library:
// https://github.com/davidjbradshaw/iframe-resizer

// the iframe content page must include the file
// 'iframeResizer.contentWindow.min.js', which will automagically
// communicate with this IFramePage component to resize iframe
// to the height of its content.
//
// https://raw.github.com/davidjbradshaw/iframe-resizer/master/js/iframeResizer.contentWindow.min.js

class IframePage extends React.Component {
  static propTypes = {
    iframeURL: PropTypes.string
  };

  state = { iframeURL: this.props.iframeURL };

  screenWidth = 0;

  onResize = () => {
    if (window.innerWidth !== this.screenWidth) {
      this.screenWidth = window.innerWidth;
    }
  };

  debouncedResize = debounce(this.onResize, 300);

  componentDidMount() {
    iframeResizer(null, this.iframe);

    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', this.debouncedResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  }

  render() {
    return (
      <div className="iframe-page">
        {this.state.iframeURL ? (
          <iframe
            src={this.state.iframeURL}
            ref={iframe => (this.iframe = iframe)}
          />
        ) : null}
      </div>
    );
  }
}

export default IframePage;
