import React from 'react';
import PropTypes from 'prop-types';

const OpeningHoursMeta = ({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday
}) => (
  <>
    {monday && <meta itemprop="openingHours" content={'Mo ' + monday} />}
    {tuesday && <meta itemprop="openingHours" content={'Tu ' + tuesday} />}
    {wednesday && <meta itemprop="openingHours" content={'We ' + wednesday} />}
    {thursday && <meta itemprop="openingHours" content={'Th ' + thursday} />}
    {friday && <meta itemprop="openingHours" content={'Fr ' + friday} />}
    {saturday && <meta itemprop="openingHours" content={'Sa ' + saturday} />}
    {sunday && <meta itemprop="openingHours" content={'Su ' + sunday} />}
  </>
);
OpeningHoursMeta.propTypes = {
  monday: PropTypes.string,
  tuesday: PropTypes.string,
  wednesday: PropTypes.string,
  thursday: PropTypes.string,
  friday: PropTypes.string,
  saturday: PropTypes.string,
  sunday: PropTypes.string
};

export default OpeningHoursMeta;
