import PropTypes from 'prop-types';

import Cart from 'components/cart';
import AddToCartConfirmation from 'components/add-to-cart-confirmation';

const AddToCartResponse = {};
AddToCartResponse.propTypes = {
  cartLink: PropTypes.exact({
    numberOfProducts: PropTypes.number,
    text: PropTypes.string,
    url: PropTypes.string
  }),
  cart: PropTypes.exact(Cart.propTypes),
  confirmation: PropTypes.exact(AddToCartConfirmation.propTypes)
};

export default AddToCartResponse;
