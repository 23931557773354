import PropTypes from 'prop-types';

// NOTE: This component is used to provide noops in order to use TextInput without Formsy
const NoopWrapper = ({ children }) =>
  children({
    errorMessage: undefined,
    isInvalid: undefined,
    onBlur: () => {},
    onChange: () => {},
    onRef: () => {}
  });

NoopWrapper.propTypes = {
  children: PropTypes.func
};

NoopWrapper.propTypesMeta = 'exclude';

export default NoopWrapper;
