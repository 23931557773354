import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import Button from '../button';
import FluidImage from '../fluid-image';
import Icon from '../icon';
import OnPageEditing from '../on-page-editing';
import Price from '../price';
import Video from 'components/video';

const themes = {
  compact: 'theme-compact',
  green: 'theme-green',
  mini: 'theme-mini',
  white: 'theme-white'
};

const CtaBlock = ({
  image,
  link,
  locateStoreLink,
  OPE,
  price,
  isFocusable,
  showImage,
  subtitle,
  text,
  theme,
  title,
  video,
  ctaType = 'cta-block'
}) => {
  const tabIndex = isFocusable ? 0 : -1;

  return (
    <div
      className={cn('cta-block', theme, {
        'has-image': !!image && showImage
      })}
    >
      {showImage &&
        (video ? (
          <div className="cta-block-image">
            <Video theme={Video.themes.fill} {...video} />
          </div>
        ) : (
          <OnPageEditing name={OPE.image}>
            {image && (
              <div className="cta-block-image">
                <FluidImage {...image} />
              </div>
            )}
          </OnPageEditing>
        ))}
      <div className="cta-block-text">
        <OnPageEditing name={OPE.title}>
          {title && <h2>{title}</h2>}
        </OnPageEditing>

        <OnPageEditing name={OPE.price}>
          {price && (
            <Price
              className="cta-block-price"
              theme={[
                Price.themes.centered,
                theme && theme.indexOf(themes.green) !== -1
                  ? Price.themes.white
                  : Price.themes.orange
              ]}
              {...price}
            />
          )}
        </OnPageEditing>

        <OnPageEditing name={OPE.subtitle}>
          {subtitle && <h3>{subtitle}</h3>}
        </OnPageEditing>

        <OnPageEditing name={OPE.text}>{text && <p>{text}</p>}</OnPageEditing>

        <OnPageEditing name={OPE.link}>
          {link && (
            <Button
              className="cta-block-link"
              tabIndex={tabIndex}
              theme={
                theme && theme.indexOf(themes.green) !== -1
                  ? Button.themes.white
                  : undefined
              }
              {...link}
              ctaType={ctaType}
              ctaTitle={title}
            />
          )}
        </OnPageEditing>

        <OnPageEditing name={OPE.locateStoreLink}>
          {locateStoreLink && (
            <Button
              className="cta-block-store-link"
              theme={Button.themes.white}
              tabIndex={tabIndex}
              {...locateStoreLink}
              ctaType={ctaType}
              ctaTitle={title}
            >
              <Icon name="pin" />
            </Button>
          )}
        </OnPageEditing>
      </div>
    </div>
  );
};

CtaBlock.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  link: PropTypes.exact(Button.propTypes),
  locateStoreLink: PropTypes.exact(Button.propTypes),
  OPE: PropTypes.exact({
    image: PropTypes.string,
    link: PropTypes.string,
    locateStoreLink: PropTypes.string,
    price: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string
  }),
  price: PropTypes.exact(Price.propTypes),
  showImage: PropTypes.bool,
  isFocusable: PropTypes.bool,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  theme: propTypeTheme(themes),
  title: PropTypes.string,
  video: PropTypes.exact(Video.propTypes),
  ctaType: PropTypes.string
};

CtaBlock.propTypesMeta = {
  showImage: 'exclude',
  theme: 'exclude',
  isFocusable: 'exclude',
  ctaType: 'exclude'
};

CtaBlock.defaultProps = {
  OPE: {},
  showImage: true,
  isFocusable: true
};

CtaBlock.themes = themes;

export default CtaBlock;
