import React from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import getFilter from './filter-stores';
import getLocation from 'js/get-location';

import Search from '../form-elements/search';
import Store from './store';

class FindStore extends React.Component {
  static propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    geolocationText: PropTypes.string,
    googleMapsAPIKey: PropTypes.string,
    search: PropTypes.exact(Search.propTypes),
    stores: PropTypes.arrayOf(PropTypes.exact(Store.propTypes)),
    shouldUseLocation: PropTypes.bool,
    textInputTheme: PropTypes.any,
    updateOnChange: PropTypes.bool
  };

  static propTypesMeta = {
    className: 'exclude',
    shouldUseLocation: 'exclude',
    textInputTheme: 'exclude',
    updateOnChange: 'exclude'
  };

  static defaultProps = {
    search: {},
    stores: []
  };

  state = {
    isLoading: false,
    isUsingLocation: false,
    searchTerm: '',
    stores: this.props.stores,
    userLatitude: null,
    userLongitude: null
  };

  defaultStores = this.props.stores;

  doSubmit = () => {
    this.setState({ isLoading: true }, () => {
      this.filter(
        this.state.searchTerm,
        this.state.userLatitude,
        this.state.userLongitude
      )
        .then(stores => {
          this.setState({ isLoading: false, stores });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  debouncedSubmit = debounce(this.doSubmit, 500, { leading: true });

  onSearchInput = searchTerm => {
    const shouldDoLiveSearch =
      this.props.updateOnChange && searchTerm.length >= 2;

    this.setState({ searchTerm });

    // If the input had a value but is now empty, run doSubmit in order to reset the stores list
    if (shouldDoLiveSearch) {
      this.debouncedSubmit(searchTerm);
    }

    if (!searchTerm) {
      this.setState({ stores: this.defaultStores });
    }
  };

  onSearch = e => {
    e.preventDefault();
    this.setState({ isUsingLocation: false }, () => {
      this.doSubmit();
    });
  };

  componentDidMount() {
    getFilter(this.props.googleMapsAPIKey, this.props.stores)
      .then(filter => {
        this.filter = filter;
      })
      .then(() => {
        if (this.props.shouldUseLocation) {
          getLocation().then(({ latitude, longitude }) => {
            this.setState(
              {
                isUsingLocation: true,
                userLatitude: latitude,
                userLongitude: longitude
              },
              () => {
                this.doSubmit();
              }
            );
          });
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <form className={this.props.className} onSubmit={this.onSearch}>
          <Search
            useFormsy={false}
            onChange={this.onSearchInput}
            showSubmitButton={!this.props.updateOnChange}
            textInputTheme={this.props.textInputTheme}
            {...this.props.search}
          />
        </form>

        {this.state.isUsingLocation && this.props.geolocationText && (
          <h2 className="find-store-geolocation-title">
            {this.props.geolocationText}
          </h2>
        )}

        {this.props.children({
          apiKey: this.props.googleMapsAPIKey,
          isLoading: this.state.isLoading,
          searchTerm: this.state.searchTerm,
          stores: this.state.stores
        })}
      </React.Fragment>
    );
  }
}

export default FindStore;
