import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import PubSub from 'pubsub-js';

import topics from 'js/topics.json';

import Collapse from 'react-tiny-collapse';

import CartProduct from './cart-product';

import CartDiscountForm from './cart-discount-form';
import CartDiscountDeleteForm from './cart-discount-delete-form';

import PageSpinner from '../page-spinner';
import Price from '../price';

const themes = {
  maxi: 'theme-maxi',
  mini: 'theme-mini'
};

class Cart extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    decreaseQuantityLabel: PropTypes.string,
    discount: PropTypes.string,
    discountLabel: PropTypes.string,
    discountCodeForm: PropTypes.exact(CartDiscountForm.propTypes),
    discountsList: PropTypes.arrayOf(
      PropTypes.exact(CartDiscountDeleteForm.propTypes)
    ),
    increaseQuantityLabel: PropTypes.string,
    numberOfProducts: PropTypes.string,
    onUpdate: PropTypes.func,
    priceLabel: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.exact(CartProduct.propTypes)),
    quantityLabel: PropTypes.string,
    removeFromCartLabel: PropTypes.string,
    shippingLabel: PropTypes.string,
    shippingCost: PropTypes.string,
    shouldBeEditable: PropTypes.bool,
    theme: PropTypes.oneOf(Object.keys(themes).map(key => themes[key])),
    totalPrice: PropTypes.string,
    totalPriceLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string
  };

  static propTypesMeta = {
    shouldBeEditable: 'exclude',
    theme: 'exclude'
  };

  static defaultProps = {
    onUpdate: () => {},
    products: [],
    theme: themes.maxi,
    shouldBeEditable: true
  };

  state = {
    decreaseQuantityLabel: this.props.decreaseQuantityLabel,
    discount: this.props.discount,
    discountsList: this.props.discountsList,
    discountLabel: this.props.discountLabel,
    increaseQuantityLabel: this.props.increaseQuantityLabel,
    isLoading: false,
    numberOfProducts: this.props.numberOfProducts,
    priceLabel: this.props.priceLabel,
    products: this.props.products,
    quantityLabel: this.props.quantityLabel,
    removeFromCartLabel: this.props.removeFromCartLabel,
    shippingLabel: this.props.shippingLabel,
    shippingCost: this.props.shippingCost,
    totalPrice: this.props.totalPrice,
    totalPriceLabel: this.props.totalPriceLabel,
    unitPriceLabel: this.props.unitPriceLabel,
    showDiscountForm: true
  };

  // This flag allows the current instance of Cart to ignore data on the subscriber if the data comes from the instance itself. This is done to prevent unnecessary calls to setState. (Useful on the Checkout page where the cart compnent is rendered both in the header and on the page)
  didPublishNewCart = false;

  componentDidMount() {
    this.subscriber = PubSub.subscribe(topics.cartUpdate, (msg, newState) => {
      if (this.didPublishNewCart) {
        this.didPublishNewCart = false;
      } else {
        const { cart } = newState;

        if (cart) {
          this.setState({ ...cart });
          this.props.onUpdate(newState);
        }
      }
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.subscriber);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ isLoading: false });
    }
  }

  onFormSubmit = () => {
    this.setState({ isLoading: true });
  };

  onResponse = response => {
    const { cart } = response;
    this.setState({
      isLoading: false,
      showDiscountForm: !!cart.discountCodeForm,
      ...cart
    });
    this.props.onUpdate(response);
    this.didPublishNewCart = true;
    PubSub.publish(topics.cartUpdate, response);
  };

  render() {
    const isMaxi = this.props.theme === themes.maxi;

    // These labels are omitted for the 'mini' theme to avoid rendering them
    const productLabels = isMaxi
      ? {
          priceLabel: this.state.priceLabel,
          quantityLabel: this.state.quantityLabel,
          unitPriceLabel: this.state.unitPriceLabel
        }
      : {};

    const cartSummaryClassNames = cn('cart-summary', {
      'not-editable': !this.props.shouldBeEditable
    });

    return (
      <div className={cn('cart', this.props.theme)}>
        <PageSpinner
          shouldContainInParent={true}
          isLoading={this.state.isLoading}
        />

        {this.state.numberOfProducts && (
          <p className="cart-number-of-products">
            {this.state.numberOfProducts}
          </p>
        )}

        {!!this.state.products.length && (
          <Collapse isOpen={true} unmountClosed={false}>
            <div className="cart-products">
              <table>
                {this.state.products.map(product => (
                  <CartProduct
                    decreaseQuantityLabel={this.state.decreaseQuantityLabel}
                    increaseQuantityLabel={this.state.increaseQuantityLabel}
                    onSubmit={this.onFormSubmit}
                    onUpdate={this.onResponse}
                    removeFromCartLabel={this.state.removeFromCartLabel}
                    key={product.id}
                    shouldBeEditable={this.props.shouldBeEditable}
                    shouldShowDetails={isMaxi}
                    {...productLabels}
                    {...product}
                  />
                ))}
              </table>
            </div>
          </Collapse>
        )}

        {this.state.discount && (
          <div className={cartSummaryClassNames}>
            <p>{this.state.discountLabel}</p>
            <Price
              originalPrice={this.state.discount}
              theme={Price.themes.smallFont}
            />

            {this.state.discountsList && (
              <ul className="cart__discounts-list">
                {this.state.discountsList.map((discount, i) => (
                  <li className="cart__discounts-list-item" key={i}>
                    <CartDiscountDeleteForm
                      {...discount}
                      onSubmit={this.onFormSubmit}
                      onUpdate={this.onResponse}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {this.state.shippingCost && (
          <div className={cartSummaryClassNames}>
            <p>{this.state.shippingLabel}</p>
            <Price
              originalPrice={this.state.shippingCost}
              theme={Price.themes.smallFont}
            />
          </div>
        )}

        {this.state.totalPrice && (
          <div className={cartSummaryClassNames}>
            <p>{this.state.totalPriceLabel}</p>
            <Price
              originalPrice={this.state.totalPrice}
              theme={
                this.props.theme === themes.mini
                  ? Price.themes.smallFont
                  : Price.themes.bigFont
              }
            />
          </div>
        )}

        {this.props.discountCodeForm && this.state.showDiscountForm && (
          <CartDiscountForm
            {...this.props.discountCodeForm}
            onSubmit={this.onFormSubmit}
            onUpdate={this.onResponse}
          />
        )}

        {this.props.children && (
          <div className="cart-children">{this.props.children}</div>
        )}
      </div>
    );
  }
}

Cart.themes = themes;

export default Cart;
