import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

const Availability = ({ inStock, text }) =>
  !text ? null : (
    <div className="availability">
      {inStock && (
        <div className="availability-icon">
          <Icon name="checkmark" />
        </div>
      )}
      {text}
    </div>
  );

Availability.propTypes = {
  inStock: PropTypes.bool,
  text: PropTypes.string
};

export default Availability;
