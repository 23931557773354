import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Icon from '../icon';

const LensVisualisationSlider = ({ x, isDragging }) => (
  <div
    className="lens-visualisation-slider-wrapper"
    style={{ transform: `translateX(${x}px)` }}
  >
    <div
      className={cn('lens-visualisation-slider', {
        'slider-wrapper-hovered': isDragging
      })}
    >
      <div className="slider-see-through-filter" />
      <span className="slider-line-top" />
      <div className="slider-arrow-group">
        <Icon className="slider-arrow-left" name="big-arrow-right" />
        <div className="slider-square">
          <Icon name="slider" />
        </div>
        <Icon className="slider-arrow-right" name="big-arrow-right" />
      </div>
      <span className="slider-line-bottom" />
    </div>
  </div>
);

LensVisualisationSlider.propTypes = {
  x: PropTypes.number,
  isDragging: PropTypes.bool
};

LensVisualisationSlider.propTypesMeta = 'exclude';

export default LensVisualisationSlider;
