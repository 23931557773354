import React from 'react';
import PropTypes from 'prop-types';

import FormsyWrapper from 'components/formsy-wrapper';

const HiddenInput = ({ name, value }) => (
  <FormsyWrapper name={name} value={value}>
    {() => <input type="hidden" name={name} value={value} />}
  </FormsyWrapper>
);

HiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default HiddenInput;
