import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import Checkbox from 'components/form-elements/checkbox';
import CheckboxGroup from '../form-elements/checkbox-group';
import Grid from '../grid';
import MyPageForm from '../my-page-form';
import MyPageLayout from 'components/my-page-layout';
import MyPageSection from 'components/my-page-section';

const ConsentPage = ({
  form,
  abortLink,
  consentGroups,
  layout,
  section,
  submitLabel
}) => (
  <MyPageLayout {...layout}>
    <Grid theme={Grid.themes.leftColumnWider}>
      <Grid.Column>
        <MyPageSection headingLevel={1} {...section}>
          <MyPageForm showSubmitButton={false} {...form}>
            {consentGroups.map(
              ({ title, options, optionDescriptions = [] }) => (
                <div className="consent-checkbox-group" key={title}>
                  <h3>{title}</h3>
                  <CheckboxGroup
                    checkboxTheme={Checkbox.themes.alignTop}
                    {...options}
                    labels={(options.options || []).map((option, i) => (
                      <span className="consent-checkbox-wrapper">
                        <span className="consent-checkbox-label">
                          {option.label}
                        </span>
                        <span className="consent-checkbox-description">
                          {optionDescriptions[i]}
                        </span>
                      </span>
                    ))}
                  />
                </div>
              )
            )}

            <div className="consent-button-wrapper">
              <Button
                className="consent-button"
                type="submit"
                text={submitLabel}
              />
              <Button
                className="consent-button"
                theme={Button.themes.outline}
                {...abortLink}
              />
            </div>
          </MyPageForm>
        </MyPageSection>
      </Grid.Column>
    </Grid>
  </MyPageLayout>
);

ConsentPage.propTypes = {
  abortLink: PropTypes.shape(Button.propTypes),
  form: PropTypes.exact(MyPageForm.propTypes),
  consentGroups: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      // NOTE: optionDescriptions are matched to options by index
      optionDescriptions: PropTypes.arrayOf(PropTypes.string),
      options: PropTypes.exact(CheckboxGroup.propTypes)
    })
  ),
  layout: PropTypes.exact(MyPageLayout.propTypes),
  section: PropTypes.exact(MyPageSection.propTypes),
  submitLabel: PropTypes.string
};

ConsentPage.defaultProps = {
  consentGroups: [],
  labelDescriptions: []
};

export default ConsentPage;
