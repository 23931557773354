import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import FormsyWrapper from 'components/formsy-wrapper';

class Radio extends React.Component {
  static propTypes = {
    attributes: PropTypes.object,
    idPrefix: PropTypes.string,
    labels: PropTypes.node,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.string.isRequired,
        selected: PropTypes.bool,
        value: PropTypes.string.isRequired
      })
    ).isRequired,
    validations: PropTypes.string,
    validationErrors: PropTypes.object
  };

  static propTypesMeta = {
    attributes: 'exclude',
    idPrefix: 'exclude',
    validations: 'exclude',
    validationErrors: 'exclude'
  };

  static defaultProps = {
    idPrefix: '',
    onChange: () => {},
    onValidate: () => {},
    options: []
  };

  onChange = value => {
    const selectedOption = this.props.options.find(
      option => option.value === value
    );

    this.props.onChange(value, selectedOption && selectedOption.label);
  };

  render() {
    return (
      <FormsyWrapper
        name={this.props.name}
        initialValue={get(this.props.options.find(o => o.selected), 'value')}
        onValidate={this.props.onValidate}
        validations={this.props.validations}
        validationErrors={this.props.validationErrors}
      >
        {({ getValue, setValue }) => (
          <fieldset className="radio">
            {this.props.options.map((option, index) => {
              const id =
                this.props.idPrefix +
                '-' +
                this.props.name +
                '-' +
                option.value;

              return (
                <div className="radio-input" key={option.value}>
                  <input
                    type="radio"
                    checked={option.value === getValue()}
                    id={id}
                    onChange={e => {
                      setValue(e.target.value);
                      this.onChange(e.target.value);
                    }}
                    name={this.props.name}
                    value={option.value}
                    {...this.props.attributes}
                  />
                  <label htmlFor={id}>
                    {this.props.labels
                      ? this.props.labels[index]
                      : option.label}
                  </label>
                </div>
              );
            })}
          </fieldset>
        )}
      </FormsyWrapper>
    );
  }
}

export default Radio;
