import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import ContentContainer from '../content-container';
import Icon from '../icon';
import Image from '../image';
import Link from '../link';
import StorePageHeader from './store-page-header';
import RichText from '../rich-text';

const StorePage = ({ header, image, link, mapEmbedUrl, mapLink, richText }) => (
  <div className="store-page">
    <ContentContainer>
      {link && (
        <Link prependChildren={true} theme={Link.themes.noUnderline} {...link}>
          <Icon name="small-arrow-left" />
        </Link>
      )}
      <StorePageHeader {...header} />

      <iframe className="store-page-map" src={mapEmbedUrl} />

      {mapLink && (
        <Button
          className="store-page-button"
          theme={Button.themes.big}
          {...mapLink}
        />
      )}

      <div className="store-info">
        <Image {...image} />
        <RichText theme={RichText.themes.centered} {...richText} />
      </div>
    </ContentContainer>
  </div>
);

StorePage.propTypes = {
  header: PropTypes.exact(StorePageHeader.propTypes),
  image: PropTypes.exact(Image.propTypes),
  link: PropTypes.exact(Link.propTypes),
  mapEmbedUrl: PropTypes.string,
  mapLink: PropTypes.exact(Link.propTypes),
  richText: PropTypes.exact(RichText.propTypes)
};

export default StorePage;
