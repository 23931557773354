import React from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import breakpoints from '../../js/breakpoints';

import Carousel from '../carousel';
import Image from '../image';
import Link from '../link';

class ProductColors extends React.Component {
  static defaultProps = {
    colors: []
  };

  static propTypes = {
    title: PropTypes.string,
    numberOfSlidesToShow: PropTypes.number,
    colors: PropTypes.arrayOf(
      PropTypes.exact({
        image: PropTypes.exact(Image.propTypes),
        link: PropTypes.exact(Link.propTypes)
      })
    )
  };

  static propTypesMeta = {
    numberOfSlidesToShow: 'exclude'
  };

  state = {
    numberOfSlidesToShow: 3
  };

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.debouncedResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  }

  onResize = () => {
    let numberOfSlidesToShow = this.state.numberOfSlidesToShow;

    if (breakpoints.smMax()) {
      numberOfSlidesToShow = 2;
    } else if (breakpoints.mdMax()) {
      numberOfSlidesToShow = 3;
    } else if (breakpoints.lgMax()) {
      numberOfSlidesToShow = 2;
    } else {
      numberOfSlidesToShow = 3;
    }

    this.setState({
      numberOfSlidesToShow
    });
  };

  debouncedResize = debounce(this.onResize, 400);

  render() {
    const { title, colors } = this.props;

    return colors.length === 0 ? null : (
      <div className="glasses-color-slideshow">
        <h2>{title}</h2>
        {colors.length > 0 && (
          <Carousel
            numberOfSlidesToShow={this.state.numberOfSlidesToShow}
            theme={[Carousel.themes.small, Carousel.themes.green]}
            shouldShowDots={false}
          >
            {colors.map(({ link, image } = {}) => (
              <Link
                className="glasses-color"
                url={link.url}
                key={image.src}
                theme={Link.themes.noStyle}
              >
                <Image {...image} />
                <span className="glasses-color-label">{link.text}</span>
              </Link>
            ))}
          </Carousel>
        )}
      </div>
    );
  }
}

export default ProductColors;
