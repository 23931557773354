import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';

const MyOrdersItem = ({
  columnLabels,
  date,
  id,
  orderNumber,
  status,
  packageTrackingLink
}) => (
  <tr key={id}>
    <td>
      <span>{columnLabels[0]}</span>
      <span>{date}</span>
    </td>
    <td>
      {orderNumber && (
        <React.Fragment>
          <span>{columnLabels[1]}</span>
          <span>
            <Link theme={Link.themes.skewUnderline} {...orderNumber} />
          </span>
        </React.Fragment>
      )}
    </td>
    <td>
      <span>{columnLabels[2]}</span>
      <span>{status}</span>
    </td>
    <td>
      {packageTrackingLink && (
        <React.Fragment>
          <span>
            <Link theme={Link.themes.skewUnderline} {...packageTrackingLink} />
          </span>
        </React.Fragment>
      )}
    </td>
  </tr>
);

MyOrdersItem.propTypes = {
  columnLabels: PropTypes.arrayOf(PropTypes.string),
  date: PropTypes.string,
  id: PropTypes.string, // NOTE: Used as key in MyOrders
  orderNumber: PropTypes.exact(Link.propTypes),
  status: PropTypes.string,
  packageTrackingLink: PropTypes.exact(Link.propTypes)
};

MyOrdersItem.propTypesMeta = {
  columnLabels: 'exclude'
};

MyOrdersItem.defaultProps = {
  columnLabels: []
};

export default MyOrdersItem;
