import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

const themes = {
  big: 'theme-big',
  circle: 'theme-circle',
  gray: 'theme-gray',
  orange: 'theme-orange',
  outline: 'theme-outline',
  light: 'theme-light',
  mini: 'theme-mini',
  plain: 'theme-plain',
  white: 'theme-white',
  uppercase: 'theme-uppercase',
  link: 'theme-link'
};

const Button = ({
  attributes,
  className,
  children,
  disabled,
  formId,
  tabIndex,
  htmlFor,
  onClick,
  prependChildren,
  text,
  theme,
  type,
  url,
  ctaTitle,
  ctaType,
  title
}) => {
  // NOTE: The backend will occasionally send Button models where every property is null or an empty string. To avoid rendering buttons in these cases, a lot of null checking is needed.
  if (!(text || children) && !url && !onClick && !htmlFor) {
    return null;
  }

  let ButtonComponent;
  const themeList =
    typeof theme === 'object' && theme !== null ? [...theme] : [theme];

  let buttonProps = {
    ...attributes,
    className: cn('button', className, ...themeList, {
      'has-children': children
    }),
    form: formId,
    onClick: onClick,
    'data-cta-title': ctaTitle ? ctaTitle : null,
    'data-cta-type': ctaType ? ctaType : null,
    title: title ? title : null
  };

  if (htmlFor) {
    ButtonComponent = 'label';
    buttonProps = { ...buttonProps, htmlFor };
  } else if (url) {
    ButtonComponent = 'a';
    buttonProps = { ...buttonProps, href: url };
  } else {
    ButtonComponent = 'button';
    buttonProps = { ...buttonProps, disabled: disabled, type: type };
  }

  return (
    <ButtonComponent {...buttonProps} tabIndex={tabIndex}>
      {prependChildren && children}
      {text && <span>{text}</span>}
      {!prependChildren && children}
    </ButtonComponent>
  );
};

Button.propTypes = {
  attributes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  formId: PropTypes.string,
  htmlFor: PropTypes.string,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  prependChildren: PropTypes.bool,
  text: PropTypes.string,
  theme: propTypeTheme(themes),
  type: PropTypes.oneOf(['button', 'submit']),
  url: PropTypes.string,
  ctaTitle: PropTypes.string,
  ctaType: PropTypes.string,
  title: PropTypes.string
};

Button.propTypesMeta = {
  attributes: 'exclude',
  className: 'exclude',
  disabled: 'exclude',
  tabIndex: 'exclude',
  formId: 'exclude',
  htmlFor: 'exclude',
  prependChildren: 'exclude',
  theme: 'exclude',
  type: 'exclude',
  ctaTitle: 'exclude',
  ctaType: 'exclude',
  title: 'exclude'
};

Button.defaultProps = {
  type: 'button',
  theme: []
};

Button.themes = themes;

export default Button;
