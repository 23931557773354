import React from 'react';
import PropTypes from 'prop-types';

import FluidImage from '../fluid-image';
import Icon from '../icon';
import Link from '../link';
import ProductCard from '../product-card';
import ProductList from '../product-list';

const CategoryBlock = ({ image, link, products, title }) => (
  <div className="category-block">
    <div className="category-block-text">
      {title && <h2>{title}</h2>}
      {link && (
        <Link theme={Link.themes.noUnderline} {...link}>
          <Icon name="small-arrow-right" />
        </Link>
      )}
    </div>

    <div className="category-block-image">
      <FluidImage {...image} />
    </div>

    <div className="category-block-products">
      {products && (
        <ProductList
          productTheme={ProductCard.themes.horizontal}
          theme={ProductList.themes.vertical}
          {...products}
          ctaType="category-block"
        />
      )}
    </div>
  </div>
);

CategoryBlock.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  link: PropTypes.exact(Link.propTypes),
  products: PropTypes.exact(ProductList.propTypes),
  title: PropTypes.string
};

export default CategoryBlock;
