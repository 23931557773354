import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import Icon from '../icon';
import OnPageEditing from '../on-page-editing';

const LinkList = ({ items, OPE, title }) => (
  <div className="link-list">
    <OnPageEditing name={OPE.title}>{title && <h2>{title}</h2>}</OnPageEditing>

    <OnPageEditing name={OPE.items}>
      <ul>
        {items.map(item => (
          <li key={item.url + item.text}>
            <Button
              className="link-list-item"
              theme={Button.themes.light}
              {...item}
            >
              <Icon className="link-list-icon" name="small-arrow-right" />
            </Button>
          </li>
        ))}
      </ul>
    </OnPageEditing>
  </div>
);

LinkList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(Button.propTypes)),
  OPE: PropTypes.exact({
    items: PropTypes.string,
    title: PropTypes.string
  }),
  title: PropTypes.string
};

LinkList.defaultProps = {
  items: [],
  OPE: {}
};

export default LinkList;
