/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

const themes = {
  editorialPage: 'theme-editorial-page',
  leftColumnWider: 'theme-left-column-wider',
  noMargin: 'theme-no-margin'
};

const Grid = ({ children, className, theme }) => (
  <div className={cn('grid', className, theme)}>{children}</div>
);

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: propTypeTheme(themes)
};

Grid.propTypesMeta = 'exclude';

Grid.Column = ({ children }) => <div className="grid-column">{children}</div>;

Grid.themes = themes;

Grid.Column.propTypes = {
  children: PropTypes.node
};

export default Grid;
