import React from 'react';

import cn from 'classnames';

import Button from '../button';
import Icon from '../icon';

// eslint-disable-next-line react/prop-types
const ArrowButton = ({ className, theme, ...props }) => (
  <Button className={cn('arrow-button', className)} theme={theme} {...props}>
    <Icon className="arrow-button-icon" name="big-arrow-right" />
  </Button>
);

ArrowButton.propTypes = Button.propTypes;

ArrowButton.defaultProps = {
  theme: Button.themes.orange
};

export default ArrowButton;
