import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Icon from '../../icon';
import OptionGroup from '../option-group';

const icons = {
  kids: 'category-kids',
  man: 'category-man',
  woman: 'category-woman'
};

const GenderSelect = ({ idPrefix, name, options }) => {
  return (
    <div className="gender-select">
      <OptionGroup name={name} options={options}>
        {({ value, onChange }) => (
          <ul>
            {options.map(option => (
              <li key={option.value}>
                <input
                  type="checkbox"
                  checked={value.indexOf(option.value) !== -1}
                  disabled={option.disabled}
                  id={idPrefix + option.value}
                  name={name}
                  onChange={e => {
                    onChange({
                      value: option.value,
                      checked: e.target.checked
                    });
                  }}
                  value={option.value}
                />
                <label
                  className="gender-select-label"
                  htmlFor={idPrefix + option.value}
                >
                  <div
                    className={cn('gender-select-icon-wrapper', {
                      ['gender-select-selected']:
                        value.indexOf(option.value) !== -1
                    })}
                  >
                    <Icon
                      name={
                        value.indexOf(option.value) !== -1
                          ? 'checkmark'
                          : icons[option.genderName]
                      }
                    />
                  </div>
                  <span>{option.label}</span>
                </label>
              </li>
            ))}
          </ul>
        )}
      </OptionGroup>
    </div>
  );
};

GenderSelect.propTypes = {
  idPrefix: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      disabled: PropTypes.bool,
      genderName: PropTypes.oneOf(Object.keys(icons)).isRequired,
      label: PropTypes.string,
      selected: PropTypes.bool,
      value: PropTypes.string
    })
  )
};

GenderSelect.propTypesMeta = {
  idPrefix: 'exclude'
};

GenderSelect.defaultProps = {
  options: []
};

export default GenderSelect;
