import React from 'react';

import LoginForm from '../login-form';

const LoginPage = props => (
  <LoginForm theme={LoginForm.themes.maxi} {...props} />
);

LoginPage.propTypes = LoginForm.propTypes;

export default LoginPage;
