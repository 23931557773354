import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Icon from 'components/icon';

const PageHeaderLinkList = ({ items }) => (
  <div className="page-header-link-list">
    {items.map(link => {
      return (
        <Button
          className="page-header-link"
          key={link.text}
          theme={Button.themes.white}
          {...link}
        >
          <Icon className="page-header-link-icon" name="small-arrow-right" />
        </Button>
      );
    })}
  </div>
);

PageHeaderLinkList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(Button.propTypes))
};

PageHeaderLinkList.defaultProps = {
  items: []
};

export default PageHeaderLinkList;
