import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../checkbox';
import CollapseList from '../../collapse-list';
import OptionGroup from '../option-group';

const CheckboxGroup = ({
  collapseLabel,
  checkboxTheme,
  expandLabel,
  idPrefix,
  labels,
  maxLength,
  name,
  options
}) => {
  return (
    <OptionGroup name={name} options={options}>
      {({ value, onChange }) => {
        return (
          <CollapseList {...{ collapseLabel, expandLabel, maxLength }}>
            {options.map((option, i) => {
              return (
                <Checkbox
                  key={i}
                  checked={value.includes(option.value)}
                  idPrefix={idPrefix}
                  labelChild={labels && labels[i]}
                  name={name}
                  onChange={onChange}
                  theme={checkboxTheme}
                  useFormsy={false}
                  {...option}
                />
              );
            })}
          </CollapseList>
        );
      }}
    </OptionGroup>
  );
};

CheckboxGroup.propTypes = {
  collapseLabel: PropTypes.string,
  checkboxTheme: PropTypes.any,
  expandLabel: PropTypes.string,
  idPrefix: PropTypes.string,
  labels: PropTypes.node,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(Checkbox.propTypes))
};

CheckboxGroup.propTypesMeta = {
  checkboxTheme: 'exclude',
  idPrefix: 'exclude'
};

CheckboxGroup.defaultProps = {
  options: []
};

export default CheckboxGroup;
