import React from 'react';
import PropTypes from 'prop-types';

import OrderAgainButton from 'components/order-again-button';
import OrderListItem from './order-list-item';

const OrderList = ({ columnLabels, orderAgain, rows }) => (
  <div className="order-list">
    <table>
      <thead>
        <tr>
          {columnLabels.map(label => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, i) => (
          <OrderListItem key={i} {...row} />
        ))}
      </tbody>
    </table>

    <div className="order-list-button-container">
      {orderAgain && <OrderAgainButton {...orderAgain} />}
    </div>
  </div>
);

OrderList.defaultProps = {
  columnLabels: [],
  rows: []
};

OrderList.propTypes = {
  columnLabels: PropTypes.arrayOf(PropTypes.string),
  orderAgain: PropTypes.exact(OrderAgainButton.propTypes),
  rows: PropTypes.arrayOf(PropTypes.exact(OrderListItem.propTypes))
};

export default OrderList;
