import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const PORTER_BUDDY_ID = 'porterbuddy-widget';

const PorterBuddy = ({
  config,
  onSelect,
  onUpdate,
  scriptSrc,
  styleHref,
  hasError
}) => {
  const container = useRef();

  useEffect(() => {
    const script = document.createElement('script');
    const link = document.createElement('link');

    script.src = scriptSrc;
    script.async = true;
    script.onload = () => {
      // console.log('--> Loaded script');
    };

    link.href = styleHref;
    link.rel = 'stylesheet';
    link.type = 'text/css';

    container.current.appendChild(script);
    container.current.appendChild(link);

    return () => {
      container.current.removeChild(script);
      container.current.removeChild(link);
    };
  }, []);

  useEffect(() => {
    window.porterbuddy = {
      ...window.porterbuddy,
      ...config,
      onSelectDeliveryWindow: onSelect,
      onSetCallbacks: ({ forceRefresh, setSelectedDeliveryWindow }) => {
        PorterBuddy.handleOnRefresh = () => {
          // console.log('--> Refreshing porterbuddy');

          forceRefresh(null, true);
        };
        PorterBuddy.handleOnSelectDeliveryWindow = deliveryWindow => {
          // console.log(
          //   '--> Setting default window in porterbuddy',
          //   deliveryWindow
          // );

          setSelectedDeliveryWindow(deliveryWindow);
        };
      },
      onUpdateDeliveryWindows: onUpdate
    };

    if (!config.availabilityResponse) return;

    PorterBuddy.handleOnRefresh();

    PorterBuddy.handleOnSelectDeliveryWindow(
      config.availabilityResponse.deliveryWindows[0]
    );

    return () => {
      window.porterbuddy = undefined;
    };
  }, [config]);

  return (
    <div
      aria-live="polite"
      className={cn('porter-buddy', {
        'porter-buddy--hidden': hasError
      })}
      ref={container}
    >
      <div id={PORTER_BUDDY_ID} />
    </div>
  );
};

PorterBuddy.handleOnRefresh = () => {};
PorterBuddy.handleOnSelectDeliveryWindow = () => {};

PorterBuddy.propTypes = {
  styleHref: PropTypes.string,
  scriptSrc: PropTypes.string,
  hasError: PropTypes.bool,
  onSelect: PropTypes.func,
  onUpdate: PropTypes.func,
  config: PropTypes.shape({
    availabilityResponse: PropTypes.object
  })
};

PorterBuddy.defaultProps = {
  styleHref: 'https://widget.porterbuddy.com/porterbuddy-widget.css',
  scriptSrc: 'https://widget.porterbuddy.com/porterbuddy-widget.js'
};

PorterBuddy.propTypesMeta = 'exclude';

export default PorterBuddy;
