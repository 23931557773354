import React from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import api from '../../js/api-helper';
import checkApiPropTypes from 'js/check-api-prop-types';

import Collapse from 'react-tiny-collapse';

import EmptyListMessage from '../empty-list-message';
import GlobalSearchResponse from './global-search-response';
import PageSpinner from '../page-spinner';
import SearchResults from './search-results';
import Search from '../form-elements/search';

class GlobalSearch extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    noResultsMessage: PropTypes.string,
    onMeasure: PropTypes.func,
    search: PropTypes.exact(Search.propTypes),
    suggestionEndpoint: PropTypes.string,
    url: PropTypes.string.isRequired
  };

  static defaultProps = {
    onMeasure: () => {},
    queryParameter: 'q'
  };

  state = {
    isLoading: false,
    isSearchResultsVisible: false,
    searchResults: null
  };

  isLoading = false;
  searchTerm = '';

  onMeasure = height => {
    if (this.wrapper) {
      const wrapperStyle = getComputedStyle(this.wrapper);
      this.props.onMeasure(
        height +
          parseInt(wrapperStyle.paddingTop) +
          parseInt(wrapperStyle.paddingBottom)
      );
    }
  };

  onSearchInput = debounce(searchTerm => {
    if (!this.props.search || !this.props.search.name) return;

    this.searchTerm = searchTerm;

    if (searchTerm && searchTerm.length >= 3 && !this.isLoading) {
      this.isLoading = true;

      this.setState({ isLoading: true }, () => {
        api
          .execute(this.props.suggestionEndpoint, {
            [this.props.search.name]: searchTerm
          })
          .then(newState => {
            this.isLoading = false;

            checkApiPropTypes(
              newState,
              GlobalSearchResponse.propTypes,
              'GlobalSearchResponse'
            );

            this.setState({
              ...newState,
              isLoading: false,
              isSearchResultsVisible: true
            });

            // In case someone types while loading
            if (this.searchTerm !== searchTerm) {
              this.onSearchInput(this.searchTerm);
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.setState({ isLoading: false });
          });
      });
    }
  }, 500);

  render() {
    return (
      <div className="global-search" ref={div => (this.wrapper = div)}>
        <form action={this.props.url} method="get">
          <label>
            <span className="global-search-label-text">{this.props.label}</span>
            <Search
              autoComplete="off"
              onChange={this.onSearchInput}
              onRef={input => (this.input = input)}
              theme={Search.themes.big}
              useFormsy={false}
              {...this.props.search}
            />
          </label>
        </form>

        <Collapse
          className="global-search-results"
          isOpen={this.state.isLoading || this.state.isSearchResultsVisible}
          onMeasure={this.onMeasure}
        >
          <div aria-live="polite" className="global-search-results-content">
            <SearchResults {...this.state.searchResults}>
              {hasResults =>
                !this.state.isLoading &&
                !hasResults && (
                  <EmptyListMessage
                    className="global-search-empty-results"
                    text={this.props.noResultsMessage}
                  />
                )
              }
            </SearchResults>
            <PageSpinner
              isLoading={this.state.isLoading}
              shouldContainInParent={true}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

export default GlobalSearch;
