import React from 'react';
import PropTypes from 'prop-types';

import Image from '../image';

const SellingPoints = ({ items }) => (
  <div className="selling-points">
    {items.map(({ image, text, url } = {}) => {
      const Element = url ? 'a' : 'div';

      return (
        <Element className="selling-points-item" href={url} key={text}>
          <div className="selling-points-icon">
            <Image responsive={false} {...image} />
          </div>
          <div className="selling-points-text">{text}</div>
        </Element>
      );
    })}
  </div>
);

SellingPoints.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      image: PropTypes.exact(Image.propTypes),
      text: PropTypes.string,
      url: PropTypes.string
    })
  )
};

SellingPoints.defaultProps = {
  items: []
};

export default SellingPoints;
