import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import get from 'lodash/get';

import analytics from '../../js/analytics';

import Bomb from '../bomb';
import Button from '../button';
import Image from 'components/image';
import Price from '../price';

const productCategories = {
  contactLens: 'ContactLens',
  Glass: 'Glass',
  Sunglass: 'Sunglass',
  EyeHealth: 'EyeHealth'
};

const themes = {
  backgroundColor: 'theme-background-color',
  horizontal: 'theme-horizontal',
  smallerImages: 'theme-smaller-images'
};

class ProductCard extends React.Component {
  static propTypes = {
    bomb: PropTypes.string,
    bombIsGreen: PropTypes.bool,
    brand: PropTypes.string,
    cta: PropTypes.exact(Button.propTypes),
    id: PropTypes.string,
    image: PropTypes.exact(Image.propTypes),
    price: PropTypes.exact(Price.propTypes),
    category: PropTypes.oneOf(Object.values(productCategories)),
    details: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    url: PropTypes.string,
    theme: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    variant: PropTypes.string,
    ctaType: PropTypes.string
  };

  static propTypesMeta = {
    theme: 'exclude',
    ctaType: 'exclude'
  };

  static defaultProps = {
    details: [],
    ctaType: 'product-card'
  };

  productClick = e => {
    e.preventDefault();

    let price = '';
    if (this.props.price) {
      price = this.props.price.discountedPrice
        ? this.props.price.discountedPrice
        : this.props.price.originalPrice;

      price = price.replace(/,/g, '.').replace(/\s/g, ''); //remove space and change comma to dot
    }

    analytics.onProductClick(
      Object.assign({}, this.props, {
        price: price
      }),
      () => {
        if (this.props.url) {
          window.location = this.props.url;
        }
      }
    );
  };

  render() {
    const className = cn('product-card', this.props.theme);
    const useLinkElement = this.props.url && !this.props.cta;

    const WrapperElement = useLinkElement ? 'a' : 'div';
    const wrapperProps = useLinkElement
      ? {
          'data-test-product-card': '',
          href: this.props.url,
          className,
          onClick: this.productClick,
          'data-cta-title': this.props.name ? this.props.name : null,
          'data-cta-type': this.props.ctaType ? this.props.ctaType : null
        }
      : { className };

    return (
      <WrapperElement {...wrapperProps}>
        <div className="product-card-content">
          <div className="product-card-image">
            <div className="product-card-image-inner">
              <Image
                theme={Image.themes.contained}
                containedPosition="center bottom"
                {...this.props.image}
              />
            </div>
          </div>
          {this.props.bomb && (
            <div className="product-card-bomb">
              <Bomb
                text={this.props.bomb}
                theme={this.props.bombIsGreen && Bomb.themes.green}
              />
            </div>
          )}
          <Price theme={Price.themes.smallFont} {...this.props.price}>
            {(smallPriceElement, bigPriceElement) => (
              <React.Fragment>
                <div className="product-card-details">
                  {!!this.props.details.length && (
                    <ul>
                      {this.props.details.map(text => (
                        <li key={text}>{text}</li>
                      ))}
                    </ul>
                  )}
                  <div className="product-card-small-price">
                    {smallPriceElement}
                  </div>
                </div>
                <div className="product-card-text">
                  <div className="product-card-text-inner">
                    <p className="product-card-brand">{this.props.name}</p>
                    <p
                      className={cn('product-card-name', {
                        'is-highlighted':
                          this.props.category === productCategories.contactLens
                      })}
                    >
                      {this.props.brand}
                    </p>
                  </div>
                  <div className="product-card-big-price">
                    {bigPriceElement}
                  </div>
                </div>
              </React.Fragment>
            )}
          </Price>
          {this.props.cta && (
            <Button className="product-card-cta" {...this.props.cta} />
          )}
        </div>
      </WrapperElement>
    );
  }
}

ProductCard.themes = themes;

export default ProductCard;
