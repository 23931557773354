import get from 'lodash/get';

const getFilter = filterGroup =>
  filterGroup.colorSelect ||
  filterGroup.genderSelect ||
  filterGroup.shapeSelect ||
  filterGroup.genericFilter;

export default function CreateFilterModel({
  filters,
  showNewProductsOnly,
  sorting
}) {
  if (!filters || !filters.filterGroups) {
    return;
  }

  const { filterGroups } = filters;

  return Object.assign(
    {},
    filterGroups.reduce((accum, filterGroup) => {
      const filter = getFilter(filterGroup);
      return Object.assign({}, accum, {
        [filter.name]: filter.options
          .filter(option => option.selected)
          .map(option => get(option, 'value'))
      });
    }, {}),
    sorting
      ? {
          [sorting.name]: get(
            sorting.options.find(option => option.selected),
            'value'
          )
        }
      : {},
    showNewProductsOnly
      ? {
          [showNewProductsOnly.name]: showNewProductsOnly.selected
        }
      : {}
  );
}
