import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from '../button';
import CategoryLink from './category-link';
import ShapeLink from './shape-link';
import ContentContainer from 'components/content-container';
import Icon from 'components/icon';
import Link from 'components/link';

const SubMenu = ({
  categoriesTitle,
  categories,
  shapesTitle,
  shapes,
  closeLabel,
  hide,
  linkListsTitle,
  linkLists,
  isHidden = false
}) => {
  const linksTabindex = isHidden ? -1 : null;

  return (
    <div className="submenu">
      <ContentContainer className="submenu-content">
        {(categories.length > 0 || (shapes && shapes.length > 0)) && (
          <div className="submenu-category-container">
            {categories.length > 0 && (
              <>
                {categoriesTitle && <h2>{categoriesTitle}</h2>}
                <ul>
                  {categories.map(link => (
                    <li key={link.url}>
                      <CategoryLink {...link} tabIndex={linksTabindex} />
                    </li>
                  ))}
                </ul>
              </>
            )}
            {shapes && shapes.length > 0 && (
              <>
                {shapesTitle && <h2>{shapesTitle}</h2>}
                <ul>
                  {shapes.map(link => (
                    <li key={link.url}>
                      <ShapeLink {...link} tabIndex={linksTabindex} />
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        )}
        <div className="submenu-links-container">
          {linkListsTitle && <h2>{linkListsTitle}</h2>}
          <div className="submenu-link-lists">
            {linkLists.map((linkList, index) => (
              <div
                className={cn('submenu-link-list', {
                  'is-editorial': linkList.links.every(link => link.editorial),
                  'is-only-list': linkLists.length === 1,
                  'is-two-columns': linkList.links.length > 10
                })}
                key={linkList.title || index}
              >
                {linkList.title && <h3>{linkList.title}</h3>}
                <ul>
                  {linkList.links.map(({ editorial, link }) => (
                    <li key={link.url}>
                      <Link
                        className="submenu-link"
                        prependChildren={editorial}
                        theme={[Link.themes.noStyle, Link.themes.skew]}
                        {...link}
                        tabindex={linksTabindex}
                      >
                        {editorial && (
                          <Icon
                            className="submenu-link-icon"
                            name="small-arrow-right"
                          />
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </ContentContainer>
      <Button
        className="submenu-close"
        onClick={hide}
        theme={[Button.themes.light, Button.themes.circle]}
        disabled={isHidden}
      >
        <span>{closeLabel}</span>
        <Icon name="small-x" />
      </Button>
    </div>
  );
};

SubMenu.propTypes = {
  categoriesTitle: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.exact(CategoryLink.propTypes)),
  shapesTitle: PropTypes.string,
  shapes: PropTypes.arrayOf(PropTypes.exact(ShapeLink.propTypes)),
  closeLabel: PropTypes.string,
  hide: PropTypes.func,
  linkListsTitle: PropTypes.string,
  linkLists: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.exact({
          editorial: PropTypes.bool,
          link: PropTypes.exact(Link.propTypes)
        })
      )
    })
  ),
  isHidden: PropTypes.bool
};

SubMenu.propTypesMeta = {
  isHidden: 'exclude'
};

SubMenu.defaultProps = {
  categories: [],
  linkLists: []
};

export default SubMenu;
