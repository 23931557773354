import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import TextInput from '../form-elements/text-input';

const CustomerDataImportForm = ({
  endpoint,
  secondaryAction,
  submitLabel,
  textInput
}) => (
  <div className="customer-data-import-form">
    <form action={endpoint} method="POST">
      <div className="customer-data-import-form-input">
        <TextInput useFormsy={false} {...textInput} />
      </div>

      <div className="customer-data-import-form-actions">
        <Button type="submit" text={submitLabel} />
        {secondaryAction && (
          <Button theme={Button.themes.outline} {...secondaryAction} />
        )}
      </div>
    </form>
  </div>
);

CustomerDataImportForm.propTypes = {
  endpoint: PropTypes.string,
  secondaryAction: PropTypes.exact(Button.propTypes),
  submitLabel: PropTypes.string,
  textInput: PropTypes.exact(TextInput.propTypes)
};

export default CustomerDataImportForm;
