import React from 'react';
import PropTypes from 'prop-types';

import { Motion, spring } from 'react-motion';

import Image from '../image';
import LensVisualisationSlider from './lens-visualisation-slider';

const colorNames = {
  'Grey/Black': 'lens-visualisation-overlay-gray-black',
  Brown: 'lens-visualisation-overlay-brown',
  Green: 'lens-visualisation-overlay-green'
};

class LensVisualisation extends React.Component {
  static propTypes = {
    image: PropTypes.exact(Image.propTypes),
    lensColor: PropTypes.oneOf(Object.keys(colorNames)),
    text: PropTypes.string
  };

  state = {
    posX: 0
  };

  isDragging = false;
  sliderIsMoving = false;
  xStart = null;

  componentDidMount() {
    if (this.visualisationWrapper) {
      this.visualisationWrapper.addEventListener('mousemove', this.onMouseMove);
      this.visualisationWrapper.addEventListener('mouseup', this.onMouseUp);
      this.visualisationWrapper.addEventListener('mousedown', this.onMouseDown);
      this.visualisationWrapper.addEventListener(
        'touchstart',
        this.onTouchStart
      );
      this.visualisationWrapper.addEventListener('touchmove', this.onTouchMove);
      this.visualisationWrapper.addEventListener('touchend', this.onTouchEnd);
      this.visualisationWrapper.addEventListener(
        'touchcancel',
        this.onTouchEnd
      );
    }
  }

  componentWillUnmount() {
    if (this.visualisationWrapper) {
      this.visualisationWrapper.removeEventListener(
        'mousemove',
        this.onMouseMove
      );
      this.visualisationWrapper.removeEventListener('mouseup', this.onMouseUp);
      this.visualisationWrapper.removeEventListener(
        'mousedown',
        this.onMouseDown
      );
      this.visualisationWrapper.removeEventListener(
        'touchstart',
        this.onTouchStart
      );
      this.visualisationWrapper.removeEventListener(
        'touchmove',
        this.onTouchMove
      );
      this.visualisationWrapper.removeEventListener(
        'touchend',
        this.onTouchEnd
      );
      this.visualisationWrapper.removeEventListener(
        'touchcancel',
        this.onTouchEnd
      );
    }
  }

  onMouseDown = () => {
    this.isDragging = true;
  };

  onMouseUp = () => {
    this.isDragging = false;
  };

  slider = event => {
    const x =
      event.pageX -
      this.visualisationWrapper.getBoundingClientRect().left -
      this.visualisationWrapper.offsetWidth / 2;

    this.setState({
      posX: x
    });
  };

  onTouchStart = event => {
    this.xStart = event.touches[0].clientX;
  };

  onTouchEnd = () => {
    this.sliderIsMoving = false;
  };

  onTouchMove = event => {
    if (!this.xStart) {
      return;
    }

    const xMove = event.touches[0].clientX;

    const xDiff = this.xStart - xMove;

    if (Math.abs(xDiff) > 20 || this.sliderIsMoving) {
      event.preventDefault();
      this.slider(event.touches[0]);
      this.sliderIsMoving = true;
    }
  };

  onMouseMove = event => {
    if (this.isDragging) {
      this.slider(event);
    }
  };

  render() {
    const { image, lensColor, text } = this.props;

    return (
      <div className="lens-visualisation-wrapper">
        {colorNames[lensColor] ? (
          <div>
            <Motion style={{ x: spring(this.state.posX) }}>
              {({ x }) => {
                return (
                  <div
                    className="lens-visualisation"
                    ref={visualisationWrapper => {
                      this.visualisationWrapper = visualisationWrapper;
                    }}
                  >
                    <div
                      className={
                        'lens-visualisation-overlay ' + colorNames[lensColor]
                      }
                      style={{ transform: `translateX(${x}px)` }}
                    />
                    <Image src={image.src} alt={image.alt} draggable={false} />
                    <LensVisualisationSlider
                      x={x}
                      isDragging={this.isDragging}
                    />
                  </div>
                );
              }}
            </Motion>
            <p>{text}</p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default LensVisualisation;
