import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import Collapse from 'react-tiny-collapse';

import propTypeTheme from 'utils/prop-type-theme';

import Icon from 'components/icon';
import Debounce from 'components/debounce';
import NoopWrapper from './noop-wrapper';
import TextInputFormsy from './text-input-formsy';

const themes = {
  aloneInRow: 'theme-alone-in-row',
  bigText: 'theme-big-text',
  grid: 'theme-grid',
  hiddenLabel: 'theme-hidden-label',
  narrow: 'theme-narrow',
  noValidation: 'theme-no-validation',
  white: 'theme-white',
  wide: 'theme-wide'
};

function validationString(validations = '', isRequired = false) {
  if (!validations && !isRequired) {
    return;
  }

  const separator = validations.length ? ',' : '';
  return validations + (isRequired ? `${separator}isRequired` : '');
}

// NOTE: This component can be (but is not always) rendered by TextInputFormsy (./text-input-formsy.jsx). When rendered in this way, TextInputFormsy uses the propTypes definition exported from this file to filter props passed to this component. Therefore, any prop that is not defined in 'propTypes' below will not be passed to this component from TextInputFormsy.

class TextInput extends React.Component {
  static propTypes = {
    autocomplete: PropTypes.oneOf(['off', 'on']),
    className: PropTypes.string,
    detectAutoFill: PropTypes.bool,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string, // Validation message from outside the component (like server side valudation)
    formId: PropTypes.string,
    idPrefix: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onRef: PropTypes.func,
    onValidate: PropTypes.func,
    placeholder: PropTypes.string,
    shouldSetValueOnChange: PropTypes.bool,
    shouldShowErrorMessage: PropTypes.bool,
    theme: propTypeTheme(themes),
    togglePasswordLabel: PropTypes.string,
    type: PropTypes.string,
    useFormsy: PropTypes.bool,
    validations: PropTypes.string,
    value: PropTypes.string,
    validationErrors: PropTypes.object
  };

  static propTypesMeta = {
    autocomplete: 'exclude',
    className: 'exclude',
    detectAutoFill: 'exclude',
    disabled: 'exclude',
    formId: 'exclude',
    idPrefix: 'exclude',
    shouldSetValueOnChange: 'exclude',
    shouldShowErrorMessage: 'exclude',
    type: 'exclude',
    theme: 'exclude',
    useFormsy: 'exclude',
    validations: 'exclude',
    validationErrors: 'exclude'
  };

  static defaultProps = {
    onChange: () => {},
    onRef: () => {},
    shouldShowErrorMessage: true,
    type: 'text',
    useFormsy: true
  };

  state = {
    type: this.props.type
  };

  togglePassword = () => {
    if (this.props.type === 'password') {
      this.setState(state => ({
        type: state.type === 'text' ? 'password' : 'text'
      }));
    }
  };

  render() {
    const Wrapper = this.props.useFormsy ? TextInputFormsy : NoopWrapper;
    const idPrefix = this.props.idPrefix ? this.props.idPrefix + '-' : '';
    const id = `${idPrefix}${this.props.name}`;

    return (
      <Wrapper
        detectAutoFill={this.props.detectAutoFill}
        errorMessage={this.props.errorMessage}
        initialValue={this.props.value}
        name={this.props.name}
        onValidate={this.props.onValidate}
        shouldSetValueOnChange={this.props.shouldSetValueOnChange}
        validations={validationString(
          this.props.validations,
          this.props.isRequired
        )}
        validationErrors={this.props.validationErrors}
      >
        {({ errorMessage, isInvalid, onBlur, onChange, onRef }) => (
          <div
            className={cn(
              'text-input',
              this.props.className,
              this.props.theme,
              { 'has-error': isInvalid }
            )}
          >
            {this.props.label && <label htmlFor={id}>{this.props.label}</label>}
            <input
              autoComplete={this.props.autocomplete}
              defaultValue={this.props.value}
              disabled={this.props.disabled}
              form={this.props.formId}
              id={id}
              name={this.props.name}
              onBlur={e => onBlur(e.target.value)}
              onChange={e => {
                onChange(e.target.value);
                this.props.onChange(e.target.value);
              }}
              placeholder={this.props.placeholder}
              ref={input => {
                onRef(input);
                this.props.onRef(input);
              }}
              type={this.state.type}
            />

            {this.props.type === 'password' && (
              <button
                className={cn('text-input-password-button', {
                  'is-active': this.state.type === 'password'
                })}
                type="button"
                onClick={this.togglePassword}
              >
                <span>{this.props.togglePasswordLabel}</span>
                <span className="text-input-password-icon">
                  <Icon name="eye" />
                </span>
              </button>
            )}

            <Debounce wait={50}>
              <Collapse
                animateChildren={false}
                className="text-input-error"
                forceInitialAnimation={true}
                isOpen={this.props.shouldShowErrorMessage && !!errorMessage}
              >
                <div>{errorMessage}</div>
              </Collapse>
            </Debounce>
          </div>
        )}
      </Wrapper>
    );
  }
}

TextInput.themes = themes;

export default TextInput;
