import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Link from '../link';
import RichText from 'components/rich-text';
import Store from 'components/find-store/store';

const StoresList = ({ storeLabels, stores }) => {
  const { name, address, hours, phone, email } = storeLabels;

  return (
    <table className="stores-list">
      <thead>
        <tr key="header">
          <th>{name}</th>
          <th>{address}</th>
          <th>{hours}</th>
          <th>{phone}</th>
          <th>{email}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {stores.map(store => (
          <tr key={store.name + store.email}>
            <td className="stores-list-name">
              <Link text={store.name} url={store.url} />
            </td>
            <td className="stores-list-address">{store.address}</td>
            <RichText
              element="td"
              className="stores-list-hours"
              theme={RichText.themes.centered}
              {...store.openingHours}
            />
            <td className="stores-list-phone">
              <Link text={store.phone} url={`tel:${store.phone}`} />
            </td>
            <td className="stores-list-email">
              <Link text={store.email} url={`mailto:${store.email}`} />
            </td>
            <td>
              <Link className="stores-list-icon" url={store.url}>
                <Icon name="pin" />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

StoresList.propTypes = {
  storeLabels: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    hours: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  stores: PropTypes.arrayOf(PropTypes.exact(Store.propTypes))
};

StoresList.propTypesMeta = {
  stores: 'exclude' // NOTE: stores are provided by FindStorePage
};

StoresList.defaultProps = {
  stores: [],
  storeLabels: {}
};

export default StoresList;
