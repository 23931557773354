import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import supportedBlocks from './supported-blocks';
import { error } from 'js/log';

import ReactComponent from 'components/react-component';

const sizes = {
  half: 'size-half',
  third: 'size-third'
};

const ComponentListItem = ({
  additionalComponentProps,
  blockNotSupportedText,
  name,
  props,
  id,
  onPageEditing,
  size
}) => {
  const Component = supportedBlocks()[name];

  if (!Component) {
    error(`😵 ContentArea does not support rendering of '${name}'.`);
    return blockNotSupportedText ? (
      <div className="component-list-item-fallback">
        {blockNotSupportedText} <i>{name}</i>
      </div>
    ) : null;
  }

  if (!props || !Object.keys(props).length) {
    return null;
  }

  const additionalProps = additionalComponentProps[name];

  const componentToRender = (
    <Component key={id} {...props} {...additionalProps} />
  );

  if (process.env.NODE_ENV !== 'production') {
    // NOTE: wrapping in 'props' in order to do strict validation with 'exact' (top level propTypes are loosely validated)
    PropTypes.checkPropTypes(
      { props: PropTypes.exact(Component.propTypes) },
      { props: { ...props, ...additionalProps } },
      'prop',
      name
    );
  }

  const OPEProps = onPageEditing.contentId
    ? {
        'data-epi-block-id': onPageEditing.contentId,
        'data-epi-content-name': onPageEditing.contentName,
        'data-epi-use-mvc': 'True',
        'data-content-group': true
      }
    : {};

  return (
    <div className={cn('component-list-item', size)} key={id} {...OPEProps}>
      {componentToRender}
    </div>
  );
};

ComponentListItem.propTypes = {
  additionalComponentProps: PropTypes.object,
  blockNotSupportedText: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onPageEditing: PropTypes.exact({
    contentName: PropTypes.string,
    contentId: PropTypes.string
  }),
  props: PropTypes.shape(ReactComponent.propTypes).isRequired, // Intentional use of 'shape' because validation of 'componentData' is handled by the components themselves
  size: PropTypes.oneOf(Object.values(sizes))
};

ComponentListItem.propTypesMeta = {
  additionalComponentProps: 'exclude',
  blockNotSupportedText: 'exclude',
  theme: 'exclude'
};

ComponentListItem.defaultProps = {
  blockNotSupportedText: null,
  enableElementSizing: true,
  onPageEditing: {}
};

ComponentListItem.sizes = sizes;

export default ComponentListItem;
