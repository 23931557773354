import isRunningOnClient from '@creuna/utils/is-running-on-client';

function minWidth(size) {
  return function() {
    return isRunningOnClient
      ? window.matchMedia(`(min-width: ${size}px)`).matches
      : null;
  };
}

function maxWidth(size) {
  return function() {
    return isRunningOnClient
      ? window.matchMedia(`(max-width: ${size}px)`).matches
      : null;
  };
}

const breakpoints = {
  sm: 480,
  md: 700,
  lg: 1024,
  xl: 1280,
  xxl: 1600
};

export default {
  sm: minWidth(breakpoints.sm),
  md: minWidth(breakpoints.md),
  lg: minWidth(breakpoints.lg),
  xl: minWidth(breakpoints.xl),
  xxl: minWidth(breakpoints.xxl),

  smMax: maxWidth(breakpoints.sm - 1),
  mdMax: maxWidth(breakpoints.md - 1),
  lgMax: maxWidth(breakpoints.lg - 1),
  xlMax: maxWidth(breakpoints.xl - 1),
  xxlMax: maxWidth(breakpoints.xxl - 1)
};
