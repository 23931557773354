function create(props) {
  try {
    return {
      [props.firstName.name]: !props.firstName.isRequired,
      [props.lastName.name]: !props.lastName.isRequired,
      [props.email.name]: !props.email.isRequired,
      [props.phone.name]: !props.phone.isRequired,
      [props.address.name]: !props.address.isRequired,
      [props.postalCode.name]: !props.postalCode.isRequired,
      [props.birthDate.name]: !props.birthDate.isRequired
    };
  } catch (e) {
    return {};
  }
}

function toggleDeliveryAddress(props, model, requireDeliveryAddress) {
  try {
    const newModel = { ...model };

    if (requireDeliveryAddress) {
      newModel[props.deliveryFirstName.name] = !props.deliveryFirstName
        .isRequired;
      newModel[props.deliveryLastName.name] = !props.deliveryLastName
        .isRequired;
      newModel[props.deliveryAddress.name] = !props.deliveryAddress.isRequired;
      newModel[props.deliveryPostalCode.name] = !props.deliveryPostalCode
        .isRequired;
    } else {
      delete newModel[props.deliveryFirstName.name];
      delete newModel[props.deliveryLastName.name];
      delete newModel[props.deliveryAddress.name];
      delete newModel[props.deliveryPostalCode.name];
    }

    return newModel;
  } catch (e) {
    return model;
  }
}

function validate(model) {
  return Object.keys(model).every(key => model[key]);
}

export default {
  create,
  toggleDeliveryAddress,
  validate
};
