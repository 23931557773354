import React from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import { iframeResizer } from 'iframe-resizer';

import isRunningOnClient from '@creuna/utils/is-running-on-client';

import ContentContainer from '../content-container';
import RichText from '../rich-text';

class BookingPage extends React.Component {
  static propTypes = {
    iframeUrl: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string
  };

  getSrc = () => {
    if (!this.props.iframeUrl) {
      return '';
    }

    const isMobile = isRunningOnClient && window.innerWidth < 700;
    const queryParams = (isMobile ? '?mobil=1&' : '?') + new Date().getTime();

    return this.props.iframeUrl + queryParams;
  };

  state = {
    src: this.getSrc()
  };

  screenWidth = 0;

  onResize = () => {
    if (window.innerWidth !== this.screenWidth) {
      this.screenWidth = window.innerWidth;
      this.setState({ src: this.getSrc() });
    }
  };

  debouncedResize = debounce(this.onResize, 300);

  componentDidMount() {
    iframeResizer(null, this.iframe);

    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', this.debouncedResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  }

  render() {
    return (
      <ContentContainer>
        <div className="booking-page">
          <h1>{this.props.title}</h1>
          <RichText theme={RichText.themes.centered} text={this.props.text} />

          {this.state.src && (
            <iframe
              src={this.state.src}
              ref={iframe => (this.iframe = iframe)}
            />
          )}
        </div>
      </ContentContainer>
    );
  }
}

export default BookingPage;
