import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import Button from '../button';
import ContentContainer from '../content-container';
import Form from '../form';
import ValidationErrorTexts from 'components/validation-error-texts';

import Link from '../link';
import PageSpinner from '../page-spinner';
import TextInput from '../form-elements/text-input';

const themes = {
  maxi: 'theme-maxi',
  mini: 'theme-mini'
};

class LoginForm extends React.Component {
  static propTypes = {
    endpoint: PropTypes.string,
    forgotPassword: PropTypes.exact(Link.propTypes),
    headingLevel: PropTypes.number,
    idPrefix: PropTypes.string,
    password: PropTypes.exact(TextInput.propTypes),
    registerLink: PropTypes.exact(Link.propTypes),
    registerLinkLabel: PropTypes.string,
    submitLabel: PropTypes.string,
    title: PropTypes.string,
    theme: propTypeTheme(themes),
    userId: PropTypes.exact(TextInput.propTypes),
    validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes)
  };

  static propTypesMeta = {
    headingLevel: 'exclude',
    idPrefix: 'exclude',
    theme: 'exclude'
  };

  static defaultProps = {
    headingLevel: 1
  };

  state = {
    isLoading: false
  };

  onBeforeSubmit = () => {
    this.setState({ isLoading: true });
  };

  onResponse = () => {
    this.setState({ isLoading: false });
  };

  render() {
    const Title = 'h' + this.props.headingLevel;
    const Wrapper = this.props.theme === themes.maxi ? ContentContainer : 'div';

    return (
      <Wrapper className={cn('login-form', this.props.theme)}>
        <PageSpinner
          isLoading={this.state.isLoading}
          shouldContainInParent={true}
        />

        <Title>{this.props.title}</Title>

        <Form
          className="login-form-form"
          endpoint={this.props.endpoint}
          onBeforeSubmit={this.onBeforeSubmit}
          onResponse={this.onResponse}
          showSubmitButton={false}
        >
          <TextInput
            detectAutofill={true}
            idPrefix={this.props.idPrefix}
            validations="isRequired"
            validationErrors={this.props.validationErrors}
            {...this.props.userId}
          />
          <TextInput
            detectAutofill={true}
            idPrefix={this.props.idPrefix}
            type="password"
            validations="isRequired"
            validationErrors={this.props.validationErrors}
            {...this.props.password}
          />

          {this.props.forgotPassword && (
            <Link
              className="login-form-forgot-password"
              {...this.props.forgotPassword}
            />
          )}

          <Button
            className="login-form-submit"
            text={this.props.submitLabel}
            theme={[Button.themes.orange, Button.themes.uppercase]}
            type="submit"
          />
        </Form>

        <div className="login-form-register">
          <p>{this.props.registerLinkLabel}</p>
          <Link {...this.props.registerLink} />
        </div>
      </Wrapper>
    );
  }
}

LoginForm.themes = themes;

export default LoginForm;
