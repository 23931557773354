import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import Button from '../../button';
import Icon from '../../icon';
import TextInput from '../text-input';

const Search = ({
  autoComplete,
  buttonText,
  name,
  value,
  onChange,
  onRef,
  placeholder,
  showSubmitButton,
  theme,
  textInputTheme,
  useFormsy
}) => (
  <div className={cn('search', theme)}>
    <TextInput
      autoComplete={autoComplete}
      className="search-input"
      defaultValue={value}
      name={name}
      onChange={onChange}
      onRef={onRef}
      placeholder={placeholder}
      shouldShowErrorMessage={false}
      shouldSetValueOnChange={true}
      type="search"
      theme={textInputTheme}
      useFormsy={useFormsy}
    />

    {showSubmitButton && (
      <div className="search-button-container">
        <Button className="search-button" type="submit" text={buttonText}>
          <Icon name="magnifier" />
        </Button>
      </div>
    )}
  </div>
);

const themes = {
  big: 'theme-big'
};

Search.propTypes = {
  autoComplete: PropTypes.string,
  buttonText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onRef: PropTypes.func,
  placeholder: PropTypes.string,
  useFormsy: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  theme: propTypeTheme(themes),
  textInputTheme: PropTypes.any,
  value: PropTypes.string
};

Search.propTypesMeta = {
  autoComplete: 'exclude',
  useFormsy: 'exclude',
  showSubmitButton: 'exclude',
  theme: 'exclude',
  textInputTheme: 'exclude'
};

Search.defaultProps = {
  name: 'search-term',
  showSubmitButton: true,
  value: ''
};

Search.themes = themes;

export default Search;
