import PropTypes from 'prop-types';

// NOTE: This component is used to provide noops in order to use Checkbox without Formsy
const NoopWrapper = ({ checked, children }) =>
  children({ checked, onChange: () => {} });

NoopWrapper.propTypes = {
  children: PropTypes.func
};

NoopWrapper.propTypesMeta = 'exclude';

export default NoopWrapper;
