import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';
import OpeningHoursMeta from 'components/openingHoursMeta/openingHoursMeta';

const StorePageHeader = ({
  title,
  openingHours,
  openingHoursMeta,
  openingHoursLabel,
  address,
  addressMeta,
  geoMeta,
  phone,
  email
}) => (
  <div
    className="store-page-header"
    itemScope
    itemType="http://schema.org/Optician"
  >
    <h1 itemProp="name">{title}</h1>
    <p>{openingHoursLabel}</p>
    <div
      className="opening-hours"
      dangerouslySetInnerHTML={{ __html: openingHours }}
    />

    {openingHoursMeta && <OpeningHoursMeta {...openingHoursMeta} />}
    <p>{address}</p>

    {addressMeta && (
      <div
        itemScope
        itemProp="address"
        itemType="http://schema.org/PostalAddress"
      >
        {addressMeta.streetAddress && (
          <meta itemProp="streetAddress" content={addressMeta.streetAddress} />
        )}
        {addressMeta.postalCode && (
          <meta itemProp="postalCode" content={addressMeta.postalCode} />
        )}
        {addressMeta.addressLocality && (
          <meta
            itemProp="addressLocality"
            content={addressMeta.addressLocality}
          />
        )}
      </div>
    )}
    {geoMeta && (
      <div itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
        {geoMeta.latitude && (
          <meta itemprop="latitude" content={geoMeta.latitude} />
        )}
        {geoMeta.longitude && (
          <meta itemprop="longitude" content={geoMeta.longitude} />
        )}
      </div>
    )}
    <p>
      <Link {...phone} />
    </p>
    <p>
      <Link {...email} />
    </p>
    {phone && phone.text && <meta itemProp="telephone" content={phone.text} />}
    {email && email.text && <meta itemProp="email" content={email.text} />}
  </div>
);
StorePageHeader.propTypes = {
  title: PropTypes.string,
  openingHours: PropTypes.string,
  openingHoursMeta: PropTypes.exact(OpeningHoursMeta.propTypes),
  openingHoursLabel: PropTypes.string,
  address: PropTypes.string,
  addressMeta: PropTypes.shape({
    streetAddress: PropTypes.string,
    postalCode: PropTypes.string,
    addressLocality: PropTypes.string
  }),
  geoMeta: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  phone: PropTypes.exact(Link.propTypes),
  email: PropTypes.exact(Link.propTypes)
};

export default StorePageHeader;
