import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import { logError } from '../../js/console';
import propTypeTheme from 'utils/prop-type-theme';

const themes = {
  big: 'theme-big',
  light: 'theme-light',
  bold: 'theme-bold',
  huge: 'theme-huge',
  mini: 'theme-mini',
  noStyle: 'theme-no-style',
  noUnderline: 'theme-no-underline',
  skew: 'theme-skew',
  skewUnderline: 'theme-skew-underline',
  white: 'theme-white'
};

class Link extends React.Component {
  static propTypes = {
    attributes: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    onRef: PropTypes.object,
    prependChildren: PropTypes.bool,
    text: PropTypes.string,
    theme: propTypeTheme(themes),
    url: PropTypes.string,
    useSpanElement: PropTypes.bool,
    tabindex: PropTypes.number
  };

  static propTypesMeta = {
    attributes: 'exclude',
    className: 'exclude',
    isActive: 'exclude',
    onRef: 'exclude',
    prependChildren: 'exclude',
    theme: 'exclude',
    useSpanElement: 'exclude',
    tabindex: 'exclude'
  };

  state = {
    role: null
  };

  componentDidMount() {
    this.setState({ role: this.props.onClick ? 'button' : null });
  }

  render() {
    const {
      children,
      className,
      prependChildren,
      text,
      theme,
      url,
      tabindex = null
    } = this.props;

    if (!url) {
      logError(
        `Url missing for 'Link' with text: ${text}, and className: ${className}`
      );
      return null;
    }

    const useSpan =
      this.props.useSpanElement ||
      !!children ||
      theme === themes.skew ||
      theme === themes.skewUnderline ||
      (theme && theme.indexOf(themes.skew) !== -1) ||
      (theme && theme.indexOf(themes.skewUnderline) !== -1);

    return (
      <a
        className={cn('link', className, theme, {
          'has-children': !!children,
          'has-children-before': !!children && prependChildren,
          'has-span': useSpan,
          'is-active': this.props.isActive,
        })}
        href={url}
        onClick={this.props.onClick}
        ref={this.props.onRef}
        role={this.state.role}
        {...this.props.attributes}
        tabIndex={tabindex}
      >
        {prependChildren && children}
        {useSpan && text ? <span>{text}</span> : text}
        {!prependChildren && children}
      </a>
    );
  }
}

Link.themes = themes;

export default Link;
