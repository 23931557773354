import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import isRunningOnClient from '@creuna/utils/is-running-on-client';

import TinyTransition from 'react-tiny-transition';

import Carousel from 'components/carousel';
import Image from 'components/image';
import ImageContainer from './image-container';
import ZoomModal from './zoom-modal';

class ProductImages extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.exact(Image.propTypes))
  };

  static defaultProps = {
    images: []
  };

  state = {
    modalIsVisible: false,
    modalSrc: '',
    portalNode: null
  };

  componentDidMount() {
    this.setState({ portalNode: document.body });
  }

  showModal(src) {
    this.setState({
      modalIsVisible: true,
      modalSrc: src
    });
  }

  hideModal = () => {
    this.setState({ modalIsVisible: false });
  };

  render() {
    const firstImage = this.props.images[0];
    const isTouch =
      isRunningOnClient && 'ontouchstart' in document.documentElement;
    const imageScale = isTouch ? 2 : 1;

    return (
      <div className="product-images">
        {this.props.images.length === 1 ? (
          <ImageContainer onClick={this.showModal.bind(this, firstImage.src)}>
            <Image scale={imageScale} {...firstImage} />
          </ImageContainer>
        ) : (
          <Carousel>
            {this.props.images.map(image => (
              <ImageContainer
                key={image.src}
                onClick={this.showModal.bind(this, image.src)}
              >
                <Image scale={imageScale} {...image} />
              </ImageContainer>
            ))}
          </Carousel>
        )}

        {this.state.portalNode &&
          ReactDOM.createPortal(
            <TinyTransition>
              {this.state.modalIsVisible && (
                <ZoomModal
                  src={this.state.modalSrc}
                  onCloseButtonClick={this.hideModal}
                />
              )}
            </TinyTransition>,
            this.state.portalNode
          )}
      </div>
    );
  }
}

export default ProductImages;
