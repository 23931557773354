import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';
import TinyTransition from 'react-tiny-transition';

import Button from '../button';
import ContentContainer from '../content-container';
import Icon from '../icon';
import Link from '../link';
import MouseoverLink from '../mouseover-link';
import SubMenu from './sub-menu';

class MainNav extends React.Component {
  static propTypes = {
    bookAppointmentLink: PropTypes.exact(Button.propTypes),
    hideMobileMenu: PropTypes.func,
    isDesktop: PropTypes.bool,
    isExpanded: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.exact({
        link: PropTypes.exact({
          url: PropTypes.string,
          text: PropTypes.string
        }),
        content: PropTypes.exact(SubMenu.propTypes)
      })
    ),
    myPageLink: PropTypes.exact(Link.propTypes),
    supportMenu: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
    toggleLogin: PropTypes.func,
    topPosition: PropTypes.number
  };

  static propTypesMeta = {
    isDesktop: 'exclude',
    isExpanded: 'exclude',
    myPageLink: 'exclude', // NOTE: This is provided by Header,
    supportMenu: 'exclude', // NOTE: This is provided by Header
    topPosition: 'exclude'
  };

  static defaultProps = {
    items: [],
    supportMenu: []
  };

  state = {
    activeSubMenu: '',
    hasActiveSubMenu: false
  };

  toggleSubMenu = menuName => {
    this.setState(
      state => {
        const hasActiveSubMenu =
          !state.hasActiveSubMenu || state.activeSubMenu !== menuName;
        return {
          shouldAnimateSubMenu:
            state.hasActiveSubMenu !== hasActiveSubMenu || !this.props.isDesktop
        };
      },
      () => {
        requestAnimationFrame(() => {
          this.setState(state => {
            const hasActiveSubMenu =
              !state.hasActiveSubMenu || state.activeSubMenu !== menuName;
            return {
              activeSubMenu: menuName,
              hasActiveSubMenu
            };
          });
        });
      }
    );
  };

  hideSubMenus = () => {
    this.setState({ activeSubMenu: '', hasActiveSubMenu: false });
  };

  render() {
    const { isDesktop, isExpanded } = this.props;

    const shouldAnimate = this.state.shouldAnimateSubMenu || !isDesktop;
    const WrapperElement = isDesktop ? 'div' : TinyTransition;

    return (
      <React.Fragment>
        <WrapperElement key="content">
          {(this.props.isDesktop || this.props.isExpanded) && (
            <div
              className={cn('main-nav', {
                'is-mobile': !this.props.isDesktop,
                'is-expanded': this.props.isExpanded
              })}
            >
              <ContentContainer className="main-nav-content">
                <ul className="main-nav-items">
                  {this.props.items.map(({ link, content }) => {
                    const isActive =
                      this.state.hasActiveSubMenu &&
                      this.state.activeSubMenu === link.url;

                    return (
                      <li key={link.url}>
                        <div className="main-nav-item-header">
                          <MouseoverLink
                            attributes={{
                              'data-test-main-nav-link': link.text
                            }}
                            className="main-nav-link"
                            theme={[Link.themes.noStyle, Link.themes.skew]}
                            onMousehold={() => this.toggleSubMenu(link.url)}
                            {...link}
                          />

                          <Button
                            className={cn('main-nav-button', {
                              'is-active': isActive
                            })}
                            onClick={() => this.toggleSubMenu(link.url)}
                            theme={Button.themes.plain}
                          >
                            <Icon name="small-arrow-down" />
                          </Button>
                        </div>

                        <div
                          className={cn('main-nav-submenu', {
                            'main-nav-submenu--active': isActive,
                            'main-nav-submenu--animate': shouldAnimate
                          })}
                          aria-expanded={isActive}
                        >
                          <SubMenu
                            hide={this.hideSubMenus}
                            {...content}
                            isHidden={!isActive}
                          />
                        </div>
                      </li>
                    );
                  })}
                </ul>

                <ul className="main-nav-support-menu">
                  {this.props.supportMenu.map(item => (
                    <li key={item.url}>
                      <Link theme={Link.themes.noStyle} {...item} />
                    </li>
                  ))}

                  {this.props.myPageLink && (
                    <li>
                      <Link
                        onClick={this.props.toggleLogin}
                        theme={Link.themes.noStyle}
                        {...this.props.myPageLink}
                      />
                    </li>
                  )}
                </ul>

                <Button
                  className="main-nav-book-appointment"
                  {...this.props.bookAppointmentLink}
                />
              </ContentContainer>
            </div>
          )}
        </WrapperElement>

        <TinyTransition duration={500}>
          {isDesktop && this.state.hasActiveSubMenu && (
            <div className="main-nav-overlay" onClick={this.hideSubMenus} />
          )}
        </TinyTransition>

        <TinyTransition duration={500}>
          {!isDesktop && isExpanded && (
            <div
              className="main-nav-overlay"
              onClick={this.props.hideMobileMenu}
            />
          )}
        </TinyTransition>
      </React.Fragment>
    );
  }
}

export default MainNav;
