import React from 'react';
import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';

class TextInputFormsy extends React.Component {
  static propTypes = {
    children: PropTypes.func,
    detectAutofill: PropTypes.bool,
    errorMessage: PropTypes.string, // Validation message from backend. Any frontend validation message will override this
    getErrorMessage: PropTypes.func, // From Formsy HOC
    isPristine: PropTypes.func, // From Formsy HOC
    isValid: PropTypes.func, // From Formsy HOC
    initialValue: PropTypes.string,
    // eslint-disable-next-line
    name: PropTypes.string.isRequired, // Required by Formsy HOC
    onValidate: PropTypes.func,
    setValue: PropTypes.func, // From Formsy HOC
    showError: PropTypes.func, // From Formsy HOC
    shouldSetValueOnChange: PropTypes.bool,
    // eslint-disable-next-line
    validations: PropTypes.string, // This string is used to set the validation type(s) for the Formsy HOC. (Comma separated list without spaces)
    // eslint-disable-next-line
    validationErrors: PropTypes.object // This object should contain a message for each validation type. (Used by the Formsy HOC).
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    onValidate: () => {},
    textInputProps: {},
    type: 'text'
  };

  autoFillLoop = null;
  state = {
    errorMessage: this.props.errorMessage,
    isMounted: false
  };

  isValid = this.props.isValid();

  onBlur = value => {
    clearInterval(this.autoFillLoop);

    if (this.props.isPristine()) {
      this.props.setValue(value);
    }
  };

  onChange = value => {
    clearInterval(this.autoFillLoop);
    this.setState({ errorMessage: '' });

    // NOTE: if 'isRequired' is the only applied validation rule the value should be updated immediately so that the user can submit the form using the enter key without moving focus out of the input first
    if (
      !this.props.isPristine() ||
      this.props.shouldSetValueOnChange ||
      this.props.detectAutofill ||
      this.props.validations === 'isRequired'
    ) {
      this.props.setValue(value);
    }
  };

  onRef = input => {
    this.input = input;
  };

  componentDidUpdate() {
    if (this.props.isValid() !== this.isValid) {
      this.isValid = this.props.isValid();
      this.props.onValidate(this.props.isValid());
    }
  }

  componentDidMount() {
    this.setState({ isMounted: true });

    if (this.props.initialValue) {
      this.props.setValue(this.props.initialValue);
      this.props.onValidate(this.props.isValid());
    } else if (this.input && this.input.value) {
      this.props.setValue(this.input.value);
    } else if (this.props.detectAutofill && this.props.isPristine()) {
      this.autoFillLoop = setInterval(() => {
        if (this.props.isPristine() && this.input && this.input.value) {
          clearInterval(this.autoFillLoop);
          this.props.setValue(this.input.value);
        }
      }, 2000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoFillLoop);
  }

  render() {
    const showError =
      this.state.errorMessage ||
      (this.props.isPristine() ? false : this.props.showError());

    return this.props.children({
      errorMessage:
        (showError && this.props.getErrorMessage()) || this.state.errorMessage,
      isInvalid:
        showError && (!this.props.isValid() || !!this.state.errorMessage),
      onBlur: this.onBlur,
      onChange: this.onChange,
      onRef: this.onRef
    });
  }
}

export default withFormsy(TextInputFormsy);
