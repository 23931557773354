import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import CheckboxFormsy from './checkbox-formsy';
import Icon from '../../icon';
import NoopWrapper from './noop-wrapper';

const Checkbox = ({
  attributes,
  checked, // NOTE: When used without Formsy, this can be used to set the checked state
  className,
  disabled,
  idPrefix,
  label,
  description,
  labelChild,
  name,
  onChange,
  theme,
  useFormsy,
  selected,
  value,
  validations
}) => {
  const Wrapper = useFormsy ? CheckboxFormsy : NoopWrapper;
  const id = idPrefix + '-' + name + (value ? '-' + value : '');

  return (
    <Wrapper
      checked={checked}
      name={name}
      initiallyChecked={selected}
      validations={validations}
    >
      {({ checked, onChange: onChangeFormsy }) => (
        <>
          <div className={cn('checkbox', theme, className)}>
            <input
              {...attributes}
              type="checkbox"
              checked={checked}
              disabled={disabled}
              id={id}
              name={name}
              onChange={e => {
                const data = { value: value, checked: e.target.checked };
                onChange(data);
                onChangeFormsy(data);
              }}
              value={value}
            />
            <label htmlFor={id}>
              <div className="checkbox-fake">
                <Icon name="checkmark" className="checkbox-checkmark" />
              </div>
              {labelChild ? labelChild : <span>{label}</span>}
            </label>
          </div>
          {description && <p className="checkbox-description">{description}</p>}
        </>
      )}
    </Wrapper>
  );
};

const themes = {
  alignTop: 'theme-align-top',
  grid: 'theme-grid',
  link: 'theme-link'
};

Checkbox.propTypes = {
  attributes: PropTypes.object,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  idPrefix: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  labelChild: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  theme: propTypeTheme(themes),
  useFormsy: PropTypes.bool,
  selected: PropTypes.bool,
  value: PropTypes.string,
  validations: PropTypes.string
};

Checkbox.propTypesMeta = {
  attributes: 'exclude',
  checked: 'exclude',
  className: 'exclude',
  idPrefix: 'exclude',
  theme: 'exclude',
  useFormsy: 'exclude',
  validations: 'exclude'
};

Checkbox.defaultProps = {
  idPrefix: '',
  onChange: () => {},
  useFormsy: true
};

Checkbox.themes = themes;

export default Checkbox;
