import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import Grid from '../grid';
import Link from '../link';
import ProductCard from '../product-card';
import ProductList from '../product-list';
import SearchResultHeader from './search-result-header';

const SearchResults = ({
  articles,
  articlesHeader,
  children,
  products,
  productsHeader
}) => {
  const hasResults =
    get(products, 'products', []).length > 0 || articles.length > 0;
  return (
    <React.Fragment>
      {hasResults && (
        <Grid theme={[Grid.themes.leftColumnWider]}>
          <Grid.Column>
            {productsHeader && <SearchResultHeader {...productsHeader} />}
            {products && (
              <div className="global-search-results-products">
                <ProductList
                  shouldAnimate={true}
                  productTheme={ProductCard.themes.smallerImages}
                  theme={[
                    ProductList.themes.narrow,
                    ProductList.themes.noMargin
                  ]}
                  {...products}
                />
              </div>
            )}
          </Grid.Column>

          <Grid.Column>
            {articlesHeader && (
              <SearchResultHeader isCentered={true} {...articlesHeader} />
            )}
            {articles.length > 0 && (
              <ul className="global-search-articles">
                {articles.map(article => (
                  <li key={article.url}>
                    <Link theme={[Link.themes.noUnderline]} {...article} />
                  </li>
                ))}
              </ul>
            )}
          </Grid.Column>
        </Grid>
      )}

      {children(hasResults)}
    </React.Fragment>
  );
};

SearchResults.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  articlesHeader: PropTypes.exact(SearchResultHeader.propTypes),
  children: PropTypes.func,
  products: PropTypes.exact(ProductList.propTypes),
  productsHeader: PropTypes.exact(SearchResultHeader.propTypes)
};

SearchResults.defaultProps = {
  articles: [],
  children: () => {}
};

export default SearchResults;
