import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Link from '../link';

const linkThemes = [
  Link.themes.big,
  Link.themes.bold,
  Link.themes.default,
  Link.themes.skew,
  Link.themes.white
];

const FooterLinkList = ({ id, links }) => (
  <div className="footer-link-list" key={id}>
    <ul>
      {links.map(link => (
        <li key={link.text}>
          <Link prependChildren={true} theme={linkThemes} {...link}>
            <Icon className="footer-link-list-icon" name="small-arrow-right" />
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

FooterLinkList.defaultProps = {
  linkList: []
};

FooterLinkList.propTypes = {
  id: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes))
};

export default FooterLinkList;
