import React from 'react';
import PropTypes from 'prop-types';

import { withFormsy } from 'formsy-react';

// This component uses the withFormsy HOC. See the supported props here: https://github.com/formsy/formsy-react/blob/master/API.md
class FormsyWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.func,
    getErrorMessage: PropTypes.func, // From Formsy HOC
    getValue: PropTypes.func, // From Formsy HOC
    initialValue: PropTypes.any,
    isPristine: PropTypes.func, // From Formsy HOC
    isValid: PropTypes.func, // From Formsy HOC
    onValidate: PropTypes.func,
    setValue: PropTypes.func // From Formsy HOC
  };

  static propTypesMeta = 'exclude';

  isValid = this.props.isValid();

  static defaultProps = {
    onValidate: () => {}
  };

  componentDidMount() {
    if (this.props.initialValue) {
      this.props.setValue(this.props.initialValue);
    }
  }

  componentDidUpdate() {
    if (this.props.isValid() !== this.isValid) {
      this.isValid = this.props.isValid();
      this.props.onValidate(this.props.isValid());
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children({
          getErrorMessage: this.props.getErrorMessage,
          getValue: this.props.getValue,
          isPristine: this.props.isPristine,
          isValid: this.props.isValid,
          setValue: this.props.setValue
        })}
      </React.Fragment>
    );
  }
}

export default withFormsy(FormsyWrapper);
