import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  green: 'theme-green'
};

const Bomb = ({ text, theme }) => (
  <div
    className={cn('bomb', theme, {
      'font-size-xl': text.length < 4,
      'font-size-lg': text.length >= 4 && text.length < 8,
      'font-size-sm': text.length >= 8
    })}
  >
    {text}
  </div>
);

Bomb.propTypes = {
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

Bomb.propTypesMeta = 'exclude';

Bomb.defaultProps = {
  text: ''
};

Bomb.themes = themes;

export default Bomb;
