import React from 'react';
import PropTypes from 'prop-types';

import ErrorSvg from './error-svg';
import Icon from 'components/icon';

const css = require('./error-page.css');

const ErrorPage = ({ errorCode, linkUrl, linkText, text, title }) => (
  <React.Fragment>
    <style>{css}</style>
    <div className="error-page">
      <h1>{title}</h1>
      {text && <p>{text}</p>}
      {linkUrl && linkText && (
        <a href={linkUrl}>
          {linkText}
          <Icon name="small-arrow-right" />
        </a>
      )}
      <div className="error-page-image">
        <ErrorSvg errorCode={errorCode} />
      </div>
    </div>
  </React.Fragment>
);

ErrorPage.propTypes = {
  errorCode: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};

export default ErrorPage;
