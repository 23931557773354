import React from 'react';
import PropTypes from 'prop-types';

import PubSub from 'pubsub-js';

import api from 'js/api-helper';
import checkApiPropTypes from 'js/check-api-prop-types';
import topics from 'js/topics.json';

import AddToCartButton from '../add-to-cart-button';
import AddToCartResponse from 'components/contact-lens-page/add-to-cart-response';
import Form from 'components/form';
import PageSpinner from 'components/page-spinner';
import Select from 'components/form-elements/select';
import ValidationErrorTexts from 'components/validation-error-texts';
import cn from 'classnames';

class GlassesForm extends React.Component {
  static propTypes = {
    updateSizeEndpoint: PropTypes.string,
    addToCartEndpoint: PropTypes.string,
    onBeforeUpdateSize: PropTypes.func,
    onRef: PropTypes.func,
    sizeSelect: PropTypes.exact(Select.propTypes),
    submitLabel: PropTypes.string,
    updateSize: PropTypes.func,
    validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes)
  };

  static defaultProps = {
    onRef: () => {}
  };

  state = {
    isLoading: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ isLoading: false });
    }
  }

  onBeforeAddToCart = () => {
    this.setState({ isLoading: true });
  };

  onAddToCartResponse = response => {
    PubSub.publish(topics.cartUpdate, response);
    checkApiPropTypes(
      response,
      AddToCartResponse.propTypes,
      'AddToCartResponse'
    );
    this.setState({ isLoading: false });
  };

  onChange = value => {
    if (this.props.updateSizeEndpoint) {
      this.props.onBeforeUpdateSize();

      api.execute(this.props.updateSizeEndpoint, value).then(response => {
        this.props.updateSize(response);
      });
    }
  };

  render() {
    const { sizeSelect } = this.props;

    return !sizeSelect ? null : (
      <Form
        className="glasses-form"
        endpoint={this.props.addToCartEndpoint}
        onBeforeSubmit={this.onBeforeAddToCart}
        onChange={this.onChange}
        onRef={this.props.onRef}
        onResponse={this.onAddToCartResponse}
        showSubmitButton={false}
      >
        {sizeSelect && sizeSelect.options && sizeSelect.options.length > 0 && (
          <div
            className={cn('glasses-form-select', {
              'is-hidden': sizeSelect.options.length === 1
            })}
          >
            <Select
              onChange={this.onChange}
              validationErrors={this.props.validationErrors}
              validations="isRequired"
              {...sizeSelect}
            />
          </div>
        )}

        <PageSpinner
          shouldContainInParent={true}
          isLoading={this.state.isLoading}
        />
        <AddToCartButton text={this.props.submitLabel} />
      </Form>
    );
  }
}

export default GlassesForm;
